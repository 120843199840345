import moment from 'moment';
import {
	chartDefinitionIds,
	chartIntervals,
	chartMetricIds,
	chartTypeIds,
	widgetIds
} from '@truescope-web/react/lib/components/charts/enums';
import { wasTokenCancelled } from '@truescope-web/react/lib/hooks/useCancelToken';
import { dateOptionsLookup } from '@truescope-web/utils/lib/dates';
import { mediaTypesLookup } from '@truescope-web/utils/lib/mediaTypes';
import { scoringFunctionNamesLookup } from '@truescope-web/utils/lib/search';
import { encodeBase64 } from '@truescope-web/utils/lib/strings';
import { extractError } from '../../../components/Api';
import { getLocale, getTimeZoneName, getTimeZoneOffset } from '../../../components/Constants';
import { convertItems } from '../../../components/mediaItem/MediaItemConstants';
import { serializeFilters } from '../../../components/widgets/FilterConstants';
import { loadMultipleChartsData } from '../../../components/widgets/charts/ChartConstants';
import { createChart } from '../../../components/widgets/charts/builder/ChartBuilderConstants';

export const trendsCustomDateOptions = [
	{
		value: dateOptionsLookup.last7Days,
		label: 'Last 7 days'
	},
	{
		value: dateOptionsLookup.last30Days,
		label: 'Last 30 days'
	}
];

export const trendsDateFormat = (isHourly = false) => `DD MMM${isHourly ? ' HHa' : ''}`; //todo put in utils

export const trendsChartDateFilters = (dateStart, dateEnd, isHourly) => {
	const momentNow = moment().utc();
	const dateTo = isHourly ? moment(dateEnd).utc() : moment(dateEnd).utc().endOf('day');
	const dateFilters = {
		publication_date_from: isHourly ? moment(dateStart).utc().format() : moment(dateStart).utc().startOf('day').format(),
		publication_date_to: dateTo.format(),
		publication_date_option: dateOptionsLookup.custom
	};

	if (moment(dateEnd).utc().isAfter(momentNow)) {
		dateFilters.publication_date_to = momentNow.startOf('hour').format();
	}

	return dateFilters;
};

export const getNewsTrendsCounts = async (
	getClientApi,
	dashboardDataContext,
	workspace,
	appCache,
	user,
	query,
	dateStart,
	dateEnd,
	isHourly,
	showRequest,
	cancelToken
) => {
	const momentNow = moment().utc();

	const dateTo = isHourly ? moment(dateEnd).utc() : moment(dateEnd).utc().endOf('day');
	const dateFilters = {
		publication_date_from: isHourly ? moment(dateStart).utc().format() : moment(dateStart).utc().startOf('day').format(),
		publication_date_to: dateTo.format(),
		publication_date_option: dateOptionsLookup.custom
	};

	if (moment(dateEnd).utc().isAfter(momentNow)) {
		dateFilters.publication_date_to = momentNow.startOf('hour').format();
	}

	let searchQuery = `"${query}"~20`;
	if (/^".*"$/gm.test(query)) {
		searchQuery = `${searchQuery}~20`;
	}

	const chartJson = createChart(
		{
			allow_benchmark: false,
			chart_name: 'Volume',
			widget_id: widgetIds.timeSeries,
			chart_definition_id: chartDefinitionIds.volumeAndAudienceOverTime,
			chart_type_id: chartTypeIds.column,
			chart_metric_ids: [chartMetricIds.volume],
			chart_interval_id: isHourly ? chartIntervals.hour : chartIntervals.day,
			props: {
				style: { height: 'auto' },
				isCompact: true
			},
			disableExport: true,
			disableLegend: false,
			search_filter: {
				time_zone: isHourly ? getTimeZoneOffset() : '+00:00',
				global: true,
				advanced_query: { value: searchQuery },
				...dateFilters,
				media_types: ['Online']
			},
			time_zone_name: isHourly ? getTimeZoneName() : 'Africa/Abidjan',
			locale: getLocale()
		},
		dashboardDataContext
	);

	const response = await loadMultipleChartsData(getClientApi, [chartJson], workspace, appCache, user, showRequest, cancelToken);

	return response?.chartData?.data.map((d) => ({
		time: isHourly ? moment(d.date).local().format() : moment(d.date).utc().format(),
		value: d.Volume
	}));
};

export const getSearchTrends = (getClientApi, query, dateStart, dateEnd, isHourly, cancelToken) => {
	// We set the endDate for caching on API Gateway.
	const momentNow = moment().utc();
	let endDate = isHourly ? moment.utc(dateEnd).endOf('hour') : moment.utc(dateEnd).endOf('day');
	if (endDate.isAfter(momentNow)) {
		endDate = momentNow.endOf('hour');
	}

	return getClientApi().then((api) =>
		api
			.post(
				`trends/v2/search/trends`,
				{
					query: encodeBase64(query),
					dateStart: isHourly
						? moment.utc(dateStart).startOf('hour').toISOString()
						: moment.utc(dateStart).startOf('day').toISOString(),
					dateEnd: endDate.toISOString(),
					isHourly
				},
				{ cancelToken }
			)
			.then(({ data }) => data)
			.catch((e) => {
				if (wasTokenCancelled(cancelToken)) {
					throw new Error('cancelled');
				}

				throw new Error(extractError(e));
			})
	);
};

export const getTwitterCounts = (getClientApi, query, isHourly = false, cancelToken) => {
	return getClientApi().then((api) =>
		api
			.post(
				`twitter/v1/counts`,
				{
					query,
					isHourly
				},
				{ cancelToken }
			)
			.then(({ data }) => data)
			.catch((e) => {
				if (wasTokenCancelled(cancelToken)) {
					throw new Error('cancelled');
				}
				throw new Error(extractError(e));
			})
	);
};

export const getTruescopeTrendsItems = async (
	getClientApi,
	workspace,
	appCache,
	user,
	query,
	isHourly,
	cancelToken,
	dateTo,
	dateFrom,
	mediaType = mediaTypesLookup.online,
	sort = 'trends'
) => {
	const dateFilters = {
		publication_date_from: dateFrom,
		publication_date_to: dateTo,
		publication_date_option: dateOptionsLookup.custom
	};

	let searchQuery = `"${query}"~20`;
	if (/^".*"$/gm.test(query)) {
		searchQuery = `${searchQuery}~20`;
	}

	const filters = {
		global: true,
		advanced_query: { value: searchQuery },
		...dateFilters,
		media_types: [mediaType],
		time_zone: isHourly ? getTimeZoneOffset() : '+00:00',
		languages: [{ value: 'EN', label: 'English' }]
	};

	if (mediaType === mediaTypesLookup.online) {
		filters.scoreBy = [scoringFunctionNamesLookup.audience, scoringFunctionNamesLookup.recency];
	}

	const params = {
		...serializeFilters(filters, workspace, appCache, user, true),
		limit: 100,
		offset: 0,
		peek: false,
		showElasticRequest: true,
		sort: mediaType === mediaTypesLookup.online ? '_score' : sort,
		desc: true
	};

	const api = await getClientApi();
	const onlineItems = await api.post(`media-items/v1/${workspace.workspace_id}/items`, params, { cancelToken });

	return convertItems(workspace, onlineItems?.data?.items || []);
};

export const getGoogleSearchItems = async (getClientApi, query, languageCode = 'en', cancelToken) => {
	return getClientApi().then((api) =>
		api
			.post(
				`trends/v2/search/trends/items`,
				{
					query: query.toLowerCase(),
					languageCode
				},
				{ cancelToken }
			)
			.then(({ data }) => data)
			.catch((e) => {
				if (wasTokenCancelled(cancelToken)) {
					throw new Error('cancelled');
				}

				throw new Error(extractError(e));
			})
	);
};

/**
 *
 * @param {getClientApi} getClientApi
 * @param {string} query
 * @param {string} dateFrom format YYYY-MM-DD
 * @param {string} cancelToken
 * @returns
 */
export const getTwitterTrendsItems = async (getClientApi, query, until, count, cancelToken) => {
	const api = await getClientApi();
	const { data } = await api.post(
		`twitter/v1/search`,
		{
			query,
			until,
			count
		},
		{ cancelToken }
	);

	return data || {};
};

export const countries = [
	{
		woeId: 'US',
		label: 'United States',
		value: 'United States'
	},
	{
		woeId: 'SG',
		label: 'Singapore',
		value: 'Singapore'
	},
	{
		woeId: 'AU',
		label: 'Australia',
		value: 'Australia'
	},
	{
		woeId: 'NZ',
		label: 'New Zealand',
		value: 'New Zealand'
	}
];

export const dateHandler = (dateOption, dateFrom, dateTo) => {
	const dateNow = moment.now();
	let from = moment(dateFrom || dateNow);
	let to = moment(dateTo || dateNow);

	if (dateOption !== dateOptionsLookup.custom) {
		switch (dateOption) {
			case dateOptionsLookup.today:
				break;
			case dateOptionsLookup.yesterday:
				from = from.subtract(86400, 's');
				break;
			case dateOptionsLookup.last2Days:
				from = from.subtract(86400 * 2, 's');
				break;
			case dateOptionsLookup.last3Days:
				from = from.subtract(86400 * 3, 's');
				break;
			case dateOptionsLookup.last7Days || dateOptionsLookup.lastWeek:
				from = from.subtract(86400 * 7, 's');
				break;

			default:
				from = from.subtract(86400 * 30, 's');
				break;
		}
	} else if (moment.duration(from.diff(to)).asDays() < -30) {
		// todo THROW ERROR
		console.error('DATE IS HIGHER THAN 30 DAYS');
	}

	return {
		dateOption: dateOption || dateOptionsLookup.last30Days,
		dateFrom: from.utc().startOf('day').format(),
		dateTo: to.utc().endOf('day').format()
	};
};

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '@truescope-web/react/lib/components/form/Button';
import Select from '@truescope-web/react/lib/components/form/Select';
import Grid from '@truescope-web/react/lib/components/layout/Grid';
import Typography from '@truescope-web/react/lib/components/layout/Typography';
import { isNullOrUndefined } from '@truescope-web/utils/lib/objects';
import { stringIsNullOrEmpty } from '@truescope-web/utils/lib/strings';

const SuggestedLinks = ({ label, links: workspaceSwitchOptions }) => {
	const navigate = useNavigate();
	const [workspaceSwitch, setWorkspaceSwitch] = useState();

	return (
		<Grid item>
			<Grid container>
				{!stringIsNullOrEmpty(label) && (
					<Grid item>
						<Typography variant="h5">{label}</Typography>
					</Grid>
				)}
				<Grid item>
					<Select
						placeholder="Select a workspace"
						options={workspaceSwitchOptions}
						onChange={(e, value) => setWorkspaceSwitch(value)}
						value={workspaceSwitch}
						fullWidth={false}
					/>
				</Grid>
				<Grid item>
					<Button variant="primary" onClick={() => navigate(`${workspaceSwitch}`)} disabled={isNullOrUndefined(workspaceSwitch)}>
						Switch workspace
					</Button>
				</Grid>
			</Grid>
		</Grid>
	);
};

export default SuggestedLinks;

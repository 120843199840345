import moment from 'moment';
import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import useMediaQuery from '@mui/material/useMediaQuery';
import { verticalAlignment } from '@truescope-web/react/lib/components/Constants';
import Select from '@truescope-web/react/lib/components/form/Select';
import TextField from '@truescope-web/react/lib/components/form/TextField';
import Grid from '@truescope-web/react/lib/components/layout/Grid';
import Inline, { horizontalAlignment } from '@truescope-web/react/lib/components/layout/Inline';
import Typography from '@truescope-web/react/lib/components/layout/Typography';
import ToolbarButton from '@truescope-web/react/lib/components/widgets/ToolbarButton';
import { dateOptions, dateOptionsLookup, prefillDates } from '@truescope-web/utils/lib/dates';
import { isNullOrUndefined } from '@truescope-web/utils/lib/objects';
import { stringIsNullOrEmpty } from '@truescope-web/utils/lib/strings';
import { dateFormat } from '../../Constants';

const DateFilter = ({ value, onRemoveFilter, onChange, dateProperty, disabled }) => {
	const dateOption = value[`${dateProperty}_date_option`];
	const from = value[`${dateProperty}_date_from`];
	const to = value[`${dateProperty}_date_to`];
	const isOnlyXs = useMediaQuery((theme) => theme.breakpoints.only('xs'));

	const handleChangeDate = (e, newDateOption) => {
		try {
			if (isNullOrUndefined(newDateOption)) {
				onChange(e, null);
				return;
			}

			const newValue = {
				[`${dateProperty}_date_option`]: newDateOption
			};

			if (newDateOption !== dateOptionsLookup.custom) {
				const { dateFrom, dateTo } = prefillDates(newDateOption, null, null, dateFormat, true);
				newValue[`${dateProperty}_date_to`] = dateTo;
				newValue[`${dateProperty}_date_from`] = dateFrom;
			} else {
				const { dateFrom, dateTo } = prefillDates(dateOptionsLookup.last30Days, null, null, dateFormat, true);
				if (stringIsNullOrEmpty(from)) {
					newValue[`${dateProperty}_date_from`] = dateFrom;
				}
				if (stringIsNullOrEmpty(to)) {
					newValue[`${dateProperty}_date_to`] = dateTo;
				}
			}

			onChange(e, newValue);
		} catch (e) {
			console.warn(`cannot change date - ${e.message}`, e);
		}
	};

	const handleDateFromChange = (e) => {
		if (stringIsNullOrEmpty(e.target.value)) {
			return;
		}
		onChange(e, {
			...(value || {}),
			[`${dateProperty}_date_from`]: e.target.value
		});
	};

	const handleDateToChange = (e) => {
		if (stringIsNullOrEmpty(e.target.value)) {
			return;
		}
		onChange(e, {
			...(value || {}),
			[`${dateProperty}_date_to`]: e.target.value
		});
	};

	const handleKeyDown = (e) => {
		if (e.keyCode === 8 || e.keyCode === 46) {
			e.preventDefault();
		}
	};

	return (
		<Grid container>
			<Grid item>
				<Inline responsiveWrap={false} horizontalAlignment={horizontalAlignment.rightAlignSiblings}>
					<Select options={dateOptions} value={dateOption} onChange={handleChangeDate} disabled={disabled} />
					{!isNullOrUndefined(onRemoveFilter) && (
						<ToolbarButton title="Remove Filter" icon={<CloseIcon />} onClick={onRemoveFilter} size="xs" disabled={disabled} />
					)}
				</Inline>
			</Grid>
			{dateOption === dateOptionsLookup.custom && (
				<Grid item>
					{isOnlyXs && <Grid item></Grid>}
					<Grid item>
						<Grid container>
							<Grid item xs={4}>
								<Typography variant="body" verticalAlignment={verticalAlignment.center}>
									From
								</Typography>
							</Grid>
							<Grid item xs={8}>
								<TextField
									required
									type="date"
									value={moment(from).format('yyyy-MM-DD') || ''}
									onKeyDown={handleKeyDown}
									onChange={handleDateFromChange}
									disabled={disabled}
								/>
							</Grid>
							<Grid item xs={4}>
								<Typography variant="body" verticalAlignment={verticalAlignment.center}>
									To
								</Typography>
							</Grid>
							<Grid item xs={8}>
								<TextField
									required
									type="date"
									value={moment(to).format('yyyy-MM-DD') || ''}
									onKeyDown={handleKeyDown}
									onChange={handleDateToChange}
									disabled={disabled}
								/>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			)}
		</Grid>
	);
};

export default DateFilter;

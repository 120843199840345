import { chartIntervals } from '@truescope-web/react/lib/components/charts/enums';
import { dateOptionsLookup } from '@truescope-web/utils/lib/dates';
import { stringIsNullOrEmpty } from '@truescope-web/utils/lib/strings';
import { createDashboard, createNewDashboardTemplate } from '../views/dashboard/builder/DashboardBuilderConstants';
import MediaOverviewDashboardTemplate from '../views/dashboard/builder/MediaOverviewDashboardTemplate';

/**
 * creates a 'media overview' dashboard for the user
 * @param {*} workspace
 * @param {*} getClientApi
 * @param {*} dashboardDataContext
 * @returns the dashboard
 */
export const createMediaOverviewDashboard = async (workspace, getClientApi, dashboardDataContext) => {
	const dashboard = {
		...createNewDashboardTemplate(),
		name: 'Media Overview'
	};
	const defaultInterval = dashboardDataContext.chartIntervalsLookup[chartIntervals.day];
	const defaultFilters = {
		publication_date_option: dateOptionsLookup.last30Days
	};
	MediaOverviewDashboardTemplate.onCreate(dashboard, defaultInterval, defaultFilters, dashboardDataContext, workspace);

	const { dashboard: newDashboard } = await createDashboard(getClientApi, dashboard, workspace.workspace_id);
	return newDashboard;
};

/**
 * creates a new dashboard and registers the workspace as having had its first login
 * @param {*} setWorkspace
 * @param {*} getClientApi
 * @param {*} dashboardDataContext
 */
export const completeFirstLogin = async (workspace, getClientApi, dashboardDataContext) => {
	const mediaOverviewDashboard = await createMediaOverviewDashboard(workspace, getClientApi, dashboardDataContext);

	const api = await getClientApi();
	const { userSettings, message: userSettingsMessage } = await api.patch(
		`workspace/v1/${workspace.workspace_id}/settings/complete-first-login`
	);

	if (!stringIsNullOrEmpty(userSettingsMessage)) {
		throw new Error(userSettingsMessage);
	}

	return {
		...workspace,
		userSettings: {
			...(workspace.userSettings || {}),
			...(userSettings || {}),
			awaitingFirstLogin: false
		},
		dashboards: [
			...(workspace.dashboards || []),
			{
				dashboard_id: mediaOverviewDashboard.dashboard_id,
				name: mediaOverviewDashboard.name,
				object_level_permission_id: mediaOverviewDashboard.object_level_permission_id
			}
		]
	};
};

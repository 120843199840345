import React, { forwardRef } from 'react';
import { infoCardTypes } from '@truescope-web/react/lib/components/widgets/InfoCard';
import ChannelInfoCard from './ChannelInfoCard/ChannelInfoCard';

const InfoCardComponents = forwardRef(({ type }, ref) => {
	switch (true) {
		case type === infoCardTypes.channelCard:
			return (
				<div ref={ref}>
					<ChannelInfoCard />
				</div>
			);
		default:
			return (
				<div>
					<ChannelInfoCard />
				</div>
			);
	}
});

export default InfoCardComponents;

import isEqual from 'lodash.isequal';
import React, { useCallback, useMemo } from 'react';
import OptionChipTextField from '@truescope-web/react/lib/components/form/OptionChipTextField';
import { useConfigContext } from '../../Config/ConfigProvider';
import PopoverSelect from './popoverSelect/PopoverSelect';

/**
 * takes workspace queries, filters our paused ones and converts them to options for the popover select
 * @param {object[]} workspaceQueries workspace queries
 * @param {boolean} includePaused if true, includes paused queries. false by default
 * @returns options
 */
export const getQueriesAsPopoverSelectOptions = (workspaceQueries, includePaused = false) =>
	workspaceQueries
		.reduce((options, { query_id, name, workspace_query_group_name, workspace_query_group_id, is_paused }) => {
			if (!is_paused || includePaused) {
				options.push({
					label: `${name}${is_paused ? ' (paused)' : ''}`,
					value: query_id,
					groupLabel: workspace_query_group_name,
					groupValue: workspace_query_group_id
				});
			}
			return options;
		}, [])
		//throwing in zzz here to make 'ungrouped' appear at the bottom of the list
		.sort((a, b) => (a.groupName || 'zzz').localeCompare(b.groupName || 'zzz'));

const QueriesFilter = ({ onChange, value: queryIds, autoFocus, className = '', startAdornment, disabled, label }) => {
	const [{ workspace }] = useConfigContext();

	const options = useMemo(() => getQueriesAsPopoverSelectOptions(workspace.queries), [workspace.queries]);

	const selectedOptions = useMemo(
		() =>
			(queryIds || []).map((queryId) => ({
				label: workspace.queriesLookup[queryId]?.name || '(deleted query)',
				value: queryId,
				groupLabel: workspace.queriesLookup[queryId]?.workspace_query_group_name,
				groupValue: workspace.queriesLookup[queryId]?.workspace_query_group_id
			})),
		[workspace.queriesLookup, queryIds]
	);

	const handleChange = useCallback(
		(_e, newOptions) => {
			const newSortedOptions = newOptions.sort((a, b) => a.value - b.value);
			const newSortedOptionValues = newSortedOptions.map(({ value }) => value);

			if (!isEqual(newSortedOptionValues, queryIds)) {
				onChange(new Event('onChange'), newSortedOptionValues, newSortedOptions);
			}
		},
		[queryIds, onChange]
	);

	const handlePopoverClose = useCallback(
		(newOptions) => {
			handleChange(undefined, newOptions);
		},
		[handleChange]
	);

	return (
		<OptionChipTextField
			className={className}
			autoFocus={autoFocus}
			value={selectedOptions}
			label={label}
			startAdornment={startAdornment}
			disabled={disabled}
			placeholder="Click to select"
			onChange={handleChange}
			popoverComponent={PopoverSelect}
			popoverTooltip="Select Queries"
			popoverProps={{
				onClose: handlePopoverClose,
				options: options,
				selectedOptions: selectedOptions,
				placeholder: 'Type to search for Queries',
				allowCheckableGroups: true
			}}
		/>
	);
};

export default QueriesFilter;

import React from 'react';
import { components } from 'react-select';
import Select from '@truescope-web/react/lib/components/form/Select';
import Typography from '@truescope-web/react/lib/components/layout/Typography';
import { reportsActivityMetricOptions } from './ReportsActivityChartConstants';

const CustomSelect = ({ value, onChange, selectedKey, disabledValue }) => {
	const className = `reports-activity-chart__metric-options__${selectedKey}-option`;

	const options = reportsActivityMetricOptions.map((option) => ({
		...option,
		disabled: option.value === disabledValue
	}));

	const handleChange = (e, value) => {
		onChange(e, value, selectedKey);
	};

	return (
		<div className={className}>
			<Select
				options={options}
				value={value}
				onChange={handleChange}
				menuPosition="fixed"
				components={{
					Control: ({ children, ...props }) => (
						<components.Control {...props}>
							<span className={`${className}__start-adornment`} /> {children}
						</components.Control>
					)
				}}
				blurInputOnSelect
			/>
		</div>
	);
};

const SelectMetrics = ({ value, onChange }) => (
	<>
		<CustomSelect value={value.left} disabledValue={value.right} selectedKey="left" onChange={onChange} />
		<Typography className="reports-activity-chart__metric-options__label">vs</Typography>
		<CustomSelect value={value.right} disabledValue={value.left} selectedKey="right" onChange={onChange} />
	</>
);

export default SelectMetrics;

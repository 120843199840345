import cloneDeep from 'lodash.clonedeep';
import React, { useEffect, useState } from 'react';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Popover from '@mui/material/Popover';
import Checkbox from '@truescope-web/react/lib/components/form/Checkbox';
import OptionChipTextField from '@truescope-web/react/lib/components/form/OptionChipTextField';
import Typography from '@truescope-web/react/lib/components/layout/Typography';
import { arrayExclude, arrayIsNullOrEmpty, createNestedLookup } from '@truescope-web/utils/lib/arrays';
import { isNullOrUndefined } from '@truescope-web/utils/lib/objects';

const TreeSelectPopover = ({
	treeNodes,
	checkedLookup,
	expandedLookup,
	labelProperty,
	valueProperty,
	childrenProperty,
	onToggleItemChecked,
	onToggleItemExpanded,
	anchorEl,
	onClose,
	isOpen
}) => {
	const renderListItem = (item, index, parentArrayPath, nestingLevel = 0) => {
		const path = `${parentArrayPath}[${index}]`;
		const hasChildren = !arrayIsNullOrEmpty(item[childrenProperty]);
		const checked = checkedLookup[item[valueProperty]];
		const expanded = Boolean(expandedLookup[item[valueProperty]]);

		return (
			<List dense disablePadding key={path} onWheel={(e) => e.stopPropagation()}>
				<ListItem disableGutters className={`tree-select__popover__field-container__list__item`}>
					<ListItemIcon
						style={{
							marginLeft: `${nestingLevel * 16}px`
						}}
					>
						<Checkbox onChange={(e) => onToggleItemChecked(e, item)} checked={checked || false} />
					</ListItemIcon>
					<ListItemText primary={item[labelProperty]} />
					{hasChildren && !checked && (
						<IconButton className="icon-button--xs" onClick={() => onToggleItemExpanded(item)} aria-label={'Expand/collapse'}>
							{expanded ? <ExpandLess /> : <ExpandMore />}
						</IconButton>
					)}
				</ListItem>
				{hasChildren && (
					<Collapse in={expanded} timeout="auto" unmountOnExit>
						{item[childrenProperty].map((child, childIndex) => {
							return renderListItem(child, childIndex, `${path}.${childrenProperty}`, nestingLevel + 1);
						})}
					</Collapse>
				)}
			</List>
		);
	};

	return (
		<Popover
			classes={{ paper: 'popover tree-select__popover' }}
			id="tree-select-popover"
			anchorOrigin={{
				vertical: 'bottom',
				horizontal: 'right'
			}}
			transformOrigin={{
				vertical: 'top',
				horizontal: 'right'
			}}
			anchorEl={anchorEl}
			open={isOpen}
			onClose={onClose}
		>
			<div className="tree-select__popover__field-container">
				{arrayIsNullOrEmpty(treeNodes) ? (
					<Typography variant="subtitle">There appears to be no content that matches your query</Typography>
				) : (
					<List className="tree-select__popover__field-container__list" dense disablePadding>
						{treeNodes.map((item, index) => renderListItem(item, index, 'items'))}
					</List>
				)}
			</div>
		</Popover>
	);
};

const recursivelyGetCheckedValues = (items, valueProperty, childrenProperty, checkedLookup, checked) => {
	if (arrayIsNullOrEmpty(items)) {
		return [];
	}

	return items.reduce((ids, item) => {
		if (!isNullOrUndefined(checkedLookup)) {
			//skip if it doesnt match the value
			const currentChecked = Boolean(checkedLookup[item[valueProperty]]);
			if (currentChecked === checked) {
				return ids;
			}
		}

		ids.push(item[valueProperty]);
		if (!arrayIsNullOrEmpty(item[childrenProperty])) {
			const childIds = recursivelyGetCheckedValues(item[childrenProperty], valueProperty, childrenProperty, checkedLookup, checked);
			ids.push(...childIds);
		}
		return ids;
	}, []);
};

/*
note: currently not supported is external modification of collection
todo: monitor the parentForm's array [name] to check for changes, then propagate them into the chips + checked selection
*/
const TreeSelect = ({
	items: originalItems,
	name = '',
	value: propValue,
	labelAbove,
	label,
	placeholder = 'Click to select',
	labelProperty = 'label',
	valueProperty = 'value',
	childrenProperty = 'options',
	onChange,
	startAdornment,
	disabled,
	className
}) => {
	const [valueLookup, setValueLookup] = useState({});
	const [labelLookup, setLabelLookup] = useState({});
	const [checkedLookup, setCheckedLookup] = useState({});
	const [expandedLookup, setExpandedLookup] = useState({});
	const [treeNodes, setTreeNodes] = useState([]);
	const [chips, setChips] = useState([]);
	const selectedItems = propValue || [];

	useEffect(() => {
		const newTreeNodes = cloneDeep(originalItems);
		const newValueLookup = createNestedLookup(newTreeNodes, valueProperty, childrenProperty);
		const newLabelLookup = createNestedLookup(newTreeNodes, labelProperty, childrenProperty);
		let newCheckedLookup = {};
		setChips(
			selectedItems.map((selectedItemId) => {
				newCheckedLookup[selectedItemId] = true;
				return newValueLookup[selectedItemId][labelProperty];
			})
		);
		setCheckedLookup(newCheckedLookup);
		setValueLookup(newValueLookup);
		setLabelLookup(newLabelLookup);
		setTreeNodes(newTreeNodes);
	}, []);

	const handleChipsChange = (e, newChips) => {
		//uncheck chips that are removed
		let newCheckedLookup = {};
		arrayExclude(chips, newChips).forEach((removeChip) => {
			const item = labelLookup[removeChip.toLowerCase()];
			if (!isNullOrUndefined(item)) {
				//get all values that are checked and uncheck them
				recursivelyGetCheckedValues([item], valueProperty, childrenProperty, checkedLookup, false).forEach((id) => {
					newCheckedLookup[id] = false;
				});
			}
		});

		const validChips = [];
		const validValues = [];
		newChips.forEach((chip) => {
			const item = labelLookup[chip.toLowerCase()];
			if (!isNullOrUndefined(item)) {
				//chip is valid, add it
				validChips.push(chip);
				validValues.push(item[valueProperty]);
				recursivelyGetCheckedValues([item], valueProperty, childrenProperty, checkedLookup, true).forEach((id) => {
					newCheckedLookup[id] = true;
				});
			}
		});

		setChips(validChips);
		setCheckedLookup({
			...checkedLookup,
			...newCheckedLookup
		});

		if (!isNullOrUndefined(onChange)) {
			onChange(
				e,
				validValues,
				validValues.map((i) => valueLookup[i])
			);
		}
	};

	const handleToggleItemExpanded = (item) => {
		const expanded = Boolean(expandedLookup[item[valueProperty]]);
		setExpandedLookup({
			...expandedLookup,
			[item[valueProperty]]: !expanded
		});
	};

	const handleToggleItemChecked = (e, item) => {
		const checked = Boolean(e.target.checked);

		let newCheckedLookup = {};
		const itemId = item[valueProperty];
		const nestedIds = recursivelyGetCheckedValues(item[childrenProperty], valueProperty, childrenProperty);
		[itemId].concat(nestedIds).forEach((id) => {
			newCheckedLookup[id] = checked;
		});

		setCheckedLookup({
			...checkedLookup,
			...newCheckedLookup
		});

		setExpandedLookup({
			...expandedLookup,
			[itemId]: false
		});

		//exclude all possible children
		let nextSelectedItems = arrayExclude(selectedItems, nestedIds);

		if (checked) {
			nextSelectedItems.push(itemId);
		} else {
			const index = nextSelectedItems.indexOf(itemId);
			nextSelectedItems.splice(index, 1);
		}

		const newChips = nextSelectedItems.map((i) => valueLookup[i][labelProperty]);
		newChips.sort();
		setChips(newChips);

		if (!isNullOrUndefined(onChange)) {
			onChange(
				e,
				nextSelectedItems,
				nextSelectedItems.map((i) => valueLookup[i])
			);
		}
	};

	const render = () => {
		return (
			<OptionChipTextField
				className={`tree-select ${className}`}
				value={chips}
				startAdornment={startAdornment}
				onChange={handleChipsChange}
				labelAbove={labelAbove}
				label={label}
				placeholder={placeholder}
				wheelDisabled
				disabled={disabled}
				popoverComponent={TreeSelectPopover}
				popoverTooltip="Select Topics"
				popoverProps={{
					name: name,
					labelProperty: labelProperty,
					valueProperty: valueProperty,
					checkedLookup: checkedLookup,
					expandedLookup: expandedLookup,
					childrenProperty: childrenProperty,
					onToggleItemChecked: handleToggleItemChecked,
					onToggleItemExpanded: handleToggleItemExpanded,
					treeNodes: treeNodes
				}}
			/>
		);
	};

	return render();
};

export default TreeSelect;

import moment from 'moment';
import React from 'react';
import { MdContentCopy } from 'react-icons/md';
import Typography from '@truescope-web/react/lib/components/layout/Typography';
import ToolbarButton from '@truescope-web/react/lib/components/widgets/ToolbarButton';
import { isNullOrUndefined } from '@truescope-web/utils/lib/objects';
import { activityTypesLookup } from '../constants';
import ListItemRow from './ListItemRow';
import { bounceReasonsLookup } from './constants';

const ListItem = ({ activityEvent }) => {
	const date = moment.utc(activityEvent.eventTimestamp).local();
	return (
		<div className="activity-stream-list-item">
			<div className="activity-stream-list-item__header">
				<Typography className="activity-stream-list-item__header__label" bold variant="body" ellipse>
					{activityEvent.eventType} - {date.format('Do MMM')} at {date.format('h:mma')}
				</Typography>
				<ToolbarButton
					className="activity-stream-list-item__header__copy-button"
					icon={<MdContentCopy />}
					title="Copy to clipboard"
					onClick={() =>
						navigator.clipboard.writeText(
							JSON.stringify(
								{
									MessageId: activityEvent.messageId,
									'Report Name': activityEvent.reportTemplateName,
									'Recipient Name': activityEvent.emailTo,
									'Event Timestamp': activityEvent.eventTimestamp,
									'Event Type': activityEvent.eventType,
									Link: activityEvent.link,
									'Event JSON': activityEvent.eventJson
								},
								undefined,
								2
							)
						)
					}
					size="xs"
				/>
			</div>
			<div className="activity-stream-list-item__body">
				<ListItemRow label="Recipient" value={activityEvent.recipient} />
				<ListItemRow label="Report" value={activityEvent.reportTemplateName} link={activityEvent.link} />
				{!isNullOrUndefined(activityEvent.clickLink) && (
					<ListItemRow label="Link" value={activityEvent.clickLink} link={activityEvent.clickLink} />
				)}
				{activityEvent.eventType.toLowerCase() === activityTypesLookup.bounce && (
					<ListItemRow
						label="Reason"
						value={`${activityEvent.eventJson.bounceType} - ${activityEvent.eventJson.bounceSubType}`}
						tooltip={bounceReasonsLookup[activityEvent.eventJson.bounceType][activityEvent.eventJson.bounceSubType]}
					/>
				)}
			</div>
		</div>
	);
};

export default ListItem;

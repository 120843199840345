import React, { useState } from 'react';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ToolbarButton from '@truescope-web/react/lib/components/widgets/ToolbarButton';
import { isNullOrUndefined } from '@truescope-web/utils/lib/objects';

const MoreOptionsMenu = ({ className, toolbarButtonProps = {}, options, disabled, size = 'sm' }) => {
	const [menuAnchorEl, setMenuAnchorEl] = useState(null);

	const handleOpenMenu = (e) => {
		setMenuAnchorEl(e.currentTarget);
	};

	const handleCloseMenu = () => {
		setMenuAnchorEl(null);
	};

	const handleMenuItemClick = (onClick) => {
		onClick();
		handleCloseMenu();
	};

	const renderMenu = () => {
		return (
			<Menu anchorEl={menuAnchorEl} open={!isNullOrUndefined(menuAnchorEl)} onClose={handleCloseMenu} className="menu-small">
				{options.map(({ label, onClick, ...props }) => (
					<MenuItem key={label} onClick={() => handleMenuItemClick(onClick)} {...props}>
						{label}
					</MenuItem>
				))}
			</Menu>
		);
	};

	const renderToolbarButton = () => {
		return (
			<ToolbarButton
				title="More Options"
				icon={<MoreVertIcon />}
				onClick={handleOpenMenu}
				size={size}
				disabled={disabled}
				{...toolbarButtonProps}
			/>
		);
	};

	return (
		<div className={className}>
			{renderToolbarButton()}
			{renderMenu()}
		</div>
	);
};

export default MoreOptionsMenu;

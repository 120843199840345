import React from 'react';
import Modal from '@mui/material/Modal';
import Grid from '@truescope-web/react/lib/components/layout/Grid';

const ResendReportModal = ({ isOpen, onClose, reportInstanceId, reportTemplateId }) => {
	return (
		<Modal className="resend-report-modal" open={isOpen} onClose={onClose}>
			<div className="resend-report-modal__content">
				<Grid container>
					<Grid item>
						TODO: MY-2797 reportId={reportInstanceId} instanceId={reportTemplateId}
					</Grid>
				</Grid>
			</div>
		</Modal>
	);
};

export default ResendReportModal;

import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import ChipTextField from '@truescope-web/react/lib/components/form/ChipTextField';
import Inline, { horizontalAlignment } from '@truescope-web/react/lib/components/layout/Inline';
import ToolbarButton from '@truescope-web/react/lib/components/widgets/ToolbarButton';
import { isNullOrUndefined } from '@truescope-web/utils/lib/objects';

const ChipTextFilter = ({ value, onRemoveFilter, onChange, autoFocus, disabled }) => {
	return (
		<Inline responsiveWrap={false} horizontalAlignment={horizontalAlignment.rightAlignSiblings}>
			<ChipTextField value={value} onChange={onChange} delimiters={[';']} autoFocus={autoFocus} disabled={disabled} />
			{!isNullOrUndefined(onRemoveFilter) && (
				<ToolbarButton title="Remove Filter" icon={<CloseIcon />} onClick={onRemoveFilter} size="xs" disabled={disabled} />
			)}
		</Inline>
	);
};

export default ChipTextFilter;

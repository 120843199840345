import React, { useRef, useState } from 'react';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import ChipTextField from '@truescope-web/react/lib/components/form/ChipTextField';
import ToolbarButton from '@truescope-web/react/lib/components/widgets/ToolbarButton';
import { stringIsNullOrEmpty } from '@truescope-web/utils/lib/strings';
import { getConditionLabel, textMatchingOptionsLookup } from '../FilterConstants';
import ConditionPicker from './ConditionPicker';

const TextFilter = ({ label, fields, onChange, value: valueProp, autoFocus, disabled }) => {
	const ref = useRef();
	const [showConditionPicker, setShowConditionPicker] = useState(false);
	const condition = valueProp?.condition || textMatchingOptionsLookup.must;
	const value = valueProp?.value;

	const handleChange = (e, newChips) => {
		onChange(e, {
			fields,
			condition,
			value: newChips.map((chip) => chip.replace(/"/g, '')).filter((chip) => !stringIsNullOrEmpty(chip))
		});
	};

	const handleShowConditionPickerClick = (e) => {
		setShowConditionPicker(true);
		e.preventDefault();
		e.stopPropagation();
	};

	const handleConditionPickerClose = () => {
		setShowConditionPicker(false);
	};

	const handleConditionChanged = (newCondition) => {
		onChange(new Event('onChange'), {
			fields,
			condition: newCondition,
			value
		});
		handleConditionPickerClose();
	};

	return (
		<div className="title-summary-body-filter full-width" ref={ref}>
			<ChipTextField
				label={`${label} ${getConditionLabel(condition)}`}
				onChange={handleChange}
				value={value || []}
				delimiters={[';']}
				disabled={disabled}
				autoFocus={autoFocus}
				endAdornment={
					<ToolbarButton title="Change Field" icon={<ManageSearchIcon />} onClick={handleShowConditionPickerClick} size="xs" />
				}
			/>
			<ConditionPicker
				value={condition}
				anchorEl={ref.current}
				isOpen={showConditionPicker}
				onClose={handleConditionPickerClose}
				onChange={handleConditionChanged}
			/>
		</div>
	);
};

export default TextFilter;

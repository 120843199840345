import React from 'react';
import { BsActivity } from 'react-icons/bs';
import Collapse from '@mui/material/Collapse';
import { useUserStorageContext } from '@truescope-web/react/lib/components/UserStorageProvider';
import Typography from '@truescope-web/react/lib/components/layout/Typography';
import ToolbarButton from '@truescope-web/react/lib/components/widgets/ToolbarButton';
import SearchPanel from './SearchPanel';

const ActivityStream = () => {
	const { userStorage, setUserStorage } = useUserStorageContext();
	const showPanel = userStorage?.reportsActivity?.showActivityStreamPanel || false;

	const handleTogglePanel = () => {
		setUserStorage(({ reportsActivity, ...prev }) => ({
			...prev,
			reportsActivity: {
				...(reportsActivity || {}),
				showActivityStreamPanel: !reportsActivity?.showActivityStreamPanel
			}
		}));
	};

	return (
		<div className="activity-stream">
			<div className="activity-stream__header">
				<Collapse in={showPanel} orientation="horizontal">
					<Typography className="activity-stream__header__label" variant="h6" bold>
						Activity Stream
					</Typography>
				</Collapse>
				<ToolbarButton
					className="activity-stream__header__toggle-visibility-button"
					title={`${showPanel ? 'Collapse' : 'Expand'} Activity Stream`}
					icon={<BsActivity />}
					onClick={handleTogglePanel}
					size="md"
				/>
			</div>
			<div className="activity-stream__body">
				<Collapse in={showPanel} orientation="horizontal">
					<SearchPanel isOpen={showPanel} />
				</Collapse>
			</div>
		</div>
	);
};

export default ActivityStream;

import React, { memo, useCallback } from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import Checkbox from '@truescope-web/react/lib/components/form/Checkbox';
import { setSelectedOptions } from './reducer';

const GroupOptionCheckbox = ({ options, value, dispatch, selectedOptions, isChecked, isIndeterminate }) => {
	const handleToggleGroupIsChecked = useCallback(
		(isChecked, value, options) => {
			const filteredOptions = selectedOptions.filter(({ groupValue }) => groupValue !== value);
			dispatch(
				isChecked
					? setSelectedOptions(filteredOptions) //remove these options
					: setSelectedOptions(filteredOptions.concat(options)) //add these options
			);
		},
		[selectedOptions, dispatch]
	);

	return (
		<ListItemButton className="option-group__check-button" dense onClick={() => handleToggleGroupIsChecked(isChecked, value, options)}>
			<ListItemIcon>
				<Checkbox
					size="small"
					edge="start"
					checked={isChecked || false}
					indeterminate={isIndeterminate || false}
					disableRipple
					tabIndex={-1}
					inputProps={{ 'aria-labelledby': `group-label-${value}` }}
				/>
			</ListItemIcon>
		</ListItemButton>
	);
};

export default memo(GroupOptionCheckbox);

import React, { useCallback, useEffect, useState } from 'react';
import AsyncCheckableAutocomplete from '@truescope-web/react/lib/components/form/AsyncCheckableAutocomplete';
import useCancelToken from '@truescope-web/react/lib/hooks/useCancelToken';
import { arrayIsNullOrEmpty } from '@truescope-web/utils/lib/arrays';
import { dateOptionsLookup } from '@truescope-web/utils/lib/dates';
import { isNullOrUndefined } from '@truescope-web/utils/lib/objects';
import { hasActiveFilters } from '@truescope-web/utils/lib/search';
import useAggregateQuerySearch from '../../hooks/useAggregateQuerySearch';
import { includeFiltersByFields } from '../FilterConstants';

const AggregateQueryFilter = ({
	onChange,
	value,
	autoFocus,
	disabled,
	queryType,
	label,
	filters: filtersProp,
	aggregateFilterFields,
	preloadDefaultOptions
}) => {
	const [{ globalFilters, workspaceFilters }, setFilters] = useState({});
	const [resetToken] = useCancelToken();
	const [defaultOptions, setDefaultOptions] = useState();
	const [isLoadingDefaultOptions, setIsLoadingDefaultOptions] = useState(false);
	const aggregateSearch = useAggregateQuerySearch();

	useEffect(() => {
		//if you want to explicitly exclude filters from the agg search, use aggregateFilterFields
		//otherwise, this will fallback to last 30 days. that way it can get a defaultOptions
		const exclusionaryFilters =
			!isNullOrUndefined(filtersProp) && !arrayIsNullOrEmpty(aggregateFilterFields)
				? includeFiltersByFields(filtersProp, aggregateFilterFields)
				: { publication_date_option: dateOptionsLookup.last30Days };

		setFilters({
			globalFilters: {
				...exclusionaryFilters,
				global: true
			},
			workspaceFilters: {
				...exclusionaryFilters,
				global: undefined
			}
		});
	}, [filtersProp, aggregateFilterFields]);

	useEffect(() => {
		if (!arrayIsNullOrEmpty(value)) {
			//we dont need default filters if we already have a value picked
			return;
		}

		if (!isNullOrUndefined(preloadDefaultOptions) && hasActiveFilters(workspaceFilters)) {
			setIsLoadingDefaultOptions(true);
			aggregateSearch({
				queryTypes: [queryType],
				cancelToken: resetToken(),
				//to speed things up, we use workspace filters here to get the top
				filters: workspaceFilters,
				limit: preloadDefaultOptions
			})
				.then((newDefaultOptions) => {
					setDefaultOptions(newDefaultOptions);
				})
				.catch((e) => {
					if (e.message !== 'cancelled') {
						console.error(`failed to get default options - ${e.message}`, e);
					}
				})
				.finally(() => {
					setIsLoadingDefaultOptions(false);
				});
		} else {
			setDefaultOptions(undefined);
		}
	}, [value, setDefaultOptions, queryType, preloadDefaultOptions, workspaceFilters, resetToken]);

	const handleGetOptionsAsync = useCallback(
		(searchString, cancelToken) => {
			return aggregateSearch({
				searchString,
				queryTypes: [queryType],
				cancelToken,
				filters: globalFilters
			});
		},
		[aggregateSearch, queryType, globalFilters]
	);

	const handleChange = (_e, newValue) => {
		onChange(new Event('onChange'), newValue, newValue);
	};

	return (
		<AsyncCheckableAutocomplete
			label={label}
			getOptionsAsync={handleGetOptionsAsync}
			debounceMs={250}
			value={value}
			onChange={handleChange}
			inputProps={{ autoFocus }}
			disabled={disabled}
			defaultOptions={defaultOptions}
			isLoading={isLoadingDefaultOptions}
		/>
	);
};

export default AggregateQueryFilter;

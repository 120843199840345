import { arrayIsNullOrEmpty } from '@truescope-web/utils/lib/arrays';
import { stringIsNullOrEmpty } from '@truescope-web/utils/lib/strings';
import { activityTypeOptions } from '../constants';

/**
 * gets the activity stream
 * @param {object} getClientApi
 * @param {string} searchString
 * @param {number} offset
 * @param {number} pageSize
 * @returns list of activity stream events
 */
export const getActivityStream = async (
	getClientApi,
	cancelToken,
	workspaceId,
	dateFrom,
	dateTo,
	searchString,
	activityTypes,
	offset,
	limit
) => {
	const api = await getClientApi();

	const {
		data: { message, items, totalCount }
	} = await api.post(
		`report-activity/v1/${workspaceId}/report-activity-stream/`,
		{
			dateFrom,
			dateTo,
			searchString: stringIsNullOrEmpty(searchString) ? undefined : searchString,
			activityTypes:
				!arrayIsNullOrEmpty(activityTypes) && activityTypes.length < activityTypeOptions.length
					? activityTypes.map((option) => option.value)
					: undefined,
			offset,
			limit
		},
		{ cancelToken }
	);

	if (!stringIsNullOrEmpty(message)) {
		throw new Error(message);
	}

	return { items: items.map((item) => ({ key: `${item.eventTimestamp}_${item.reportTemplateId}`, ...item })), totalCount };
};

/**
 * nice labels for bounce reasons
 * this is structured using bounceType by bounceSubType
 */
export const bounceReasonsLookup = {
	Undetermined: {
		Undetermined: `The recipient's email provider sent a bounce message. The bounce message didn't contain enough information to determine the reason for the bounce. The bounce email, which was sent to the address in the Return-Path header of the email that resulted in the bounce, might contain additional information about the issue that caused the email to bounce.`
	},
	Permanent: {
		General: `The recipient's email provider sent a hard bounce message, but didn't specify the reason for the hard bounce.	When you receive this type of bounce notification, you should immediately remove the recipient's email address from this report. Sending messages to addresses that produce hard bounces can have a negative impact on Truescope’s reputation as a sender.`,
		NoEmail: `The intended recipient's email provider sent a bounce message indicating that the email address doesn't exist. When you receive this type of bounce notification, you should immediately remove the recipient's email address from your reports recipient list. Sending messages to addresses that don't exist can have a negative impact on Truescope’s reputation as a sender.`,
		Suppressed: `The recipient's email address is on Truescope’s suppression list because it has a recent history of producing hard bounces.`,
		OnAccountSuppressionList: `Truescope has suppressed sending to this address because it is on the account-level suppression list.`
	},
	Transient: {
		General: `The recipient's email provider sent a general bounce message. You might be able to send a message to the same recipient in the future if the issue that caused the message to bounce is resolved. If you send an email to a recipient who has an active automatic response rule (such as an "out of the office" message), you might receive this type of notification.`,
		MailboxFull: `The recipient's email provider sent a bounce message because the recipient's inbox was full. You might be able to send to the same recipient in the future when the mailbox is no longer full.`,
		MessageTooLarge: `The recipient's email provider sent a bounce message because message you sent was too large. You might be able to send a message to the same recipient in future if you reduce the size of the reports or choose the HTML report format.`,
		ContentRejected: `The recipient's email provider sent a bounce message because the message you sent contains content that the provider doesn't allow.`,
		AttachmentRejected: `The recipient's email provider sent a bounce message because the message contained an unacceptable attachment. For example, some email providers may reject messages with attachments of a certain file type, or messages with very large attachments. You might be able to send a message to the same recipient if you remove or change the content of the attachment.`
	}
};

import React from 'react';
import Typography from '@truescope-web/react/lib/components/layout/Typography';
import { environmentUrl } from '../../components/Constants';

const DashboardPrintPageFooter = ({ title, page, total }) => {
	return (
		<div className="dashboard-print-page-footer">
			<div className="dashboard-print-page-footer__left">
				<div className="dashboard-print-page-footer__left__content">
					<Typography className="dashboard-print-page-footer__left__content__page-count">
						{title} - Page {page} of {total}
					</Typography>
				</div>
			</div>
			<div className="dashboard-print-page-footer__right">
				<div className="dashboard-print-page-footer__right__content">
					<img
						className="dashboard-print-page-footer__right__content__logo"
						src={`https://${environmentUrl}/truescope-logo.png`}
						alt="Truescope"
					/>
				</div>
			</div>
		</div>
	);
};

export default DashboardPrintPageFooter;

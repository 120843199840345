import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import Auth0ProviderWithRedirectCallback from './components/Auth0ProviderWithRedirectCallback';
import config from './config.json';
import * as serviceWorker from './serviceWorker';

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
	<BrowserRouter
		getUserConfirmation={() => {
			// Passing empty callback to disable default browser prompt
		}}
	>
		<Auth0ProviderWithRedirectCallback
			domain={config.auth0.domain}
			clientId={config.auth0.clientId}
			authorizationParams={{
				redirect_uri: window.location.origin,
				audience: config.auth0.audience,
				connection: config.auth0.connection
			}}
			//not sure if we should be setting these 2 params (below). but changing them forces a log out/error,
			//so its not great to toggle and release, because you're forcing a bad user experience:
			// useRefreshTokens={true}
			// cacheLocation="localstorage"
		>
			<App />
		</Auth0ProviderWithRedirectCallback>
	</BrowserRouter>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

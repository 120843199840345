import React, { useCallback, useEffect, useState } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Container from '@mui/material/Container';
import { useUserStorageContext } from '@truescope-web/react/lib/components/UserStorageProvider';
import Select from '@truescope-web/react/lib/components/form/Select';
import Typography from '@truescope-web/react/lib/components/layout/Typography';
import useCancelToken from '@truescope-web/react/lib/hooks/useCancelToken';
import { useApiLookup } from '../../../../components/providers/ApiLookupProvider';
import { countries } from '../constants';
import TrendingNowItem from './TrendingNowItem';
import { getGoogleTopTrends } from './constants';

const TrendingNow = () => {
	const [getClientApi] = useApiLookup();
	const [resetToken] = useCancelToken();
	const { userStorage, setUserStorage } = useUserStorageContext();

	const [data, setData] = useState([]);
	const [country, setCountry] = useState(userStorage?.trends?.country || countries[0]);
	const [isLoading, setIsLoading] = useState(true);
	const [isHidden, setIsHidden] = useState(false);

	const getData = useCallback(async () => {
		try {
			setIsLoading(true);

			setUserStorage((prev) => {
				prev.trends = {
					country
				};
				return prev;
			});

			const data = await getGoogleTopTrends(getClientApi, country.woeId, resetToken());

			setData(data || []);
			setIsLoading(false);
		} catch (e) {
			setIsHidden(true);
		}
	}, [setData, setUserStorage, setIsLoading, country, getClientApi, resetToken]);

	useEffect(() => {
		getData();
	}, [getData]);

	if (isHidden) {
		return null;
	}

	return (
		<div className="trending-now">
			<Container maxWidth="md" sx={{ height: '100%' }}>
				<div className="trending-now__header">
					<Typography className="trending-now__header__title" variant="h3">
						Trending Now
					</Typography>
					<div className="trends-search__content__left__top-bar__date-country__country">
						<Select
							fullWidth={false}
							disabled={isLoading}
							options={countries}
							onChange={(_e, _value, object) => setCountry(object)}
							value={country}
						/>
					</div>
				</div>

				{isLoading ? (
					<div className="trending-now__loader">
						<CircularProgress />
					</div>
				) : (
					data.map((item) => <TrendingNowItem key={item.title} item={item} country={country} />)
				)}
			</Container>
		</div>
	);
};

export default TrendingNow;

import { wasTokenCancelled } from '@truescope-web/react/lib/hooks/useCancelToken';
import { aggregateQueryTypesLookup } from '@truescope-web/utils/lib/lookups';
import { isNullOrUndefined } from '@truescope-web/utils/lib/objects';
import { stringIsNullOrEmpty } from '@truescope-web/utils/lib/strings';
import { extractError } from '../../Api';
import { serializeFilters } from '../FilterConstants';

/**
 * searches items on the server
 * @param {*} param0
 * @param {*} workspace_id
 * @param {*} search_string
 */
export const executeAggregateQuery = (
	getClientApi,
	searchString,
	queryTypes,
	filters,
	showElasticRequest,
	includeCount,
	workspace,
	appCache,
	user,
	cancelToken
) => {
	return getClientApi()
		.then((api) => {
			return api.post(
				`aggregate-queries/v1`,
				{
					searchString,
					queryTypes,
					filters: !isNullOrUndefined(filters) ? serializeFilters(filters, workspace, appCache, user, true) : null,
					showElasticRequest,
					includeCount
				},
				{ cancelToken }
			);
		})
		.then(({ data }) => {
			const { matches, message } = data;
			if (!stringIsNullOrEmpty(message)) {
				throw new Error(message);
			}

			return { matches };
		})
		.catch((e) => {
			return wasTokenCancelled(cancelToken) ? { cancelled: true } : { message: extractError(e) };
		});
};

/**
 * aggregate query type
 */
export const aggregateQueryTypes = {
	authors: {
		plural: 'Authors',
		singular: 'Author',
		queryType: aggregateQueryTypesLookup.authors,
		excludeFilters: ({ authors, ...filters }) => filters
	},
	cities: {
		plural: 'Cities',
		singular: 'City',
		queryType: aggregateQueryTypesLookup.cities,
		excludeFilters: ({ cities, ...filters }) => filters
	},
	companies: {
		plural: 'Organizations',
		singular: 'Organization',
		queryType: aggregateQueryTypesLookup.companies,
		excludeFilters: ({ companies, ...filters }) => filters
	},
	countries: {
		plural: 'Countries',
		singular: 'Country',
		queryType: aggregateQueryTypesLookup.countries,
		excludeFilters: ({ countries, ...filters }) => filters
	},
	languages: {
		plural: 'Languages',
		singular: 'Language',
		queryType: aggregateQueryTypesLookup.languages,
		excludeFilters: ({ languages, ...filters }) => filters
	},
	locations: {
		plural: 'Locations',
		singular: 'Location',
		queryType: aggregateQueryTypesLookup.locations,
		excludeFilters: ({ locations, ...filters }) => filters
	},
	mediaOwners: {
		plural: 'Media Owners',
		singular: 'Media Owner',
		queryType: aggregateQueryTypesLookup.mediaOwners,
		excludeFilters: ({ media_owners, ...filters }) => filters
	},
	mediaTypes: {
		plural: 'Media Types',
		singular: 'Media Type',
		queryType: aggregateQueryTypesLookup.mediaTypes,
		excludeFilters: ({ media_types, ...filters }) => filters
	},
	people: {
		plural: 'People',
		singular: 'Person',
		queryType: aggregateQueryTypesLookup.people,
		excludeFilters: ({ people, ...filters }) => filters
	},
	sources: {
		plural: 'Channels',
		singular: 'Channel',
		queryType: aggregateQueryTypesLookup.sources,
		excludeFilters: ({ sources, ...filters }) => filters
	},
	sourceNetworks: {
		plural: 'Networks',
		singular: 'Network',
		queryType: aggregateQueryTypesLookup.sourceNetworks,
		excludeFilters: ({ source_networks, ...filters }) => filters
	},
	sourceSections: {
		plural: 'Program/Sections',
		singular: 'Program/Section',
		queryType: aggregateQueryTypesLookup.sourceSections,
		excludeFilters: ({ source_sections, ...filters }) => filters
	},
	regions: {
		plural: 'States/Regions',
		singular: 'State/Region',
		queryType: aggregateQueryTypesLookup.regions,
		excludeFilters: ({ regions, ...filters }) => filters
	}
};

import React from 'react';
import { useUserStorageContext } from '@truescope-web/react/lib/components/UserStorageProvider';
import Select from '@truescope-web/react/lib/components/form/Select';
import { useReportsActivityContext } from '../ReportsActivityProvider';
import { updateState } from '../reducer';
import { activityTableOptions } from './constants';

const ActivitiesTableSelect = ({ anchorEl }) => {
	const [{ activityTableType }, dispatch] = useReportsActivityContext();
	const { setUserStorage } = useUserStorageContext();

	const handleActivityTableTypeChange = (_e, value) => {
		setUserStorage(({ reportsActivity, ...prev }) => ({
			...prev,
			reportsActivity: {
				...(reportsActivity || {}),
				activityTableType: value
			}
		}));
		dispatch(updateState({ activityTableType: value }));
	};

	return (
		<div className="activities-table-select">
			<Select
				options={activityTableOptions}
				value={activityTableType}
				onChange={handleActivityTableTypeChange}
				label="Group by"
				menuPosition="fixed"
				menuPortalTarget={anchorEl}
			/>
		</div>
	);
};

export default ActivitiesTableSelect;

import React from 'react';
import SocialIcon from '@truescope-web/react/lib/components/widgets/SocialIcon';
import { arrayIsNullOrEmpty } from '@truescope-web/utils/lib/arrays';
import { isNullOrUndefined } from '@truescope-web/utils/lib/objects';

const ChannelInfoCardSocialLinks = ({ socialHandles = [] }) => {
	if (arrayIsNullOrEmpty(socialHandles)) {
		return null;
	}

	return (
		<div className="channel-info-card__social">
			{socialHandles.map((socialNetwork) => {
				if (
					isNullOrUndefined(socialNetwork.id) ||
					isNullOrUndefined(socialNetwork.socialNetwork) ||
					isNullOrUndefined(socialNetwork.url)
				) {
					return null;
				}

				return (
					<div key={socialNetwork.id} className="channel-info-card__social__icon">
						<a href={socialNetwork.url} target="_blank" rel="nofollow noopener noreferrer">
							<SocialIcon socialNetwork={socialNetwork.socialNetwork} />
						</a>
					</div>
				);
			})}
		</div>
	);
};

export default ChannelInfoCardSocialLinks;

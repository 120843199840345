import * as am4core from '@amcharts/amcharts4/core';
import React, { createContext, memo, useContext, useEffect, useState } from 'react';
import { useUserStorageContext } from '@truescope-web/react/lib/components/UserStorageProvider';
import { am4themes } from '@truescope-web/react/lib/components/charts/amChartsConstants';
import { isNullOrUndefined } from '@truescope-web/utils/lib/objects';
import { themes } from '../theme';

const CssThemeContext = createContext(null);

export const useCssThemeContext = () => {
	const context = useContext(CssThemeContext);
	if (isNullOrUndefined(context)) {
		throw new Error('useCssThemeContext must be used within a CssThemeContext');
	}
	return context;
};

const CssThemeProvider = ({ children }) => {
	const { userStorage } = useUserStorageContext();
	const [themeOverride, setThemeOverride] = useState(null);

	useEffect(() => {
		const theme = themeOverride || userStorage.userSettings?.theme?.value || themes.light.value;
		document.body.className = `theme--${theme} ${userStorage.debug ? 'debug' : ''}`;
		am4core.unuseAllThemes();
		am4core.useTheme(am4themes[theme]);
	}, [userStorage.userSettings?.theme?.value, userStorage.debug, themeOverride]);

	return (
		<CssThemeContext.Provider
			value={{
				setThemeOverride,
				clearThemeOverride: () => setThemeOverride(null)
			}}
		>
			{children}
		</CssThemeContext.Provider>
	);
};

/**
 * a theme override so that only LIGHT is used
 */
export const useLightCssTheme = () => {
	const { setThemeOverride, clearThemeOverride } = useCssThemeContext();
	useEffect(() => {
		setThemeOverride(themes.light.value);
		return () => {
			clearThemeOverride();
		};
	}, [setThemeOverride, clearThemeOverride]);
};

export default memo(CssThemeProvider);

import React, { memo, useCallback, useMemo, useRef, useState } from 'react';
import { IoIosCloseCircle } from 'react-icons/io';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import IconButton from '@mui/material/IconButton';
import Popover from '@mui/material/Popover';
import TextField from '@mui/material/TextField';
import Button from '@truescope-web/react/lib/components/form/Button';
import Typography from '@truescope-web/react/lib/components/layout/Typography';
import { isNullOrUndefined } from '@truescope-web/utils/lib/objects';

const getFriendlyValue = (isOpen, value, label) => {
	const { from = undefined, to = undefined } = value;
	if (isOpen) {
		return '';
	}

	if (from >= 0 && isNullOrUndefined(to)) {
		return `${from} to max`;
	}

	if (isNullOrUndefined(from) && to >= 0) {
		return `none to ${to}`;
	}

	if (from && to) {
		return `${from} to ${to}`;
	}

	if (from >= 0 && to >= 0) {
		return `0 to ${to}`;
	}

	return `All ${label}`;
};

const RangeFilterComponent = memo(({ open = false, value: valueProp = {}, label, onRemove, onChange, keepMounted = false, disabled }) => {
	const textFieldRef = useRef(undefined);
	const [isOpen, setIsOpen] = useState(open);
	const [value, setValue] = useState({ to: valueProp?.to || null, from: valueProp?.from || null });
	const hasValue = !isNullOrUndefined(valueProp?.from) || !isNullOrUndefined(valueProp?.to);

	const handleClose = useCallback(() => {
		setValue({ to: valueProp?.to || null, from: valueProp?.from || null });
		setIsOpen(false);
	}, [setIsOpen, setValue, valueProp]);

	const handleOpen = useCallback(() => setIsOpen(true), [setIsOpen]);

	const handleOnRemove = useCallback(
		(e) => {
			e.preventDefault();

			return isNullOrUndefined(onRemove) ? null : onRemove();
		},
		[onRemove]
	);

	const handleOnUpdate = useCallback(() => {
		onChange(null, value, value);
		setIsOpen(false);
	}, [onChange, handleClose, setIsOpen, value]);

	const handleChange = useCallback(
		(e, type) => {
			const value = parseInt(e.target.value, 10);

			setValue((prevValue) => {
				const nextValue = {
					...prevValue
				};
				if (isNaN(value)) {
					delete nextValue[type];
				} else {
					nextValue[type] = value;
				}

				return nextValue;
			});
		},
		[setValue]
	);

	const adornments = useMemo(
		() => (
			<>
				<div className="range-filter__input__open-icon">
					<IconButton className="range-filter__input__open-icon__button" onClick={handleOpen} disabled={disabled}>
						<ExpandMoreIcon />
					</IconButton>
				</div>
				{!isNullOrUndefined(onRemove) && (
					<div className="range-filter__input__remove-icon__button" onClick={disabled ? undefined : handleOnRemove}>
						<IoIosCloseCircle />
					</div>
				)}
			</>
		),
		[handleOnRemove]
	);

	return (
		<div className="range-filter">
			<TextField
				size="small"
				fullWidth
				onClick={handleOpen}
				className={`range-filter__input ${isOpen ? 'open' : ''} textfield-container__mui-control`}
				variant="outlined"
				label={label}
				InputLabelProps={{
					shrink: true
				}}
				value={hasValue ? getFriendlyValue(isOpen, value, label) : `All ${label.toLowerCase()}`}
				ref={textFieldRef}
				InputProps={{
					endAdornment: adornments
				}}
				disabled={disabled}
			/>
			<Popover
				className={`range-filter__popover ${isOpen ? 'open' : ''}`}
				onClose={handleClose}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'left'
				}}
				elevation={0}
				anchorEl={textFieldRef.current}
				keepMounted={keepMounted}
				open={isOpen}
				disablePortal
			>
				<div className="range-filter__popover__container" style={{ width: textFieldRef?.current?.offsetWidth || '100%' }}>
					<div className="range-filter__popover__container__content">
						<div className="range-filter__popover__container__content__input">
							<Typography className="range-filter__popover__container__content__input__label" variant="h6">
								Greater than
							</Typography>
							<TextField
								className="range-filter__popover__container__content__input__greater-than"
								fullWidth
								onChange={(e) => handleChange(e, 'from')}
								value={value?.from === 0 ? '0' : value?.from || ''}
							/>
						</div>
						<div className="range-filter__popover__container__content__input">
							<Typography className="range-filter__popover__container__content__input__label" variant="h6">
								Less than
							</Typography>
							<TextField
								className="range-filter__popover__container__content__input__less-than"
								fullWidth
								onChange={(e) => handleChange(e, 'to')}
								value={value?.to === 0 ? '0' : value?.to || ''}
							/>
						</div>
						<div className="range-filter__popover__container__content__button-container">
							<div className="range-filter__popover__container__content__button-container__button">
								<Button onClick={handleOnUpdate}>Update</Button>
							</div>
							<div className="range-filter__popover__container__content__button-container__button range-filter__popover__container__content__button-container__button--close">
								<Button variant="default" onClick={handleClose}>
									Close
								</Button>
							</div>
						</div>
					</div>
				</div>
			</Popover>
		</div>
	);
});

const RangeFilter = ({ onChange, label, value = {}, disabled }) => {
	return <RangeFilterComponent label={label} onChange={onChange} value={value} disabled={disabled} />;
};

export { RangeFilterComponent };
export default RangeFilter;

import { dashboardDateRanges } from './DashboardBuilderConstants';

const dashboardBuilderReducer = (state, action) => {
	switch (action?.type) {
		case 'update':
			return {
				...state,
				...action.payload
			};
		case 'updateDefaultFilters':
			return {
				...state,
				defaultFilters: {
					...state.defaultFilters,
					...action.payload
				}
			};
		case 'reset':
			return getDashboardBuilderInitialState(state);
		default:
			throw new Error(`unknown reducer action '${action?.type}'`);
	}
};

export const getDashboardBuilderInitialState = (defaultProps) => ({
	templateId: null,
	name: null,
	dashboard: null,
	dateRange: dashboardDateRanges.all,
	defaultFilters: null,
	defaultInterval: null,
	...(defaultProps || {})
});

export const updateDashboardBuilderState = (data, clickAwayContext) => {
	clickAwayContext?.setShowClickAway(true);
	return {
		type: 'update',
		payload: data
	};
};

export default dashboardBuilderReducer;

import { isNullOrUndefined } from '@truescope-web/utils/lib/objects';
import { stringIsNullOrEmpty } from '@truescope-web/utils/lib/strings';

/**
 * given text, this filters options and returns a list of options with highlights
 * first this finds startsWith, then contains (to show priority)
 * @param {string} filterText
 * @param {options[]} options
 * @param {object} selectedOptionsLookup
 * @returns
 */
export const filterOptions = (filterText, options, selectedOptionsLookup, appendExistingSelectedOptions = false) => {
	if (stringIsNullOrEmpty(filterText)) {
		return [];
	}
	const filterTextLowerCase = filterText.toLowerCase();
	const { startsWith, includes, alreadyChecked } = options.reduce(
		(resultsLookup, option) => {
			const optionLabelLowerCase = option.label.toLowerCase();
			const index = optionLabelLowerCase.indexOf(filterTextLowerCase);
			if (index === 0) {
				resultsLookup.startsWith.push({
					...option,
					highlight: (
						<span>
							<b>{option.label.substring(0, filterTextLowerCase.length)}</b>
							{option.label.substring(index + filterTextLowerCase.length)}
						</span>
					)
				});
			} else if (index > 0) {
				resultsLookup.includes.push({
					...option,
					highlight: (
						<span>
							{option.label.substring(0, index)}
							<b>{option.label.substring(index, filterTextLowerCase.length + index)}</b>
							{option.label.substring(index + filterTextLowerCase.length)}
						</span>
					)
				});
			} else if (appendExistingSelectedOptions && !isNullOrUndefined(selectedOptionsLookup[option.value])) {
				resultsLookup.alreadyChecked.push(option);
			}

			return resultsLookup;
		},
		{ startsWith: [], includes: [], alreadyChecked: [] }
	);

	return startsWith.concat(includes).concat(alreadyChecked);
};

import React, { useMemo } from 'react';
import CheckableAutocomplete from '@truescope-web/react/lib/components/form/CheckableAutocomplete';
import { sentimentOptions } from '@truescope-web/utils/lib/sentiments';

const SentimentsFilter = ({ onChange, value: valueProp, autoFocus, disabled }) => {
	const value = useMemo(() => (valueProp || []).map((sentiment) => ({ label: sentiment, value: sentiment })), [valueProp]);

	const handleChange = (_e, newValue) => {
		onChange(
			new Event('onChange'),
			newValue.map(({ value }) => value),
			newValue
		);
	};

	return (
		<CheckableAutocomplete
			label="Sentiment"
			options={sentimentOptions}
			value={value}
			onChange={handleChange}
			inputProps={{ autoFocus }}
			disabled={disabled}
		/>
	);
};

export default SentimentsFilter;

export const settingTypeLookup = {
	// Pins
	queryPin: 'query-pins',
	scopePin: 'scope-pins',
	dashboardPin: 'dashboard-pins',
	reportPin: 'report-template-pins',

	// Options
	analysisSettings: 'analysis-settings',
	inboxPreview: 'inbox-preview'
};

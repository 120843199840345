import moment from 'moment';
import React, { useCallback, useEffect, useMemo, useReducer, useState } from 'react';
import { DataGridPro as MuiDataGridPro } from '@mui/x-data-grid-pro';
import { loadingStatesLookup } from '@truescope-web/react/lib/components/loading/constants';
import { extractError } from '../../../../../components/Api';
import { useConfigContext } from '../../../../../components/Config/ConfigProvider';
import { useApiLookup } from '../../../../../components/providers/ApiLookupProvider';
// import MoreOptionsMenu from '../../../../../components/widgets/MoreOptionsMenu';
import { useReportsActivityContext } from '../../ReportsActivityProvider';
import FullDataGridLoader from '../FullDataGridLoader';
import PartialDataGridLoader from '../PartialDataGridLoader';
import { descendingFirstSortOptions, pageSizeOptions } from '../constants';
import activitiesTableReducer, {
	beginLoadingState,
	getInitialState,
	updatePaginationModel,
	updateSortModel,
	updateState
} from '../reducer';
import { cellValueRenderFunctions } from '../valueFormatters';
import ResendReportModal from './ResendReportModal';
import { getReportActivity } from './constants';

const ReportActivityTable = ({ reportTemplateId }) => {
	const [getClientApi] = useApiLookup();
	const [{ workspace }] = useConfigContext();
	const [{ dateFrom, dateTo }] = useReportsActivityContext();
	const [{ sortModel, nextSortModel, paginationModel, nextPaginationModel, items, loadingState, totalCount }, dispatch] = useReducer(
		activitiesTableReducer,
		getInitialState({
			defaultSortModel: [{ field: 'sendDate', sort: 'desc' }]
		})
	);
	const [resendReportState, setResendReportState] = useState({ isOpen: false, reportTemplateId: undefined, reportInstanceId: undefined });

	const loadData = useCallback(async () => {
		try {
			dispatch(beginLoadingState());
			const { items: newItems, totalCount: newTotalCount } = await getReportActivity(
				getClientApi,
				reportTemplateId,
				workspace.workspace_id,
				dateFrom,
				dateTo,
				nextSortModel?.[0]?.sort === 'desc',
				nextSortModel?.[0]?.field,
				nextPaginationModel.pageSize * nextPaginationModel.page,
				nextPaginationModel.pageSize
			);
			dispatch(
				updateState({
					items: newItems,
					totalCount: newTotalCount,
					loadingState: loadingStatesLookup.idle,
					paginationModel: nextPaginationModel,
					sortModel: nextSortModel
				})
			);
		} catch (e) {
			dispatch(updateState({ loadingState: loadingStatesLookup.idle, errorMessage: extractError(e) }));
		}
	}, [getClientApi, workspace?.workspace_id, dateFrom, dateTo, nextSortModel, nextPaginationModel, reportTemplateId, dispatch]);

	useEffect(() => {
		loadData();
	}, [loadData]);

	const handleResend = useCallback(
		(row) => {
			setResendReportState({
				isOpen: true,
				reportInstanceId: row.reportInstanceId,
				reportTemplateId: row.reportTemplateId
			});
		},
		[setResendReportState]
	);

	const handleReportResendClose = () => {
		setResendReportState({ isOpen: false });
	};

	const columns = useMemo(
		() => [
			{
				field: 'sendDate',
				headerName: 'Date sent',
				width: 251,
				type: 'dateTime',
				valueGetter: ({ value }) => moment(value).toDate(),
				disableColumnMenu: true,
				sortingOrder: descendingFirstSortOptions
			},
			{
				field: 'recipientCount',
				headerName: 'Recipients',
				headerAlign: 'right',
				align: 'right',
				minWidth: 170,
				valueFormatter: cellValueRenderFunctions.formatNumber,
				disableColumnMenu: true,
				sortingOrder: descendingFirstSortOptions
			},
			{
				field: 'sendCount',
				headerName: 'Sends',
				headerAlign: 'right',
				align: 'right',
				minWidth: 170,
				valueFormatter: cellValueRenderFunctions.formatNumber,
				disableColumnMenu: true,
				sortingOrder: descendingFirstSortOptions
			},
			{
				field: 'deliveryCount',
				headerName: 'Deliveries',
				headerAlign: 'right',
				align: 'right',
				minWidth: 170,
				valueFormatter: cellValueRenderFunctions.formatNumber,
				disableColumnMenu: true,
				sortingOrder: descendingFirstSortOptions
			},
			{
				field: 'openCount',
				headerName: 'Opens',
				headerAlign: 'right',
				align: 'right',
				minWidth: 170,
				valueFormatter: cellValueRenderFunctions.formatNumber,
				disableColumnMenu: true,
				sortingOrder: descendingFirstSortOptions
			},
			{
				field: 'opensPercentage',
				headerName: 'Open rate',
				headerAlign: 'right',
				align: 'right',
				minWidth: 170,
				valueFormatter: cellValueRenderFunctions.formatPercentage,
				disableColumnMenu: true,
				sortingOrder: descendingFirstSortOptions
			},
			{
				field: 'clickCount',
				headerName: 'Clicks',
				headerAlign: 'right',
				align: 'right',
				minWidth: 170,
				valueFormatter: cellValueRenderFunctions.formatNumber,
				disableColumnMenu: true,
				sortingOrder: descendingFirstSortOptions
			},
			{
				field: 'clicksPercentage',
				headerName: 'Click rate',
				headerAlign: 'right',
				align: 'right',
				minWidth: 170,
				valueFormatter: cellValueRenderFunctions.formatPercentage,
				disableColumnMenu: true,
				sortingOrder: descendingFirstSortOptions
			},
			{
				field: 'bounceCount',
				headerName: 'Bounces',
				headerAlign: 'right',
				align: 'right',
				minWidth: 170,
				valueFormatter: cellValueRenderFunctions.formatNumber,
				disableColumnMenu: true,
				sortingOrder: descendingFirstSortOptions
			},
			{
				field: 'bouncesPercentage',
				headerName: 'Bounce rate',
				headerAlign: 'right',
				align: 'right',
				minWidth: 170,
				valueFormatter: cellValueRenderFunctions.formatPercentage,
				disableColumnMenu: true,
				sortingOrder: descendingFirstSortOptions
			}
			/*
			TODO: MY-2797
			{
				field: '__more_options__',
				headerName: '',
				width: 50,
				sortable: false,
				renderCell: ({ row }) => (
					<span>
						<MoreOptionsMenu size="xs" disabled={false} options={[{ label: 'Resend', onClick: () => handleResend(row) }]} />
					</span>
				),
				disableColumnMenu: true
			}
			*/
		],
		[handleResend]
	);

	const handleSortModelChange = useCallback(
		(newSortModel) => {
			if (loadingState !== loadingStatesLookup.idle) {
				return;
			}
			dispatch(updateSortModel(newSortModel));
		},
		[dispatch, loadingState]
	);

	const handlePaginationModelChange = useCallback(
		(newPaginationModel) => {
			if (loadingState !== loadingStatesLookup.idle) {
				return;
			}
			dispatch(updatePaginationModel(newPaginationModel));
		},
		[dispatch, loadingState]
	);

	return (
		<div className="report-activity-table nested-table">
			{loadingState === loadingStatesLookup.full ? (
				<FullDataGridLoader />
			) : (
				<MuiDataGridPro
					className="activity-data-grid"
					rows={items}
					columns={columns}
					loading={loadingState === loadingStatesLookup.partial}
					getRowId={(row) => row.reportInstanceId}
					disableRowSelectionOnClick
					disableColumnFilter
					onSortModelChange={handleSortModelChange}
					sortModel={sortModel}
					slots={{
						loadingOverlay: PartialDataGridLoader
					}}
					rowCount={totalCount}
					pagination
					pageSizeOptions={pageSizeOptions}
					paginationModel={paginationModel}
					paginationMode="server"
					onPaginationModelChange={handlePaginationModelChange}
					disableColumnResize
					initialState={{
						pinnedColumns: { left: ['sendDate'] }
					}}
				/>
			)}
			<ResendReportModal onClose={handleReportResendClose} {...resendReportState} />
		</div>
	);
};

export default ReportActivityTable;

import { useCallback } from 'react';
import { useAppCache } from '@truescope-web/react/lib/components/AppCache';
import { useUserStorageContext } from '@truescope-web/react/lib/components/UserStorageProvider';
import { dateOptionsLookup } from '@truescope-web/utils/lib/dates';
import { isNullOrUndefined } from '@truescope-web/utils/lib/objects';
import { stringIsNullOrEmpty } from '@truescope-web/utils/lib/strings';
import { useConfigContext } from '../Config/ConfigProvider';
import { useApiLookup } from '../providers/ApiLookupProvider';
import { executeAggregateQuery } from '../widgets/filterSelect/FilterSelectConstants';

/**
 * this hook is used by the aggregate search filter components
 * it ideally returns up to 10 results as suggestions when the user types text into the component
 */
const useAggregateQuerySearch = () => {
	const [getClientApi] = useApiLookup();
	const [{ workspace, user }] = useConfigContext();
	const { userStorage } = useUserStorageContext();
	const appCache = useAppCache();

	const aggregateSearch = useCallback(
		async ({ searchString, filters, queryTypes, cancelToken, includeCount, limit = 10 }) => {
			const {
				matches = [],
				message,
				cancelled
			} = await executeAggregateQuery(
				getClientApi,
				searchString,
				queryTypes,
				{
					...(filters || {}),
					limit,
					//in future, maybe this should match the location of the filter. like, dashboard or inbox.
					publication_date_option: isNullOrUndefined(filters?.publication_date_option)
						? dateOptionsLookup.last30Days
						: filters.publication_date_option
				},
				userStorage.debug,
				includeCount,
				workspace,
				appCache,
				user,
				cancelToken
			);

			if (cancelled) {
				throw new Error('cancelled');
			}

			if (!stringIsNullOrEmpty(message)) {
				throw new Error(message);
			}

			return queryTypes?.length !== 1 ? matches : matches[queryTypes[0]] || [];
		},
		[getClientApi, workspace, userStorage.debug, appCache]
	);

	return aggregateSearch;
};

export default useAggregateQuerySearch;

import { stringIsNullOrEmpty } from '@truescope-web/utils/lib/strings';

/**
 * extracts an error from an api exception
 * @param {*} param0
 */
export const extractError = (exception) => {
	if (!stringIsNullOrEmpty(exception.response?.data?.message)) {
		console.error('An api error occurred: ', exception.response.data.message, exception.response.data.details);
		return exception.response.data.message;
	}

	if (!stringIsNullOrEmpty(exception.message)) {
		console.error(`An error occurred - ${exception.message}`, exception);
		return exception.message;
	}

	console.error('An unknown error occurred', exception);
	return 'serious error occurred';
};

import createTheme from '@mui/material/styles/createTheme';
import { breakpoints } from '@truescope-web/react/lib/utils/breakpoints';

/*
override some of the deeply nested global MUI Theme settings
*/

var tsblue = {
	50: '#99bdef',
	100: '#6ca0e9',
	200: '#4b8be4',
	300: '#3279dd',
	400: '#1d6ad6',
	500: '#0b5cce',
	600: '#0b55bc',
	700: '#0b4ca8',
	800: '#0b4598',
	900: '#0a3f8a',
	A100: '#82b1ff',
	A200: '#448aff',
	A400: '#2979ff',
	A700: '#2962ff'
};

export default createTheme({
	typography: {
		fontFamily: `Roboto`
	},
	palette: {
		primary: tsblue,
		text: {
			primary: '#2b4265',
			secondary: '#8493A6'
		}
	},
	breakpoints: {
		values: {
			xs: breakpoints.xs.min,
			sm: breakpoints.sm.min,
			md: breakpoints.md.min,
			lg: breakpoints.lg.min
		}
	},
	components: {
		MuiButton: {
			styleOverrides: {
				root: {
					textTransform: 'none'
				},
				startIcon: {
					marginRight: '4px',
					marginLeft: '0'
				}
			}
		},
		MuiClockPicker: {
			styleOverrides: {
				arrowSwitcher: {
					button: {
						padding: 'unset'
					}
				}
			}
		},
		MuiIconButton: {
			styleOverrides: {
				root: {
					padding: 12
				}
			}
		},
		MuiLink: {
			defaultProps: {
				underline: 'none'
			},
			styleOverrides: {
				root: {
					cursor: 'pointer',
					'&[disabled]': {
						cursor: 'default',
						pointerEvents: 'none'
					}
				}
			}
		},
		MuiTextField: {
			defaultProps: {
				variant: 'standard'
			}
		},
		MuiOutlinedInput: {
			styleOverrides: {
				root: {
					padding: 'unset',
					lineHeight: 'unset'
				}
			}
		},
		MuiSelect: {
			defaultProps: {
				variant: 'standard'
			}
		},
		MuiStepper: {
			styleOverrides: {
				root: {
					padding: 24 // https://mui.com/material-ui/migration/v5-component-changes/#remove-built-in-padding
				}
			}
		}
	}
});

/**
 * different themes available in the app
 */
export const themeOptions = [
	{
		label: 'Light',
		value: 'light'
	},
	{
		label: 'Dark',
		value: 'dark'
	}
];

/**
 * a keyed list of themes
 */
export const themes = themeOptions.reduce((acc, cur) => {
	acc[cur.value] = cur;
	return acc;
}, {});

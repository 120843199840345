import React from 'react';
import ScrollView from '@truescope-web/react/lib/components/layout/ScrollView';
import { isBeyondScrollThreshold, mediaItemListLoadingState } from '@truescope-web/react/lib/components/widgets/MediaItemList';
import { arrayIsNullOrEmpty } from '@truescope-web/utils/lib/arrays';
import { useActivityStreamContext } from './ActivityStreamProvider';
import ListItem from './ListItem';
import NoItemsFound from './NoItemsFound';
import Search from './Search';
import SkeletonList from './SkeletonList';

const loadAfterPercent = 0.8;

const ListBody = ({ items, loadingState }) => {
	if (loadingState === mediaItemListLoadingState.loadingAllItems) {
		return <SkeletonList size={10} />;
	}

	return (
		<>
			{items.map((activityEvent) => (
				<ListItem key={activityEvent.key} activityEvent={activityEvent} />
			))}
			{loadingState === mediaItemListLoadingState.loadingMoreItems && <SkeletonList size={4} />}
		</>
	);
};

const List = ({ onLoadMore, onSearch }) => {
	const [{ loadingState, items, totalCount }] = useActivityStreamContext();
	const hasItems = !arrayIsNullOrEmpty(items);

	const handleScroll = (e) => {
		if (
			loadingState === mediaItemListLoadingState.loadingMoreItems ||
			items.length >= totalCount ||
			!isBeyondScrollThreshold(e.target, loadAfterPercent)
		) {
			return;
		}

		onLoadMore();
	};

	return (
		<div className="activity-stream-list">
			<ScrollView fill onScroll={handleScroll}>
				<Search onSearch={onSearch} />
				{loadingState === mediaItemListLoadingState.notLoading && !hasItems ? (
					<NoItemsFound />
				) : (
					<ListBody items={items} loadingState={loadingState} />
				)}
			</ScrollView>
		</div>
	);
};

export default List;

import React from 'react';
import { useAppCache } from '@truescope-web/react/lib/components/AppCache';
import TreeSelect from '../TreeSelect';

const CategoriesFilter = ({ onChange, isClearable, value, classes, disabled }) => {
	const appCache = useAppCache();

	return (
		<TreeSelect
			isClearable={isClearable}
			isMulti
			label="Topics"
			items={appCache.categories}
			onChange={onChange}
			value={value}
			classes={classes}
			disabled={disabled}
		/>
	);
};

export default CategoriesFilter;

import { isNullOrUndefined } from '@truescope-web/utils/lib/objects';
import { getLocale } from '../../../../components/Constants';

const formatter = new Intl.NumberFormat(getLocale(), {
	minimumFractionDigits: 0,
	maximumFractionDigits: 0
});

/**
 * render functions for value
 */
export const valueRenderFunctions = {
	formatPercentage: (value) => (isNullOrUndefined(value) ? '' : `${formatter.format(value * 100).toLocaleString()} %`),
	formatNumber: (value) => (isNullOrUndefined(value) ? '' : value.toLocaleString())
};

/**
 * render functions for cells
 */
export const cellValueRenderFunctions = {
	formatPercentage: ({ value }) => valueRenderFunctions.formatPercentage(value),
	formatNumber: ({ value }) => valueRenderFunctions.formatNumber(value)
};

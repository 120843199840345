import React, { useState } from 'react';
import { HiMagnifyingGlass } from 'react-icons/hi2';
import { useNavigate } from 'react-router-dom';
import { Container, InputAdornment } from '@mui/material';
import TextField from '@truescope-web/react/lib/components/form/TextField';
import Inline, { horizontalAlignment } from '@truescope-web/react/lib/components/layout/Inline';
import ScrollView from '@truescope-web/react/lib/components/layout/ScrollView';
import Typography from '@truescope-web/react/lib/components/layout/Typography';
import ToolbarButton from '@truescope-web/react/lib/components/widgets/ToolbarButton';
import { stringIsNullOrEmpty } from '@truescope-web/utils/lib/strings';
import TrendingNow from './trending-now/TrendingNow';

const Trends = () => {
	const [query, setQuery] = useState('');
	const navigate = useNavigate();

	const handleSearchOnChange = (e) => {
		setQuery(e.target.value);
	};

	const handleOnSearch = () => {
		if (stringIsNullOrEmpty(query.trim())) {
			return;
		}

		navigate(`search`, {
			state: {
				query
			}
		});
	};

	const handleSearchOnKeyDown = (e) => {
		const query = e.target.value.trim();
		if (stringIsNullOrEmpty(query)) {
			return;
		}

		if (e.keyCode === 13) {
			e.preventDefault();
			e.stopPropagation();

			navigate(`search`, {
				state: {
					query
				}
			});
		}
	};

	return (
		<div className="trends">
			<div className="trends__header">
				<Inline horizontalAlignment={horizontalAlignment.rightAlignSiblings} responsiveWrap={false}>
					<Typography variant="h4">Trends</Typography>
				</Inline>
			</div>
			<ScrollView fill>
				<div className="trends__search-label">
					<Container maxWidth="md">
						<Typography variant="h1">Discover how a term is trending across news, social and search</Typography>
					</Container>
				</div>
				<div className="trends__search">
					<div className="trends__search__content">
						<Container maxWidth="md">
							<TextField
								placeholder="Type your search term and press Enter"
								autoFocus
								value={query}
								onChange={handleSearchOnChange}
								onKeyDown={handleSearchOnKeyDown}
								InputProps={{
									className: 'trends__search__content__input',
									endAdornment: (
										<InputAdornment position="end" className="trends__search__content__input__adornment">
											<ToolbarButton
												icon={<HiMagnifyingGlass />}
												onClick={handleOnSearch}
												title="Search"
												size="xs"
												disabled={stringIsNullOrEmpty(query?.trim())}
											/>
										</InputAdornment>
									)
								}}
							/>
						</Container>
					</div>
				</div>
				<TrendingNow />
			</ScrollView>
		</div>
	);
};

export default Trends;

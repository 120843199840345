import cloneDeep from 'lodash.clonedeep';
import { loadingStatesLookup } from '@truescope-web/react/lib/components/loading/constants';
import { arrayIsNullOrEmpty } from '@truescope-web/utils/lib/arrays';
import { pageSizeOptions } from './constants';

/**
 * updates the state of the reducer
 * @param {object} data
 * @returns reducer action
 */
export const updateState = (data) => {
	return {
		type: 'update',
		payload: data
	};
};

/**
 * updates the sort model
 * @param {array} sortModel
 * @returns reducer action
 */
export const updateSortModel = (sortModel) => {
	return {
		type: 'update-sort-model',
		payload: sortModel
	};
};

/**
 * updates the pagination model
 * @param {array} sortModel
 * @returns reducer action
 */
export const updatePaginationModel = (paginationModel) => {
	return {
		type: 'update-pagination-model',
		payload: paginationModel
	};
};

/**
 * begins loading state
 * @returns reducer action
 */
export const beginLoadingState = () => {
	return {
		type: 'beginLoading'
	};
};

/**
 * given a property and identifying key, updates the state of an item inside the items array
 * @param {string} property property, ie 'reportTemplateId'
 * @param {unknown} key key, ie the value of reportTemplateId
 * @param {object} itemState the updated state of a row item, ie { isSubscribed: true }
 * @returns reducer action
 */
export const updateItemValue = (property, key, itemState) => {
	return {
		type: 'updateItemValue',
		payload: {
			property,
			key,
			itemState
		}
	};
};

/**
 * initial state of the reducer
 * @returns state object
 */
export const getInitialState = (initialValues = {}) => {
	return {
		loadingState: loadingStatesLookup.full,
		errorMessage: undefined,
		items: [],
		benchmark: undefined,
		totalCount: 0,
		paginationModel: undefined,
		nextPaginationModel: {
			page: 0,
			pageSize: pageSizeOptions[0]
		},
		sortModel: undefined,
		nextSortModel: !arrayIsNullOrEmpty(initialValues?.defaultSortModel) ? cloneDeep(initialValues.defaultSortModel) : [],
		defaultSortModel: [],
		isFirstLoad: true,
		...initialValues
	};
};

/**
 * reducer actions
 * @param {object} state
 * @param {object} action
 */
const activitiesTableReducer = (state, action) => {
	switch (action?.type) {
		case 'update':
			return {
				...state,
				...action.payload
			};
		case 'update-sort-model':
			return {
				...state,
				//this is the third click on a column. usually this clears the sorting. but instead, we want to begin sorting by the original column, desc
				//we store the 'next' model so that it doesnt update the grid until AFTER the items have come back. otherwise the grid tries to auto sort the items itself
				nextSortModel: arrayIsNullOrEmpty(action.payload) ? cloneDeep(state.defaultSortModel) : action.payload
			};
		case 'update-pagination-model':
			return {
				...state,
				//see above re 'next' model
				nextPaginationModel: action.payload
			};
		case 'beginLoading':
			return {
				...state,
				errorMessage: undefined,
				loadingState: state.isFirstLoad ? loadingStatesLookup.full : loadingStatesLookup.partial,
				isFirstLoad: false
			};
		case 'updateItemValue':
			const { property, key, itemState } = action.payload;
			const index = state.items.findIndex((item) => item[property] === key);
			if (index < 0) {
				throw new Error(`cannot find item where ${property} === ${key}`);
			}
			const items = [...state.items];
			items[index] = {
				...state.items[index],
				...itemState
			};
			return {
				...state,
				items,
				//if an item has been updated, its likely to have been committed to the server, so we should revert the loading state to idle
				loadingState: loadingStatesLookup.idle
			};
		case 'reset':
			return getInitialState();
		default:
			throw new Error(`unknown reducer action '${action?.type}'`);
	}
};

export default activitiesTableReducer;

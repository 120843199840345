import React, { createContext, useCallback, useContext, useState } from 'react';

const SharedLoadingContext = createContext({});

/**
 * creates a shared loading state
 */
export const useSharedLoadingState = (loadingKey) => {
	const [loadingStateLookup, setLoadingStateLookup] = useContext(SharedLoadingContext);
	const isLoading = loadingStateLookup[loadingKey];
	const setIsLoading = useCallback(
		(newIsLoading) => {
			setLoadingStateLookup((prev) => ({
				...prev,
				[loadingKey]: newIsLoading
			}));
		},
		[setLoadingStateLookup, loadingKey]
	);

	return [isLoading, setIsLoading];
};

/**
 * contains a shared state for loading
 */
const SharedLoadingStateProvider = ({ children }) => {
	const [loadingStateLookup, setLoadingStateLookup] = useState({});
	return <SharedLoadingContext.Provider value={[loadingStateLookup, setLoadingStateLookup]}>{children}</SharedLoadingContext.Provider>;
};

export default SharedLoadingStateProvider;

import React from 'react';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import Link from '@mui/material/Link';
import Typography from '@truescope-web/react/lib/components/layout/Typography';
import ToolbarButton from '@truescope-web/react/lib/components/widgets/ToolbarButton';
import { stringIsNullOrEmpty } from '@truescope-web/utils/lib/strings';

const ListItemRow = ({ label, value, link, tooltip }) => {
	return (
		<div className="activity-stream-list-item__body__row">
			<Typography variant="subtitle">{label}</Typography>
			{!stringIsNullOrEmpty(link) ? (
				<div className="activity-stream-list-item__body__row__link">
					<Link className="link" href={link} target="_blank" rel="nofollow noopener noreferrer">
						{value.replace(/(https|http):\/\//, '')}
					</Link>
				</div>
			) : (
				<Typography variant="body" ellipse>
					{value}
				</Typography>
			)}
			{!stringIsNullOrEmpty(tooltip) && <ToolbarButton icon={<HelpOutlineIcon />} title={tooltip} size="xs" />}
		</div>
	);
};

export default ListItemRow;

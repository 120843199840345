import { reportsActivityMetricsLookup } from './ReportsActivityChartConstants';

/**
 * updates the state of the reducer
 * @param {object} data
 * @returns reducer action
 */
export const updateState = (data) => {
	return {
		type: 'update',
		payload: data
	};
};

/**
 * initial state of the reducer
 * @returns state object
 */
export const getInitialState = ({ selectedMetrics, ...defaultValues }) => {
	return {
		isLoading: true,
		errorMessage: undefined,
		chartData: undefined,
		selectedMetrics: {
			left: reportsActivityMetricsLookup.openCount.value,
			right: reportsActivityMetricsLookup.clickCount.value,
			...(selectedMetrics || {})
		},
		...defaultValues
	};
};

/**
 * reducer actions
 * @param {object} state
 * @param {object} action
 */
const reportsActivityChartReducer = (state, action) => {
	switch (action?.type) {
		case 'update':
			return {
				...state,
				...action.payload
			};
		case 'reset':
			return getInitialState();
		default:
			throw new Error(`unknown reducer action '${action?.type}'`);
	}
};

export default reportsActivityChartReducer;

import React from 'react';
import ValidationPlaceholder from '@truescope-web/react/lib/components/form/ValidationPlaceholder';
import Grid from '@truescope-web/react/lib/components/layout/Grid';
import Typography from '@truescope-web/react/lib/components/layout/Typography';
import QueriesFilter from '../../../components/widgets/filterComponents/QueriesFilter';
import { updateDashboardBuilderState } from './DashboardBuilderReducer';

const FilterStep = ({ state, dispatch, validationState }) => {
	const handleChange = (_e, value) => {
		dispatch(
			updateDashboardBuilderState({
				defaultFilters: {
					...state.defaultFilters,
					query_ids: value
				}
			})
		);
	};

	return (
		<Grid container>
			<Grid item>
				<Typography variant="subtitle">Choose which query to analyze on this Dashboard</Typography>
			</Grid>
			<Grid item>
				<QueriesFilter onChange={handleChange} value={state.defaultFilters.query_ids} label="Queries" />
			</Grid>
			<Grid item>
				<ValidationPlaceholder validationResult={validationState?.results?.query_ids} />
			</Grid>
		</Grid>
	);
};

export default FilterStep;

export const initialState = {
	workspace: undefined,
	isInitialized: false,
	isLoading: true,
	user: undefined
};

export const setIsLoading = (isLoading = false) => ({
	type: 'update',
	state: { isLoading }
});

export const clearWorkspace = () => ({
	type: 'update',
	state: { workspace: null }
});

export const updateWorkspace = (values) => ({
	type: 'updateWorkspace',
	state: { workspace: values, isLoading: false }
});

export const updateUser = (user) => ({
	type: 'updateUser',
	state: { user }
});

export const update = (state) => ({
	type: 'update',
	state
});

const configReducer = (state = initialState, action) => {
	switch (action.type) {
		case 'update': {
			return {
				...state,
				...action.state
			};
		}
		case 'updateWorkspace': {
			return {
				...state,
				...action.state,
				workspace: {
					...state.workspace,
					...action.state.workspace
				}
			};
		}
		case 'updateUser': {
			const { user } = action.state;
			return {
				...state,
				user: {
					...state.user,
					...user
				}
			};
		}
		default:
			throw new Error(`unknown reducer action '${action.type}'`);
	}
};

export default configReducer;

/**
 * activity types lookup
 */
export const activityTypesLookup = {
	delivered: 'delivered',
	click: 'click',
	open: 'open',
	bounce: 'bounce',
	send: 'send'
};

/**
 * activity type labels
 */
export const activityTypeLabels = {
	delivered: 'Delivered',
	click: 'Click',
	open: 'Open',
	bounce: 'Bounce',
	send: 'Send'
};

/**
 * activity type options
 */
export const activityTypeOptions = Object.values(activityTypesLookup).map((activityType) => ({
	label: activityTypeLabels[activityType],
	value: activityType
}));

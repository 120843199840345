import React, { memo, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Link from '@mui/material/Link';
import { useUserStorageContext } from '@truescope-web/react/lib/components/UserStorageProvider';
import Select from '@truescope-web/react/lib/components/form/Select';
import Inline from '@truescope-web/react/lib/components/layout/Inline';
import Typography from '@truescope-web/react/lib/components/layout/Typography';
import { isNullOrUndefined } from '@truescope-web/utils/lib/objects';
import { stringIsNullOrEmpty } from '@truescope-web/utils/lib/strings';
import { useConfigContext } from '../Config/ConfigProvider';

const getThemeOptions = (themes) =>
	themes.map((theme) => ({
		label: theme.name,
		value: theme.workspace_theme_id,
		icon: theme?.images?.badge?.url,
		iconBackground: isNullOrUndefined(theme?.images?.badge?.url) ? theme.font_styles.font_color : 'transparent'
	}));

const SelectTheme = ({
	label = 'Choose Theme',
	value: valueProp,
	onChange,
	onClickManageThemes,
	showIcons = true,
	defaultThemeIndex = 0,
	validationResult = null,
	classes = {},
	...selectProps
}) => {
	const [{ workspace }] = useConfigContext();
	const navigate = useNavigate();
	const { userStorage, setUserStorage } = useUserStorageContext();
	const themes = workspace.themes || [];
	const [themeOptions, setThemeOptions] = useState(getThemeOptions(themes));
	const [value, setValue] = useState(null);

	useEffect(() => {
		setThemeOptions(getThemeOptions(themes));
	}, [themes]);

	useEffect(() => {
		if (!isNullOrUndefined(valueProp)) {
			setValue(valueProp);
			return;
		}
		const defaultTheme =
			userStorage.workspaceSettings[workspace.workspace_id]?.lastUsedThemeId || themeOptions[defaultThemeIndex]?.value;
		setValue(defaultTheme);
		if (!isNullOrUndefined(onChange)) {
			onChange(new Event('onChange'), defaultTheme);
		}
	}, [valueProp, defaultThemeIndex, themeOptions, userStorage.workspaceSettings[workspace.workspace_id]?.lastUsedThemeId]);

	const handleThemeChange = (e, themeId) => {
		setUserStorage((prev) => {
			return {
				...prev,
				workspaceSettings: {
					...prev.workspaceSettings,
					[workspace.workspace_id]: {
						...(prev.workspaceSettings[workspace.workspace_id] || {}),
						lastUsedThemeId: themeId
					}
				}
			};
		});
		if (!isNullOrUndefined(onChange)) {
			onChange(e, themeId);
		}
	};

	const handleManageThemesClick = () => {
		if (!isNullOrUndefined(onClickManageThemes)) {
			onClickManageThemes();
		}
		navigate(`/w/${workspace.workspace_id}/workspace/themes/${valueProp}`);
	};

	return (
		<div className="select-theme">
			<Typography variant="subtitle" className={`select-theme__label ${!stringIsNullOrEmpty(classes?.label) ? classes.label : ''}`}>
				{label}
			</Typography>
			<Inline>
				<Select
					options={themeOptions}
					onChange={handleThemeChange}
					showIcons={showIcons}
					value={value}
					validationResult={validationResult}
					{...selectProps}
				/>
				<Link className="select-theme__link" onClick={handleManageThemesClick}>
					Edit
				</Link>
			</Inline>
		</div>
	);
};

export default memo(SelectTheme);

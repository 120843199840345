import React, { useMemo } from 'react';
import IconSelect from '@truescope-web/react/lib/components/widgets/IconSelect';
import { useConfigContext } from '../../Config/ConfigProvider';
import { environmentUrl } from '../../Constants';

// import IconSelect from './IconSelect';

const MediaTypesFilter = ({ onChange, value, disabled }) => {
	const [{ workspace }] = useConfigContext();
	const iconOptions = useMemo(
		() =>
			(workspace.mediaTypes || []).map((mediaType) => ({
				imgSource: `https://${environmentUrl}/icons/mediatypes/${mediaType.value?.toLowerCase()}.png`,
				label: mediaType.label,
				value: mediaType.value
			})),
		[workspace.mediaTypes]
	);

	return <IconSelect iconMax={9} iconOptions={iconOptions} value={value} size="xs" onChange={onChange} disabled={disabled} />;
};

export default MediaTypesFilter;

import { aggregateQueryTypesLookup } from '@truescope-web/utils/lib/lookups';
import { marketsLookup } from '@truescope-web/utils/lib/lookups';
import { isNullOrUndefined } from '@truescope-web/utils/lib/objects';
import { stringIsNullOrEmpty } from '@truescope-web/utils/lib/strings';
import { fullTextSearchFields, titleAndSummarySearchFields, titleSearchFields } from './FilterConstants';
import AggregateQueryFilter from './filterComponents/AggregateQueryFilter';
import BooleanFilter from './filterComponents/BooleanFilter';
import CategoriesFilter from './filterComponents/CategoriesFilter';
import ChipTextFilter from './filterComponents/ChipTextFilter';
import DateFilter from './filterComponents/DateFilter';
import EntitySentimentsFilter from './filterComponents/EntitySentimentsFilter';
import MediaTypesFilter from './filterComponents/MediaTypesFilter';
import QueriesFilter from './filterComponents/QueriesFilter';
import RangeFilter from './filterComponents/RangeFilter';
import ScopesFilter from './filterComponents/ScopesFilter';
import SentimentsFilter from './filterComponents/SentimentsFilter';
import SimpleFilter from './filterComponents/SimpleFilter';
import TextFilter from './filterComponents/TextFilter';

export const filterGroupNameLookup = {
	content: 'Content',
	textAnalytics: 'Text Analytics',
	feed: 'Feed',
	source: 'Source'
};

export const filterGroupSortOrder = {
	[filterGroupNameLookup.feed]: 1,
	[filterGroupNameLookup.source]: 2,
	[filterGroupNameLookup.content]: 3,
	[filterGroupNameLookup.textAnalytics]: 4
};

export const filterDefinitions = {
	audience: {
		component: RangeFilter,
		option: { label: 'Potential Impressions', value: 'audience' },
		field: 'audience',
		requiresWorkspaceId: false,
		group: {
			name: filterGroupNameLookup.source,
			sort_order: 11
		},
		marketIds: undefined
	},
	authors: {
		component: AggregateQueryFilter,
		componentProps: {
			queryType: aggregateQueryTypesLookup.authors
		},
		option: { label: 'Authors', value: 'authors' },
		field: 'author_ids',
		requiresWorkspaceId: false,
		group: {
			name: filterGroupNameLookup.source,
			sort_order: 5
		},
		marketIds: undefined
	},
	categories: {
		component: CategoriesFilter,
		option: { label: 'Topics', value: 'categories' },
		field: 'categories.category_id',
		requiresWorkspaceId: false,
		group: {
			name: filterGroupNameLookup.textAnalytics,
			sort_order: 3
		},
		marketIds: undefined
	},
	cities: {
		component: AggregateQueryFilter,
		componentProps: {
			queryType: aggregateQueryTypesLookup.cities
		},
		option: { label: 'Cities', value: 'cities' },
		field: 'cities',
		requiresWorkspaceId: false,
		group: {
			name: filterGroupNameLookup.source,
			sort_order: 9
		},
		marketIds: undefined
	},
	companies: {
		component: AggregateQueryFilter,
		componentProps: {
			queryType: aggregateQueryTypesLookup.companies
		},
		option: { label: 'Organizations', value: 'companies' },
		field: 'companies_mentioned.id',
		requiresWorkspaceId: false,
		group: {
			name: filterGroupNameLookup.textAnalytics,
			sort_order: 4
		},
		marketIds: undefined
	},
	countries: {
		component: AggregateQueryFilter,
		componentProps: {
			queryType: aggregateQueryTypesLookup.countries
		},
		option: { label: 'Countries', value: 'countries' },
		field: 'countries',
		requiresWorkspaceId: false,
		group: {
			name: filterGroupNameLookup.source,
			sort_order: 7
		},
		marketIds: undefined
	},
	emojis: {
		component: ChipTextFilter,
		option: { label: 'Emojis', value: 'emojis' },
		field: 'emoji',
		requiresWorkspaceId: false,
		group: {
			name: filterGroupNameLookup.content,
			sort_order: 1
		},
		marketIds: undefined
	},
	entity_sentiments: {
		component: EntitySentimentsFilter,
		option: { label: 'Entity Sentiment', value: 'entity_sentiments' },
		field: 'entity_sentiments',
		requiresWorkspaceId: true,
		group: {
			name: filterGroupNameLookup.textAnalytics,
			sort_order: 7
		},
		marketIds: undefined
	},
	hashtags: {
		component: ChipTextFilter,
		option: { label: 'Hashtags', value: 'hashtags' },
		field: 'hashtags',
		requiresWorkspaceId: false,
		group: {
			name: filterGroupNameLookup.content,
			sort_order: 2
		},
		marketIds: undefined
	},
	item_date_option: {
		component: DateFilter,
		componentProps: {
			dateProperty: 'item'
		},
		option: { label: 'Crawl Date', value: 'item_date_option' },
		field: 'item_date',
		requiresWorkspaceId: false,
		marketIds: undefined
	},
	junk: {
		component: BooleanFilter,
		option: { label: 'Junk', value: 'junk' },
		componentProps: {
			componentLabel: 'Show junk'
		},
		field: 'junk',
		requiresWorkspaceId: true,
		group: {
			name: filterGroupNameLookup.feed,
			sort_order: 4
		},
		marketIds: undefined
	},
	key_phrases: {
		component: ChipTextFilter,
		option: { label: 'Key Phrases', value: 'key_phrases' },
		field: 'key_phrases',
		requiresWorkspaceId: false,
		group: {
			name: filterGroupNameLookup.textAnalytics,
			sort_order: 7
		},
		marketIds: undefined
	},
	languages: {
		component: AggregateQueryFilter,
		componentProps: {
			queryType: aggregateQueryTypesLookup.languages
		},
		option: { label: 'Languages', value: 'languages' },
		field: 'languages',
		requiresWorkspaceId: false,
		group: {
			name: filterGroupNameLookup.content,
			sort_order: 7
		},
		marketIds: undefined
	},
	locations: {
		component: AggregateQueryFilter,
		componentProps: {
			queryType: aggregateQueryTypesLookup.locations
		},
		option: { label: 'Locations', value: 'locations' },
		field: 'entities.extracted_entity_id',
		requiresWorkspaceId: false,
		group: {
			name: filterGroupNameLookup.textAnalytics,
			sort_order: 6
		},
		marketIds: undefined
	},
	majorMentions: {
		component: BooleanFilter,
		option: { label: 'Major mentions', value: 'majorMentions' },
		componentProps: {
			componentLabel: 'Major mentions only'
		},
		field: 'majorMentions',
		requiresWorkspaceId: false,
		group: {
			name: filterGroupNameLookup.textAnalytics,
			sort_order: 2
		},
		marketIds: undefined
	},
	media_owners: {
		component: AggregateQueryFilter,
		componentProps: {
			queryType: aggregateQueryTypesLookup.mediaOwners
		},
		option: { label: 'Media Owners', value: 'media_owners' },
		field: 'media_owners',
		requiresWorkspaceId: false,
		group: {
			name: filterGroupNameLookup.source,
			sort_order: 6
		},
		marketIds: undefined
	},
	media_types: {
		component: MediaTypesFilter,
		componentProps: {
			queryType: aggregateQueryTypesLookup.mediaTypes
		},
		option: { label: 'Media Types', value: 'media_types' },
		field: 'mediatypes',
		requiresWorkspaceId: false,
		group: {
			name: filterGroupNameLookup.source,
			sort_order: 1
		},
		marketIds: undefined
	},
	people: {
		component: AggregateQueryFilter,
		componentProps: {
			queryType: aggregateQueryTypesLookup.people
		},
		option: { label: 'People', value: 'people' },
		field: 'people_mentioned.id',
		requiresWorkspaceId: false,
		group: {
			name: filterGroupNameLookup.textAnalytics,
			sort_order: 5
		},
		marketIds: undefined
	},
	publication_date_option: {
		component: DateFilter,
		componentProps: {
			dateProperty: 'publication'
		},
		option: { label: 'Published', value: 'publication_date_option' },
		field: 'publication_date',
		requiresWorkspaceId: false,
		marketIds: undefined
	},
	query_ids: {
		component: QueriesFilter,
		option: { label: 'Queries', value: 'query_ids' },
		field: 'queries.query_id',
		requiresWorkspaceId: false,
		group: {
			name: filterGroupNameLookup.feed,
			sort_order: 2
		},
		marketIds: undefined
	},
	read: {
		component: BooleanFilter,
		option: { label: 'Read', value: 'read' },
		componentProps: {
			componentLabel: 'Show read'
		},
		field: 'read',
		requiresWorkspaceId: true,
		group: {
			name: filterGroupNameLookup.feed,
			sort_order: 3
		},
		marketIds: undefined
	},
	regions: {
		component: AggregateQueryFilter,
		componentProps: {
			queryType: aggregateQueryTypesLookup.regions
		},
		option: { label: 'State/Regions', value: 'regions' },
		field: 'regions',
		requiresWorkspaceId: false,
		group: {
			name: filterGroupNameLookup.source,
			sort_order: 8
		},
		marketIds: undefined
	},
	scope_ids: {
		component: ScopesFilter,
		option: { label: 'Feeds', value: 'scope_ids' },
		field: 'scopes.scope_id',
		requiresWorkspaceId: false,
		group: {
			name: filterGroupNameLookup.feed,
			sort_order: 1
		},
		marketIds: undefined
	},
	sentiments: {
		component: SentimentsFilter,
		option: { label: 'Sentiment', value: 'sentiments' },
		field: 'sentiment',
		requiresWorkspaceId: true,
		group: {
			name: filterGroupNameLookup.textAnalytics,
			sort_order: 1
		},
		marketIds: undefined
	},
	'simple_query.or': {
		component: SimpleFilter,
		componentProps: {
			placeholder: 'Enter some keywords',
			multiline: false
		},
		option: { label: 'Some of these keywords', value: 'simple_query.or' },
		field: 'simple_query.or',
		requiresWorkspaceId: false,
		group: {
			name: filterGroupNameLookup.content,
			sort_order: 2
		},
		marketIds: undefined
	},
	'simple_query.and': {
		component: SimpleFilter,
		componentProps: {
			placeholder: 'Enter some keywords',
			multiline: false
		},
		option: { label: 'All of these keywords', value: 'simple_query.and' },
		field: 'simple_query.and',
		requiresWorkspaceId: false,
		group: {
			name: filterGroupNameLookup.content,
			sort_order: 1
		},
		marketIds: undefined
	},
	'simple_query.not': {
		component: SimpleFilter,
		componentProps: {
			placeholder: 'Enter some keywords',
			multiline: false
		},
		option: { label: 'None of these keywords', value: 'simple_query.not' },
		field: 'simple_query.not',
		requiresWorkspaceId: false,
		group: {
			name: filterGroupNameLookup.content,
			sort_order: 3
		},
		marketIds: undefined
	},
	social_engagement: {
		component: RangeFilter,
		option: { label: 'Social Engagement', value: 'social_engagement' },
		field: 'social_engagement',
		requiresWorkspaceId: false,
		group: {
			name: filterGroupNameLookup.source,
			sort_order: 12
		},
		marketIds: undefined
	},
	sources: {
		component: AggregateQueryFilter,
		componentProps: {
			queryType: aggregateQueryTypesLookup.sources
		},
		option: { label: 'Channels', value: 'sources' },
		field: 'source_source_id',
		requiresWorkspaceId: false,
		group: {
			name: filterGroupNameLookup.source,
			sort_order: 3
		},
		marketIds: undefined
	},
	source_dmarank: {
		component: RangeFilter,
		option: { label: 'DMA Rank', value: 'source_dmarank' },
		field: 'source_dmarank',
		requiresWorkspaceId: false,
		group: {
			name: filterGroupNameLookup.source,
			sort_order: 10
		},
		marketIds: [marketsLookup.northAmerica]
	},
	source_networks: {
		component: AggregateQueryFilter,
		componentProps: {
			queryType: aggregateQueryTypesLookup.sourceNetworks
		},
		option: { label: 'Networks', value: 'source_networks' },
		field: 'source_networks',
		requiresWorkspaceId: false,
		group: {
			name: filterGroupNameLookup.source,
			sort_order: 2
		},
		marketIds: [marketsLookup.northAmerica]
	},
	source_sections: {
		component: AggregateQueryFilter,
		componentProps: {
			queryType: aggregateQueryTypesLookup.sourceSections
		},
		option: { label: 'Program/Sections', value: 'source_sections' },
		field: 'source_sections',
		requiresWorkspaceId: false,
		group: {
			name: filterGroupNameLookup.source,
			sort_order: 4
		},
		marketIds: [marketsLookup.northAmerica]
	},
	title: {
		component: TextFilter,
		componentProps: {
			fields: titleSearchFields
		},
		option: { label: 'Headline', value: 'title' },
		field: 'title',
		requiresWorkspaceId: false,
		group: {
			name: filterGroupNameLookup.content,
			sort_order: 6
		},
		marketIds: undefined
	},
	title_summary: {
		component: TextFilter,
		componentProps: {
			fields: titleAndSummarySearchFields
		},
		option: { label: 'Headline and summary', value: 'title_summary' },
		field: 'title_summary',
		requiresWorkspaceId: false,
		group: {
			name: filterGroupNameLookup.content,
			sort_order: 5
		},
		marketIds: undefined
	},
	title_summary_body: {
		component: TextFilter,
		componentProps: {
			fields: fullTextSearchFields
		},
		option: { label: 'Full text', value: 'title_summary_body' },
		field: 'title_summary_body',
		requiresWorkspaceId: false,
		group: {
			name: filterGroupNameLookup.content,
			sort_order: 4
		},
		marketIds: undefined
	}
};

/**
 * allows filter definitions to be accessed by their aggregate query type
 */
export const aggregateQueryFilterDefinitionsLookup = Object.values(filterDefinitions).reduce((lookup, filterDefinition) => {
	if (!isNullOrUndefined(filterDefinition.componentProps?.queryType)) {
		lookup[filterDefinition.componentProps.queryType] = filterDefinition;
	}
	return lookup;
}, {});

/**
 * resolves the elastic field to a filter field
 * @param {*} elasticQueryField
 * @returns filterField
 */
export const getFilterFieldFromElasticField = (elasticQueryField) => {
	return stringIsNullOrEmpty(elasticQueryField)
		? null
		: Object.values(filterDefinitions).find(({ field }) => field === elasticQueryField)?.option?.value;
};

/**
 * gets the object type that a widget uses
 * @param {*} elasticQueryField
 * @returns filterDefinition
 */
export const getFilterDefinitionFromElasticField = (elasticQueryField) => {
	return stringIsNullOrEmpty(elasticQueryField)
		? null
		: Object.values(filterDefinitions).find(({ field }) => field === elasticQueryField);
};

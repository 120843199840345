export const getSources = async (getClientApi, cancelToken, sourceId) => {
	try {
		const api = await getClientApi();
		const { data } = await api.get(`/sources/v1/${sourceId}`, {
			cancelToken
		});
		return data;
	} catch (e) {
		if (e.message === 'cancelled') {
			return;
		}

		console.error(`failed to get source - ${e.message}`);
	}
};

import React, { forwardRef, memo } from 'react';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@truescope-web/react/lib/components/form/Checkbox';
import { isNullOrUndefined } from '@truescope-web/utils/lib/objects';
import { addSelectedOption, removeSelectedOption } from './reducer';

const CheckableOption = forwardRef(
	({ className, highlight, option, isChecked, isIndeterminate, dispatch, showGroupName, disabled, allowCheckboxes, onClick }, ref) => {
		const labelId = `checkbox-list-label-${option.value}`;

		const handleClick = (e) => {
			e.stopPropagation();
			if (!isNullOrUndefined(onClick)) {
				onClick(option);
			} else {
				dispatch(isChecked && !isIndeterminate ? removeSelectedOption(option) : addSelectedOption(option));
			}
		};

		return (
			<ListItem ref={ref} className={`checkable-option ${className || ''}`} key={option.value} disablePadding>
				<ListItemButton className="checkable-option__button" onClick={handleClick} dense disabled={disabled}>
					{allowCheckboxes && (
						<ListItemIcon className="checkable-option__button__icon">
							<Checkbox
								className="checkable-option__button__icon__checkbox"
								size="small"
								edge="start"
								checked={isChecked}
								indeterminate={isIndeterminate}
								tabIndex={-1}
								disableRipple
								inputProps={{ 'aria-labelledby': labelId }}
							/>
						</ListItemIcon>
					)}
					<ListItemText
						className="checkable-option__button__text"
						id={labelId}
						primary={highlight || option.label}
						secondary={showGroupName ? option.groupLabel : undefined}
					/>
				</ListItemButton>
			</ListItem>
		);
	}
);

export default memo(CheckableOption);

import React, { createContext, memo, useContext, useReducer } from 'react';
import { isNullOrUndefined } from '@truescope-web/utils/lib/objects';
import configReducer, { initialState } from './configReducer';

const ConfigContext = createContext(null);

export const useConfigContext = (optional = false) => {
	const context = useContext(ConfigContext);
	if (!optional && isNullOrUndefined(context)) {
		throw new Error('useConfigContext must be used within a ConfigProvider');
	}
	return context;
};

const ConfigProvider = ({ children }) => {
	const [state, dispatch] = useReducer(configReducer, initialState);

	return <ConfigContext.Provider value={[state, dispatch]}>{children}</ConfigContext.Provider>;
};

export default memo(ConfigProvider);

import moment from 'moment';
import { wasTokenCancelled } from '@truescope-web/react/lib/hooks/useCancelToken';
import { getJunkedMediaItemIds } from '@truescope-web/react/lib/utils/itemCache';
import { arrayIsNullOrEmpty, firstOrDefault } from '@truescope-web/utils/lib/arrays';
import { dateOptionsLookup } from '@truescope-web/utils/lib/dates';
import { extractedEntityTypes } from '@truescope-web/utils/lib/entityHelpers';
import { deserializeSyndicationInformation, getHostedImageUrl } from '@truescope-web/utils/lib/mediaItem';
import { isBroadcast, isBroadcastFromSupplier, mediaTypesLookup } from '@truescope-web/utils/lib/mediaTypes';
import { isNullOrUndefined } from '@truescope-web/utils/lib/objects';
import { joinDefined } from '@truescope-web/utils/lib/strings';
import { suppliersLookup } from '@truescope-web/utils/lib/suppliers';
import { extractError } from '../Api';
import { sendWebSocketRequestPromise } from '../providers/WebSockets/constants';
import { serializeFilters } from '../widgets/FilterConstants';

const getPublishedDateString = (state, item) => {
	const { workspace } = state || {};
	const { source_media_type, supplier_info_id, publication_date } = item || {};

	//if it's print item, display unadjusted publication date
	if (source_media_type === mediaTypesLookup.print) {
		return moment(publication_date, 'YYYY-MM-DD HH:mm:ss').format('DD MMMM YYYY');
	}

	if (!isBroadcast(source_media_type)) {
		return moment(item.publication_date, 'YYYY-MM-DD HH:mm:ssZ').local().format('DD MMMM YYYY h:mma');
	}

	if (
		isBroadcastFromSupplier(source_media_type, supplier_info_id, suppliersLookup.tveyes) &&
		!arrayIsNullOrEmpty(workspace) &&
		!isNullOrUndefined(workspace[0].item_date)
	) {
		// we use the workspace[0].item_date in 'local time' to display broadcast item
		return moment(workspace[0].item_date, 'YYYY-MM-DD HH:mm:ss').format('DD MMMM YYYY h:mma');
	}

	//if it's broadcast, display unadjusted publication date and time
	return moment(publication_date, 'YYYY-MM-DD HH:mm:ss').format('DD MMMM YYYY h:mma');
};

export const convertItem = (workspace, item, userId) => {
	let state = {
		checked: false
	};

	let syndicationInformation = {};

	try {
		//sometimes the object comes back from the api as 'workspaces:[null]', so this trims the null entries out
		state.workspace_scopes = (item.workspace_scopes || []).filter((x) => !isNullOrUndefined(x));
		state.workspace_queries = getWorkspaceQueriesWithCurrentNames(
			(item.workspace_queries || []).filter((x) => !isNullOrUndefined(x)),
			workspace.queriesLookup
		);
		state.workspace = (item.workspace || []).filter((x) => !isNullOrUndefined(x));

		state.language_code = item.language_code?.toUpperCase();

		state.author_name = joinDefined(item.author_names, ', ');
		state.default_source_name = buildDefaultSourceName(item);

		state.publishedDate_string = getPublishedDateString(state, item);

		state.item_date__moment = moment.utc(item.item_date, 'YYYY-MM-DD HH:mm:ss').local();
		state.item_date__date = state.item_date__moment.toDate();

		state.junk = isItemJunkForWorkspace(item, workspace.workspace_id);
		state.read = isItemReadForUser(item, userId);
		state.cover_image = determineCoverImage(item);
		state.thumbnail = determineThumbnailImage(item);
		state.page_numbers_string = buildPageNumberString(item);
		state.audience = determineAudienceCount(item);
		state.entityLookup = buildEntityLookup(item);
		state.keywordLookup = buildKeywordLookup(item.workspace_scopes, state.workspace_queries);
		state.mediaStartTimeLookup = buildMediaStartTimeLookup(item);

		syndicationInformation = deserializeSyndicationInformation(item);
		state.subtitle = buildSubtitle(item, state);

		state.social_engagement = determineSocialEngagement(item.social_engagement, item.source_media_type);

		// Remove unneeded large objects and arrays for the sake of preserving memory
		delete item.entities;
		delete item.syndicated_items;

		if (!isNullOrUndefined(item.related_items?.syndicated_count)) {
			state.syndicated_count = item.related_items.syndicated_count;
			delete item.related_items;
		}
	} catch (e) {
		state.message = `An error occurred while attempting to deserialize the media item ${item.item_id} - ${e.message}`;
		console.error(state.message, e.trace);
	}

	return {
		...item,
		...state,
		...syndicationInformation
	};
};

export const convertItems = (workspace, items, userId) => {
	return items.map((item) => convertItem(workspace, item, userId));
};

/**
 * gets items from the media-items api microservice with websockets
 * @param {boolean} isWebsocketReady
 * @param {WebSocket} webSocket
 * @param {function} send
 * @param {string} lastWebsocketMessageId
 * @param {object} user
 * @param {object} workspace
 * @param {object} appCache
 * @param {object} filters
 * @param {number} limit
 * @param {number} offset
 * @param {object} cancelToken
 * @param {string} query_from_timestamp
 * @param {string} query_to_timestamp
 * @param {boolean} peek
 * @param {string} sort
 * @param {boolean} desc
 * @param { { fragment_size: 200, fields: { summary: {}, title: {} } } } } highlight
 * @returns { items: [...], totalCount: 100, syndicatedCount: 100, individualCount: 105 } count and media items
 */
export const searchMediaItemsWithWebSockets = async (
	isWebsocketReady,
	webSocket,
	send,
	websocketMessageId,
	user,
	workspace,
	appCache,
	filters,
	limit,
	offset,
	query_from_timestamp = undefined,
	query_to_timestamp = undefined,
	peek = undefined,
	sort = 'item_date',
	desc = true,
	highlightOptions = undefined
) => {
	try {
		const newFilters = {
			junk: false,
			publication_date_option: dateOptionsLookup.last30Days,
			...filters
		};

		const params = {
			...serializeFilters(newFilters, workspace, appCache, user, true),
			peek,
			query_from_timestamp,
			query_to_timestamp,
			limit,
			offset,
			sort,
			desc,
			showElasticRequest: filters.showElasticRequest
		};

		if (!isNullOrUndefined(highlightOptions)) {
			params.highlight = highlightOptions;
		}

		const { data, messageId } = await sendWebSocketRequestPromise(
			isWebsocketReady,
			webSocket,
			{
				action: 'search',
				data: params,
				workspaceId: workspace.workspace_id
			},
			send,
			websocketMessageId
		);

		data.items = !arrayIsNullOrEmpty(data.items) ? convertItems(workspace, data.items, user?.user_id) : [];

		if (!isNullOrUndefined(data.elasticRequest)) {
			console.debug(
				`searchMediaItems ${peek ? 'peek' : ''} socket elasticRequest`,
				data.elasticRequest?.unifiedRequest || data.elasticRequest
			);
		}

		return {
			...data,
			messageId
		};
	} catch (e) {
		const data = e.response?.data;
		const isESValidationFailure =
			Array.isArray(data) && !arrayIsNullOrEmpty(data) && data.some(({ reason }) => reason?.type === 'query_shard_exception');

		return { message: extractError(e), items: [], isESValidationFailure };
	}
};

/**
 * gets items from the media-items api microservice
 * @param {object} getClientApi
 * @param {object} user
 * @param {object} workspace
 * @param {object} appCache
 * @param {object} filters
 * @param {number} limit
 * @param {number} offset
 * @param {object} cancelToken
 * @param {string} query_from_timestamp
 * @param {string} query_to_timestamp
 * @param {boolean} peek
 * @param {string} sort
 * @param {boolean} desc
 * @param { { fragment_size: 200, fields: { summary: {}, title: {} } } } } highlight
 * @returns { items: [...], totalCount: 100, syndicatedCount: 100, individualCount: 105 } count and media items
 */
export const searchMediaItems = async (
	getClientApi,
	user,
	workspace,
	appCache,
	filters,
	limit,
	offset,
	cancelToken,
	query_from_timestamp = undefined,
	query_to_timestamp = undefined,
	peek = undefined,
	sort = 'item_date',
	desc = true,
	highlightOptions = undefined
) => {
	try {
		const newFilters = {
			junk: false, //todo: this shouldnt be set here. it means you could end up with disjointed results.
			//because in some places you set junk, and in other places you're reusing the filters but you're
			//missing the junk flag. I'm fixing a hotfix RMB-1072 - so for safety im going to leave this here
			//but we should come back and fix the filters being passed IN here so they're correctly setting the
			//junk flag
			publication_date_option: dateOptionsLookup.last30Days,
			...filters
		};

		const params = {
			...serializeFilters(newFilters, workspace, appCache, user, true),
			peek,
			query_from_timestamp,
			query_to_timestamp,
			limit,
			offset,
			sort,
			desc,
			showElasticRequest: filters.showElasticRequest
		};

		if (!isNullOrUndefined(highlightOptions)) {
			params.highlight = highlightOptions;
		}

		const api = await getClientApi();

		const { data } = await api.post(`media-items/v1/${workspace.workspace_id}/items`, params, { cancelToken });

		data.items = !arrayIsNullOrEmpty(data.items) ? convertItems(workspace, data.items, user?.user_id) : [];

		if (!isNullOrUndefined(data.elasticRequest)) {
			console.debug(
				`searchMediaItems ${peek ? 'peek' : ''} elasticRequest`,
				data.elasticRequest?.unifiedRequest || data.elasticRequest
			);
		}

		return data;
	} catch (e) {
		if (wasTokenCancelled(cancelToken)) {
			return { items: [], cancelled: true };
		}
		const data = e.response?.data;
		const isESValidationFailure =
			Array.isArray(data) && !arrayIsNullOrEmpty(data) && data.some(({ reason }) => reason?.type === 'query_shard_exception');

		return { message: extractError(e), items: [], isESValidationFailure };
	}
};

/**
 * converts the entities property of an item into a dictionary lookup
 * @param {*} item
 */
const buildEntityLookup = ({ entities }) => {
	return (entities || []).reduce(
		(entityLookup, entity) => {
			switch (entity.extracted_entity_type_id) {
				case extractedEntityTypes.person:
					entityLookup.people.push(entity);
					break;
				case extractedEntityTypes.location:
					entityLookup.locations.push(entity);
					break;
				case extractedEntityTypes.company:
					entityLookup.companies.push(entity);
					break;
				case extractedEntityTypes.event:
					entityLookup.events.push(entity);
					break;
				case extractedEntityTypes.workOfArt:
					entityLookup.worksOfArt.push(entity);
					break;
				case extractedEntityTypes.consumerGood:
					entityLookup.consumerGoods.push(entity);
					break;
				default:
					console.error('unknown extracted entity type: ', entity.extracted_entity_type_id);
			}

			return entityLookup;
		},
		{ people: [], companies: [], locations: [], events: [], worksOfArt: [], consumerGoods: [] }
	);
};

/**
 * Checks if a user has read an item.
 * @param {*} item - the item to check
 * @param {*} user_id - the user to check read status
 */
const isItemReadForUser = ({ _users_marked_as_read: usersMarkedAsRead }, userId) => {
	return (
		isNullOrUndefined(userId) ||
		(!arrayIsNullOrEmpty(usersMarkedAsRead) && usersMarkedAsRead.some((readUserId) => parseInt(readUserId, 10) === userId))
	);
};

/**
 * Check if an item is marked junk for a workspace
 * @param {*} item - the item to check
 * @param {*} workspace_id - the workspace to check junk status
 */
const isItemJunkForWorkspace = ({ _workspaces_marked_as_junk }, workspaceId) => {
	return (
		!arrayIsNullOrEmpty(_workspaces_marked_as_junk) &&
		_workspaces_marked_as_junk.some((junkedWorkspaceId) => parseInt(junkedWorkspaceId, 10) === workspaceId)
	);
};

/**
 * Grabs the cover image for a given item
 * @param {*} item
 */
const determineCoverImage = ({ images }) => {
	return !arrayIsNullOrEmpty(images) ? getHostedImageUrl(images[0], 880) : null;
};

/**
 * Grabs the thumbnail image for a given item
 * @param {*} item
 */
export const determineThumbnailImage = ({ images }) => {
	return !arrayIsNullOrEmpty(images) ? getHostedImageUrl(images[0], 150) : null;
};

/**
 * Created comma-delimited subtitle for items.
 * @param {*} item
 * @param {*} state
 * @returns {String} subtitle
 */
const buildSubtitle = ({ source_media_type, supplier_info_id }, { default_source_name, author_name, page_numbers_string }) => {
	return isBroadcastFromSupplier(source_media_type, supplier_info_id, suppliersLookup.tveyes)
		? default_source_name
		: joinDefined([default_source_name, author_name, page_numbers_string], ', ');
};

/**
 * Builds Source Name for Media Type. Include the Program information for broadcast types
 * @param {*} item
 * @returns {string} Source Name
 */
const buildDefaultSourceName = ({ source_source_name, source_section_name, source_media_type, supplier_info_id }) => {
	const sourceName = firstOrDefault(source_source_name, '');
	return isBroadcastFromSupplier(source_media_type, supplier_info_id, suppliersLookup.tveyes)
		? joinDefined([sourceName, source_section_name], ', ')
		: sourceName;
};

/**
 * Constructs a string representation of the page numbers associated with an item
 * @param {*} item
 */
const buildPageNumberString = ({ page_numbers }) => {
	if (arrayIsNullOrEmpty(page_numbers)) {
		return null;
	}
	return `Page${page_numbers.length !== 1 ? 's' : ''} ${joinDefined(page_numbers, ', ')}`;
};

/**
 * Returns the give object with values parsed into numbers
 * @param  {{ video_views: number|string, last_retrieval_date: date }} mediaType=undefined
 * @returns { video_views: number }}
 */
const determineSocialEngagement = (socialEngagement = {}, mediaType = undefined) => {
	if (isNullOrUndefined(socialEngagement) || Object.keys(socialEngagement).length === 0) {
		return {};
	}

	const excludedKeys = ['last_retrieval_date'];

	if (!isNullOrUndefined(mediaType)) {
		const isTwitter = mediaType === mediaTypesLookup.twitter;
		if (isTwitter) {
			excludedKeys.push('comments');

			if (socialEngagement.twitter_video_views === 0 || socialEngagement.twitter_video_views === '0') {
				excludedKeys.push('twitter_video_views');
			}
		}
	}

	return Object.entries(socialEngagement).reduce((acc, [key, value]) => {
		if (isNullOrUndefined(value) || excludedKeys.includes(key)) {
			return acc;
		}

		Object.assign(acc, { [key]: parseInt(value, 10) });

		return acc;
	}, {});
};

/**
 * If an audience count is set, returns it, otherwise 0
 * @param {*} item
 */
const determineAudienceCount = (item) => {
	return !isNullOrUndefined(item.audience) ? item.audience : 0;
};

/**
 * Builds a lookup of key words for a media item, as they pertain to different scopes and queries
 * @param {*} item
 */
export const buildKeywordLookup = (workspaceScopes, workspaceQueries) => {
	return (workspaceScopes || [])
		.filter((scope) => !isNullOrUndefined(scope))
		.map(({ scope_id, keyword_matches, name }) => ({ key: `scopeId_${scope_id}`, id: scope_id, name, keyword_matches }))
		.concat(
			(workspaceQueries || [])
				.filter((query) => !isNullOrUndefined(query))
				.map(({ query_id, keyword_matches, name }) => ({ key: `queryId_${query_id}`, id: query_id, name, keyword_matches }))
		);
};

/**
 * These functions are exported for testing purposes and should only be accessed for that purpose.
 */
export const exportedForTesting = {
	buildSubtitle,
	buildDefaultSourceName
};

/**
 * given a list of media item workspace queries, this takes the query lookup (which contains the latest and most up to date query names)
 * and overlays them ontop of a media item. once an item is stamped with a query, the name stays the same. so we use this technique to
 * ensure that the query name is the 'latest' known name (as users can rename queries at any point in time)
 * @param { array } workspaceQueries
 * @param { object } queriesLookup
 * @returns
 */
export const getWorkspaceQueriesWithCurrentNames = (workspaceQueries, queriesLookup) => {
	return workspaceQueries.map((workspaceQuery) => ({
		...workspaceQuery,
		name: !isNullOrUndefined(queriesLookup[workspaceQuery.query_id]) ? queriesLookup[workspaceQuery.query_id].name : workspaceQuery.name
	}));
};

/**
 * Builds a lookup of start times for a media item, as they pertain to different scopes and queries
 * @param {*} item
 */
export const buildMediaStartTimeLookup = ({ workspace_scopes, workspace_queries }) => {
	return (workspace_scopes || [])
		.filter((scope) => !isNullOrUndefined(scope))
		.map(({ scope_id, item_date }) => ({ key: `scopeId_${scope_id}`, timeStamp: item_date }))
		.concat(
			(workspace_queries || [])
				.filter((query) => !isNullOrUndefined(query))
				.map(({ query_id, item_date }) => ({ key: `queryId_${query_id}`, timeStamp: item_date }))
		)
		.sort((a, b) => b.item_date - a.item_date);
};

/**
 * initializes item ids to an empty array
 * @param {*} param0
 * @returns
 */
const initializeItemIds = ({ item_ids, ...filters } = {}) => ({ ...filters, item_ids: item_ids || [] });

/**
 * this takes filters and then applies the locally cached junked/deleted media item ids and appends them to the filter criteria
 * @param {object} filters
 * @returns filters that exclude deleted items (and if you have junk:true, INCLUDES junked items)
 */
export const appendJunkToFilters = (filters) => {
	const { junked, deleted } = getJunkedMediaItemIds();
	if (arrayIsNullOrEmpty(junked) && arrayIsNullOrEmpty(deleted)) {
		return filters;
	}

	const mutatedFilters = {
		...filters,
		or: initializeItemIds(filters.or),
		not: initializeItemIds(filters.not)
	};

	if (!arrayIsNullOrEmpty(deleted)) {
		mutatedFilters.not.item_ids.push(...deleted);
	}

	if (filters.junk) {
		mutatedFilters.or.item_ids.push(...junked);
	} else {
		mutatedFilters.not.item_ids.push(...junked);
	}

	return mutatedFilters;
};

import React, { memo, useCallback, useReducer } from 'react';
import { useEffect } from 'react';
import { FormHelperText } from '@mui/material';
import Popover from '@mui/material/Popover';
import LoadingIndicator from '@truescope-web/react/lib/components/loading/LoadingIndicator';
import { loadingStatesLookup } from '@truescope-web/react/lib/components/loading/constants';
import { arrayIsNullOrEmpty } from '@truescope-web/utils/lib/arrays';
import { isNullOrUndefined } from '@truescope-web/utils/lib/objects';
import PartialLoadingIndicator from '../../PartialLoadingIndicator';
import OptionFilter from './OptionFilter';
import OptionPicker from './OptionPicker';
import reducer, { getInitialState, reinitialize, resetState } from './reducer';

/**
 * PopoverSelect takes a list of options that must be { label, value }
 * if you want to group them, use { label, value, options }
 * @param {*} param0
 * @returns
 */
const PopoverSelect = ({
	loadingState,
	onClose,
	anchorEl,
	isOpen,
	ToolbarButtons,
	options,
	selectedOptions,
	placeholder,
	allowCheckableGroups,
	allowCheckboxes = true,
	onClick,
	emptyStateText = 'No valid items',
	...props
}) => {
	const [state, dispatch] = useReducer(reducer, getInitialState({ options, selectedOptions }));

	useEffect(() => {
		//if the selected options change externally, we need to reload them
		if (isOpen) {
			dispatch(reinitialize(options, selectedOptions));
		}
	}, [isOpen, options, selectedOptions]);

	const handleClose = useCallback(() => {
		onClose(state.selectedOptions);
	}, [onClose, state.selectedOptions]);

	const handleWheel = (e) => {
		e.stopPropagation();
	};

	const renderContainer = () => {
		if (loadingState === loadingStatesLookup.full) {
			return <LoadingIndicator variant="circular" />;
		}

		if (arrayIsNullOrEmpty(options)) {
			return (
				<div className="popover-select__option-picker-container__empty-state">
					<FormHelperText>{emptyStateText}</FormHelperText>
				</div>
			);
		}

		return (
			<OptionPicker
				showAllOptions={state.showAllOptions}
				options={state.options}
				filteredOptions={state.filteredOptions}
				selectedOptions={state.selectedOptions}
				selectedOptionsLookup={state.selectedOptionsLookup}
				groups={state.groups}
				groupCheckedCountsLookup={state.groupCheckedCountsLookup}
				dispatch={dispatch}
				allowCheckableGroups={allowCheckableGroups}
				allowCheckboxes={allowCheckboxes}
				onClick={onClick}
			/>
		);
	};

	return (
		<Popover
			classes={{ paper: 'popover popover-select-paper' }}
			anchorEl={anchorEl}
			open={isOpen}
			onClose={handleClose}
			keepMounted={false}
			anchorOrigin={{
				vertical: 'bottom',
				horizontal: 'left'
			}}
			transformOrigin={{
				vertical: 'top',
				horizontal: 'left'
			}}
			TransitionProps={{
				onExited: () => {
					//once the close animation finishes, clear the state so the previously checked state doesnt flicker on the screen
					dispatch(resetState());
				}
			}}
			{...props}
		>
			<div className="popover-select" onWheel={handleWheel}>
				<div className="popover-select__filter-container">
					<OptionFilter
						options={state.options}
						selectedOptionsLookup={state.selectedOptionsLookup}
						dispatch={dispatch}
						placeholder={placeholder}
						disabled={loadingState === loadingStatesLookup.full}
					/>
				</div>
				<div className="popover-select__option-picker-container">{renderContainer()}</div>
				{!isNullOrUndefined(ToolbarButtons) && (
					<div className="popover-select__toolbar-container">
						<ToolbarButtons selectedOptions={state.selectedOptions} />
					</div>
				)}
				<PartialLoadingIndicator loading={loadingState === loadingStatesLookup.partial} />
			</div>
		</Popover>
	);
};

export default memo(PopoverSelect);

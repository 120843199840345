/**
 * different types of tables that can be selected
 */
export const activityTablesLookup = {
	recipient: 'recipient',
	report: 'report'
};

/**
 * options for activity tables
 */
export const activityTableOptions = [
	{
		label: 'Report',
		value: activityTablesLookup.report
	},
	{
		label: 'Recipient',
		value: activityTablesLookup.recipient
	}
];

/**
 * page size options across all tables
 */
export const pageSizeOptions = [10, 30, 50];

/**
 * allows sorting to happen by decending first
 * note: the default options are ['asc', 'desc', null]. the 'null' allows the column sorting to be cleared. but the usability is weird. so we intentionally override and remove it in both asc/desc sorting options
 */
export const ascendingFirstSortOptions = ['asc', 'desc'];
/**
 * allows sorting to happen by decending first
 */
export const descendingFirstSortOptions = ['desc', 'asc'];

import React, { memo } from 'react';
import List from '@mui/material/List';
import { isNullOrUndefined } from '@truescope-web/utils/lib/objects';
import CheckableOption from './CheckableOption';

const FlatList = ({ options, selectedOptionsLookup, dispatch, onClick, allowCheckboxes }) => (
	<List disablePadding>
		{options.map(({ highlight, ...option }) => (
			<CheckableOption
				key={option.value}
				highlight={highlight}
				dispatch={dispatch}
				isChecked={!isNullOrUndefined(selectedOptionsLookup[option.value])}
				isIndeterminate={selectedOptionsLookup[option.value]?.isIndeterminate === true}
				option={option}
				disabled={option.disabled}
				showGroupName
				onClick={onClick}
				allowCheckboxes={allowCheckboxes}
			/>
		))}
	</List>
);

export default memo(FlatList);

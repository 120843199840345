import React from 'react';
import { useMemo } from 'react';
import { getColorArrowPercent } from '@truescope-web/react/lib/components/charts/cardChart/BenchmarkIndicator';
import { isNullOrUndefined } from '@truescope-web/utils/lib/objects';

const flipColor = (color) => {
	if (color === 'red') {
		return 'green';
	}
	if (color === 'green') {
		return 'red';
	}
	return color;
};

const FullBenchmark = ({ current, previous, isIncreaseBad = false, valueFormatter }) => {
	const { percent, arrow, color } = useMemo(() => getColorArrowPercent(current, previous), [current, previous]);

	return (
		<div className="column-benchmark">
			<div className="column-benchmark__current">{isNullOrUndefined(valueFormatter) ? current : valueFormatter(current)}</div>
			<div className="column-benchmark__previous">
				<div
					className={`column-benchmark__previous__arrow column-benchmark__previous__arrow--${
						isIncreaseBad ? flipColor(color) : color
					}`}
				>
					{arrow} {percent.toFixed(0)}%
				</div>
				<div className="column-benchmark__previous__amount">
					{isNullOrUndefined(valueFormatter) ? previous : valueFormatter(previous)}
				</div>
			</div>
			<div className="column-benchmark__previous-label">previously</div>
		</div>
	);
};

const PartialBenchmark = ({ current, valueFormatter }) => (
	<div className="column-benchmark">
		<div className="column-benchmark__current">{isNullOrUndefined(valueFormatter) ? current : valueFormatter(current)}</div>
	</div>
);

const ColumnBenchmark = ({ current, previous, isIncreaseBad = false, valueFormatter }) =>
	isNullOrUndefined(previous) ? (
		<PartialBenchmark current={current} valueFormatter={valueFormatter} />
	) : (
		<FullBenchmark current={current} previous={previous} isIncreaseBad={isIncreaseBad} valueFormatter={valueFormatter} />
	);

export default ColumnBenchmark;

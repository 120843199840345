import { chartIntervals, chartMapDefinitionIds, chartMapTypeIds, chartTypeIds } from '@truescope-web/react/lib/components/charts/enums';
import { validationResults } from '@truescope-web/react/lib/utils/validation';
import { dateOptionsLookup } from '@truescope-web/utils/lib/dates';
import { marketsLookup } from '@truescope-web/utils/lib/lookups';
import { isNullOrUndefined } from '@truescope-web/utils/lib/objects';
import { objectLevelPermissions } from '@truescope-web/utils/lib/permissions';
import { stringIsNullOrEmpty } from '@truescope-web/utils/lib/strings';
import { getChartMapDefinitionName } from '../../../components/widgets/charts/builder/ChartBuilderConstants';

/**
 * for common default properties to be set against a new dashboard, place them here
 */
export const createNewDashboardTemplate = () => {
	return {
		layout: {
			lg: [],
			md: [],
			sm: [],
			xs: []
		},
		date_range: dashboardDateRanges.all,
		charts: [],
		object_level_permission_id: objectLevelPermissions.admin,
		dashboard_id: null
	};
};

/**
 * icons used for dashboard templates
 */
export const dashboardIcons = {
	defaultTemplate: `${process.env.PUBLIC_URL}/images/widgets/widget-sentiment-dashboard.png`,
	empty: `${process.env.PUBLIC_URL}/images/widgets/widget-blank-dashboard.png`,
	mediaSentiment: `${process.env.PUBLIC_URL}/images/widgets/widget-sentiment-dashboard.png`,
	mediaOverview: `${process.env.PUBLIC_URL}/images/widgets/widget-query-dashboard.png`,
	compare: `${process.env.PUBLIC_URL}/images/widgets/widget-sentiment-dashboard.png`
};

/**
 */
export const defaultDashboardDateOptions = {
	chart_interval_id: chartIntervals.day,
	search_filter: {
		publication_date_option: dateOptionsLookup.last30Days,
		publication_date_from: null,
		publication_date_to: null
	}
};

/**
 * Options for dashboard date ranges
 */
export const dashboardDateRanges = {
	all: 1,
	separate: 2
};

/**
 * Options for dashboard date range
 */
export const dashboardDateRangeOptions = [
	{
		label: 'Use the same date range setting for all charts',
		value: dashboardDateRanges.all
	},
	{ label: 'Use a separate date range setting for each chart', value: dashboardDateRanges.separate }
];
/**
 * creates a dashboard
 * @param {*} param0
 * @param {*} query
 */
export const createDashboard = async (getClientApi, dashboard, workspace_id) => {
	const api = await getClientApi();
	const { data } = await api.put(`dashboards/v1`, { dashboard, workspace_id });
	if (!stringIsNullOrEmpty(data.message)) {
		throw new Error(data.message);
	}
	return data;
};

/**
 * validation rules for creating a dashboard in the dashboard builder
 */
export const dashboardBuilderValidationRules = {
	templateId: ({ templateId }) => {
		return isNullOrUndefined(templateId) ? validationResults.required('Please select a template') : validationResults.ok();
	},
	name: ({ name }) => {
		return stringIsNullOrEmpty(name) ? validationResults.required('Please enter a dashboard name') : validationResults.ok();
	}
};
/**
 * Get chart_map_definition_id and chart_map_type_id based on market id
 * @param {object} workspace
 * @returns {{ chart_map_definition_id: number, chart_map_type_id: number }}
 */
export const getChartMapIdsByMarket = (market_id, chartTypeId) => {
	const isHeatMap = chartTypeId === chartTypeIds.heatMap;
	switch (market_id) {
		case marketsLookup.northAmerica:
			return {
				chart_map_definition_id: isHeatMap ? chartMapDefinitionIds.unitedStates : chartMapDefinitionIds.northAmerica,
				chart_map_type_id: isHeatMap ? chartMapTypeIds.segmentByRegionState : chartMapTypeIds.segmentByCity
			};
		case marketsLookup.newZealand:
			return {
				chart_map_definition_id: chartMapDefinitionIds.newZealand,
				chart_map_type_id: chartMapTypeIds.segmentByRegionState
			};
		case marketsLookup.singapore:
			return {
				chart_map_definition_id: chartMapDefinitionIds.asia,
				chart_map_type_id: isHeatMap ? chartMapTypeIds.segmentByCountry : chartMapTypeIds.segmentByCity
			};
		default:
			return {
				chart_map_definition_id: chartMapDefinitionIds.world,
				chart_map_type_id: chartMapTypeIds.segmentByCountry
			};
	}
};

export const getChartMapFieldsByMarket = (workspace, { chart_metric_ids, chart_definition_name, chart_type_id }, dashboardDataContext) => {
	const chartMapFields = getChartMapIdsByMarket(workspace.market_id, chart_type_id);
	chartMapFields.chart_name = getChartMapDefinitionName(
		chartMapFields.chart_map_type_id,
		chartMapFields.chart_map_definition_id,
		{ chart_metric_ids, chart_definition_name },
		dashboardDataContext
	);
	return chartMapFields;
};

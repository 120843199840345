import { stringIsNullOrEmpty } from '@truescope-web/utils/lib/strings';

/**
 * gets report activities
 * @param {object} getClientApi api lookup
 * @param {number} workspaceId workspace id
 * @param {string} dateFrom iso8601 utc date to
 * @param {string} dateTo iso8601 utc date to
 * @param {boolean} desc if true, orders by descending
 * @param {string} sortBy column name sorting
 * @param {number} offset how many items to offset by
 * @param {number} limit how many items to get
 * @returns list of activity stream events
 */
export const getReportActivities = async (getClientApi, workspaceId, dateFrom, dateTo, desc, sortBy, offset, limit) => {
	const api = await getClientApi();

	const {
		data: { message, items, totalCount, benchmark }
	} = await api.post(`report-activity/v1/${workspaceId}/reports/`, {
		dateFrom,
		dateTo,
		offset,
		limit,
		desc,
		sortBy
	});

	if (!stringIsNullOrEmpty(message)) {
		throw new Error(message);
	}

	return { items, totalCount, benchmark };
};

/**
 * gets report activities
 * @param {object} getClientApi api lookup
 * @param {number} reportTemplateId report template id
 * @param {number} workspaceId workspace id
 * @param {string} dateFrom iso8601 utc date to
 * @param {string} dateTo iso8601 utc date to
 * @param {boolean} desc if true, orders by descending
 * @param {string} sortBy column name sorting
 * @param {number} offset how many items to offset by
 * @param {number} limit how many items to get
 * @returns list of activity stream events
 */
export const getReportActivity = async (getClientApi, reportTemplateId, workspaceId, dateFrom, dateTo, desc, sortBy, offset, limit) => {
	const api = await getClientApi();

	const {
		data: { message, items, totalCount }
	} = await api.post(`report-activity/v1/${workspaceId}/reports/${reportTemplateId}`, {
		dateFrom,
		dateTo,
		offset,
		limit,
		desc,
		sortBy
	});

	if (!stringIsNullOrEmpty(message)) {
		throw new Error(message);
	}

	return { items, totalCount };
};

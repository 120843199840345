const Mailbox = `${process.env.PUBLIC_URL}/images/empty-state/mailbox.png`;
const Rocketship = `${process.env.PUBLIC_URL}/images/empty-state/rocket-ship.png`;
const InboxNoResults = `${process.env.PUBLIC_URL}/images/empty-state/no-search-results.png`;
const AddChart = `${process.env.PUBLIC_URL}/images/empty-state/add-chart.png`;
const CheckSpellingNoQueries = `${process.env.PUBLIC_URL}/images/empty-state/check-spelling-no-queries.png`;
const CreateDashboard = `${process.env.PUBLIC_URL}/images/empty-state/create-dashboard.png`;
const NoQueries = `${process.env.PUBLIC_URL}/images/empty-state/no-queries.png`;
const NoReports = `${process.env.PUBLIC_URL}/images/empty-state/no-reports.png`;
const NoActivity = `${process.env.PUBLIC_URL}/images/empty-state/no-activity.png`;

export const emptyStateImagesLookup = {
	rocketship: 'rocketship',
	mailbox: 'mailbox',
	inboxNoResults: 'inboxNoResults',
	addChart: 'addChart',
	checkSpellingNoQueries: 'checkSpellingNoQueries',
	createDashboard: 'createDashboard',
	noQueries: 'noQueries',
	noReports: 'noReports',
	noActivity: 'noActivity'
};

export const getEmptyStateImage = (variant) => {
	switch (variant) {
		case emptyStateImagesLookup.rocketship:
			return Rocketship;
		case emptyStateImagesLookup.mailbox:
			return Mailbox;
		case emptyStateImagesLookup.inboxNoResults:
			return InboxNoResults;
		case emptyStateImagesLookup.addChart:
			return AddChart;
		case emptyStateImagesLookup.checkSpellingNoQueries:
			return CheckSpellingNoQueries;
		case emptyStateImagesLookup.createDashboard:
			return CreateDashboard;
		case emptyStateImagesLookup.noQueries:
			return NoQueries;
		case emptyStateImagesLookup.noReports:
			return NoReports;
		case emptyStateImagesLookup.noActivity:
			return NoActivity;
		default:
			throw new Error(`${variant} is not supported`);
	}
};

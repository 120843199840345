import { workspaceUserActivityTypesLookup } from '@truescope-web/utils/lib/lookups';
import { stringIsNullOrEmpty } from '@truescope-web/utils/lib/strings';

/**
 * when creating a query, you can pick from one of the following three sources.
 * we send this with the activity to know what's being used
 */
export const booleanSourcesLookup = {
	inbox: 'inbox',
	simple: 'simple',
	boolean: 'boolean',
	assistant: 'assistant'
};

/**
 * captures a new query search and it's results
 * @param {object} getClientApi
 * @param {number} workspaceId
 * @param {string} booleanSource
 * @param {string} plaintextQuery
 * @param {object} filters
 * @param {object} results
 */
export const captureNewQuerySearch = async (
	getClientApi,
	workspaceId,
	booleanSource,
	plaintextQuery,
	filters,
	totalCount,
	individualCount,
	syndicatedCount
) => {
	await captureWorkspaceUserActivity(getClientApi, workspaceId, workspaceUserActivityTypesLookup.newQuerySearch, {
		booleanSource,
		plaintextQuery,
		filters,
		results: {
			totalCount,
			individualCount,
			syndicatedCount
		}
	});
};

/**
 * captures an idle event
 * @param {object} getClientApi api lookup
 * @param {number} workspaceId workspace id
 * @param {string} path navigated to path
 */
export const captureIdleEvent = async (getClientApi, workspaceId, path) => {
	await captureWorkspaceUserActivity(getClientApi, workspaceId, workspaceUserActivityTypesLookup.idle, {
		path
	});
};

/**
 * captures a navigation event
 * @param {object} getClientApi api lookup
 * @param {number} workspaceId workspace id
 * @param {string} path current path
 */
export const captureNavigationEvent = async (getClientApi, workspaceId, path) => {
	await captureWorkspaceUserActivity(getClientApi, workspaceId, workspaceUserActivityTypesLookup.navigate, {
		path
	});
};

/**
 * creates a workspace user activity
 * @param {object} getClientApi
 * @param {number} workspaceId
 * @param {number} workspaceUserActivityType
 * @param {object} payload
 */
const captureWorkspaceUserActivity = async (getClientApi, workspaceId, workspaceUserActivityType, payload) => {
	try {
		const api = await getClientApi();
		const {
			data: { message }
		} = await api.put(`workspace-user-activities/v1/${workspaceId}/activities/${workspaceUserActivityType}`, payload);

		if (!stringIsNullOrEmpty(message)) {
			throw new Error(message);
		}
	} catch (e) {
		console.error(`failed to capture workspace user activity - ${e.message}`, e);
	}
};

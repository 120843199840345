import { Auth0Provider } from '@auth0/auth0-react';
import React from 'react';
import { useNavigate } from 'react-router-dom';

// Some browsers have issues with deep links without this code, it fails to redirect, ie Safari
const Auth0ProviderWithRedirectCallback = ({ children, ...props }) => {
	const navigate = useNavigate();
	const onRedirectCallback = (appState) => {
		navigate(appState?.returnTo || window.location.pathname || '/');
	};
	return (
		<Auth0Provider onRedirectCallback={onRedirectCallback} {...props}>
			{children}
		</Auth0Provider>
	);
};

export default Auth0ProviderWithRedirectCallback;

import React, { memo, useEffect, useState } from 'react';
import Typography from '@truescope-web/react/lib/components/layout/Typography';
import Avatar from '@truescope-web/react/lib/components/widgets/Avatar';
import { infoCardUpdate, useInfoCardContext } from '@truescope-web/react/lib/components/widgets/InfoCard';
import useCancelToken from '@truescope-web/react/lib/hooks/useCancelToken';
import { getAvatarUrl } from '@truescope-web/utils/lib/mediaItem';
import { isNullOrUndefined } from '@truescope-web/utils/lib/objects';
import { determineShortName } from '@truescope-web/utils/lib/strings';
import { useApiLookup } from '../../providers/ApiLookupProvider';
import ChannelInfoCardAverages from './ChannelInfoCardAverages';
import { getSources } from './ChannelInfoCardConstants';
import ChannelInfoCardDescription from './ChannelInfoCardDescription';
import ChannelInfoCardEmptyState from './ChannelInfoCardEmptyState';
import ChannelInfoCardSkeleton from './ChannelInfoCardSkeleton';
import ChannelInfoCardSocialLinks from './ChannelInfoCardSocialLinks';

const ChannelInfoCard = () => {
	const [getClientApi] = useApiLookup();
	const [resetToken, cancelToken] = useCancelToken();

	const { state, dispatch } = useInfoCardContext();
	const { anchorElement, data: stateData, previousData } = state;
	const { sourceId } = stateData;
	const [isLoading, setIsLoading] = useState(true);
	const [data, setData] = useState(null);

	const hasUrl = !isNullOrUndefined(data?.url);

	useEffect(() => {
		setIsLoading(true);
		if (isNullOrUndefined(sourceId) || isNullOrUndefined(anchorElement)) {
			cancelToken();
			return;
		}

		if (parseInt(sourceId, 10) === parseInt(previousData?.sourceId, 10)) {
			setData(previousData);
			setIsLoading(false);
			return;
		}

		getSources(getClientApi, resetToken(), sourceId).then((data) => {
			setData(data);
			dispatch(infoCardUpdate({ previousData: data }));
			setIsLoading(false);
		});
	}, [anchorElement, dispatch, sourceId, previousData?.sourceId, getClientApi, resetToken, cancelToken]);

	if (isNullOrUndefined(sourceId) || isNullOrUndefined(anchorElement)) {
		return null;
	}

	if (isLoading) {
		return <ChannelInfoCardSkeleton />;
	}

	if (isNullOrUndefined(data)) {
		return <ChannelInfoCardEmptyState />;
	}

	return (
		<div className="channel-info-card">
			<div className="channel-info-card__header">
				<div className="channel-info-card__header__source">
					<Avatar
						imgSrc={getAvatarUrl({
							url: data.url,
							source_profile_image_url: data.sourceProfileImage
						})}
						alt={data.sourceProfileImage || data.url}
						size="md"
						initials={determineShortName(data.name)}
					/>
				</div>
				<div className="channel-info-card__header__info">
					<Typography className="channel-info-card__header__info__name" bold>
						{data?.name}
					</Typography>
					{hasUrl && (
						<Typography
							className="channel-info-card__header__info__link"
							variant="subtitle"
							onClick={() => window.open(data.url, 'noopener')}
						>
							{data.url.replace('http://', '').replace('https://', '')}
						</Typography>
					)}
				</div>
			</div>
			<ChannelInfoCardSocialLinks socialHandles={data.socialHandles} />
			<ChannelInfoCardDescription data={data} />
			<ChannelInfoCardAverages
				mediaType={data.mediaType}
				averageAudience={data.averageAudience}
				averageSocialEngagement={data.averageSocialEngagement}
			/>
		</div>
	);
};

export default memo(ChannelInfoCard);

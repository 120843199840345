import React from 'react';
import { BsPeople } from 'react-icons/bs';
import { MdOutlineFavoriteBorder } from 'react-icons/md';
import Typography from '@truescope-web/react/lib/components/layout/Typography';
import { isNullOrUndefined } from '@truescope-web/utils/lib/objects';
import { getMediaAudienceTitle } from '@truescope-web/utils/src/mediaTypes';

const ChannelInfoCardAveragesItem = ({ text, icon, count }) => {
	return (
		<div className="channel-card-info-averages">
			{!isNullOrUndefined(count) && (
				<>
					<div className="channel-card-info-averages__icon">{icon}</div>
					<div className="channel-card-info-averages__content">
						<Typography bold>{count}</Typography>
						<Typography className="channel-card-info-averages__content__title">{text}</Typography>
					</div>
				</>
			)}
		</div>
	);
};

const ChannelInfoCardAverages = ({ mediaType, averageAudience, averageSocialEngagement }) => {
	return (
		<div>
			{!isNullOrUndefined(averageAudience) && (
				<ChannelInfoCardAveragesItem icon={<BsPeople />} count={averageAudience} text={getMediaAudienceTitle(mediaType)} />
			)}
			{!isNullOrUndefined(averageSocialEngagement) && (
				<ChannelInfoCardAveragesItem icon={<MdOutlineFavoriteBorder />} count={averageSocialEngagement} text="average engagement" />
			)}
		</div>
	);
};

export default ChannelInfoCardAverages;

import React, { useCallback, useMemo } from 'react';
import { Popper } from '@mui/material';
import CheckableAutocomplete from '@truescope-web/react/lib/components/form/CheckableAutocomplete';
import { stringIsNullOrEmpty } from '@truescope-web/utils/lib/strings';
import { useConfigContext } from '../../Config/ConfigProvider';

const ScopesFilter = ({
	onChange,
	hideShrinkLabel = true,
	value: scopeIds,
	autoFocus,
	InputProps = {},
	inputProps = {},
	className = null,
	useChips = false,
	showCount = true,
	disabled
}) => {
	const [{ workspace }] = useConfigContext();

	const options = useMemo(
		() =>
			workspace.scopes.map(({ scope_id, name }) => ({
				label: name,
				value: scope_id
			})),
		[workspace.scopes]
	);

	const value = useMemo(
		() =>
			(scopeIds || []).map((scopeId) => ({
				label: workspace.scopesLookup[scopeId]?.name || 'deleted feed',
				value: scopeId
			})),
		[workspace.scopes, scopeIds]
	);

	const handleChange = useCallback(
		(_e, newValue) => {
			onChange(
				new Event('onChange'),
				newValue.map(({ value }) => value),
				newValue
			);
		},
		[onChange]
	);

	return (
		<CheckableAutocomplete
			label="Feeds"
			options={options}
			value={value}
			onChange={handleChange}
			PopperComponent={(props) => (
				<Popper
					{...props}
					className={`checkable-select-filter__popper ${stringIsNullOrEmpty(className) ? `` : `${className}__popper`} `}
					placement="bottom-start"
				/>
			)}
			selectAll
			disableClearable
			inputProps={{ autoFocus, ...inputProps }}
			InputProps={{ shrink: hideShrinkLabel, ...InputProps }}
			hideLabel={hideShrinkLabel}
			className={`checkable-select-filter ${className}`}
			useChips={useChips}
			showCount={showCount}
			disabled={disabled}
		/>
	);
};

export default ScopesFilter;

import React, { useCallback, useRef, useState } from 'react';
import { memo } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import Collapse from '@mui/material/Collapse';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@truescope-web/react/lib/components/layout/Typography';
import { isNullOrUndefined } from '@truescope-web/utils/lib/objects';
import { stringIsNullOrEmpty } from '@truescope-web/utils/lib/strings';
import CheckableOption from './CheckableOption';
import GroupOptionCheckbox from './GroupOptionCheckbox';

const CheckableGroup = ({
	group,
	count,
	dispatch,
	selectedOptions,
	selectedOptionsLookup,
	allowCheckableGroups,
	onClick,
	allowCheckboxes,
	scrollBarsRef,
	isLastGroup
}) => {
	const firstItemInGroup = useRef();
	const [isExpanded, setIsExpanded] = useState(group.isExpandedByDefault || false);

	const handleToggleGroupIsExpanded = useCallback(
		(e) => {
			e.stopPropagation();
			setIsExpanded((prev) => !prev);
		},
		[setIsExpanded]
	);

	const handleEntered = () => {
		if (isLastGroup && scrollBarsRef.current) {
			scrollBarsRef.current?.scrollToSmooth?.(firstItemInGroup.current.offsetTop);
		}
	};

	const renderOption = useCallback(
		(option, index) => {
			return (
				<CheckableOption
					ref={index === 0 ? firstItemInGroup : undefined}
					key={option.value}
					className="option-group__checkable-option"
					dispatch={dispatch}
					isChecked={!isNullOrUndefined(selectedOptionsLookup[option.value])}
					isIndeterminate={selectedOptionsLookup[option.value]?.isIndeterminate === true}
					option={option}
					disabled={option.disabled}
					onClick={onClick}
					allowCheckboxes={allowCheckboxes}
				/>
			);
		},
		[selectedOptionsLookup]
	);

	const labelId = `group-label-${group.value}`;
	const isChecked = group.options.length === count;
	const isIndeterminate = count > 0 && count < group.options.length;
	return (
		<>
			<ListItem className="option-group" key={group.value} disablePadding>
				{allowCheckableGroups && (
					<GroupOptionCheckbox
						isChecked={isChecked}
						isIndeterminate={isIndeterminate}
						options={group.options}
						value={group.value}
						dispatch={dispatch}
						selectedOptions={selectedOptions}
					/>
				)}
				<ListItemButton
					className={`option-group__expand-button ${!allowCheckableGroups ? 'option-group__expand-button--extra-padding' : ''}`}
					onClick={handleToggleGroupIsExpanded}
					dense
				>
					<ListItemText
						id={labelId}
						className="option-group__expand-button__group-name"
						primary={
							<Typography ellipse italic={stringIsNullOrEmpty(group.label)}>
								{group.label || 'Ungrouped'}{' '}
								{count > 0 && (
									<span className="option-group__expand-button__group-name__count">
										({count}/{group.options.length})
									</span>
								)}
							</Typography>
						}
					/>
					<ListItemIcon>{isExpanded ? <ExpandMoreIcon /> : <KeyboardArrowRightIcon />}</ListItemIcon>
				</ListItemButton>
			</ListItem>
			<Collapse key={labelId} in={isExpanded} onEntered={handleEntered}>
				{group.options.map(renderOption)}
			</Collapse>
		</>
	);
};

export default memo(CheckableGroup);

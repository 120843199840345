import set from 'lodash.set';
import React, { createContext, memo, useEffect, useMemo, useReducer } from 'react';
import { useUserStorageContext } from '@truescope-web/react/lib/components/UserStorageProvider';
import { facebookLoginStatus, loadFacebook } from '@truescope-web/react/lib/utils/facebook';
import { isNullOrUndefined } from '@truescope-web/utils/lib/objects';
import { facebookAppId } from '../../config';
import { extractError } from '../Api';
import { useConfigContext } from '../Config/ConfigProvider';
import { useApiLookup } from './ApiLookupProvider';

const initialState = {
	status: 'unknown',
	userID: null,
	name: null,
	accessToken: null,
	expired: true,
	error: false
};

export const FacebookUserTokenContext = createContext();

export function FacebookReducer(state, action) {
	switch (action.type) {
		case 'update':
			return {
				...state,
				...action.payload
			};
		case 'reset':
			return initialState;
		default:
			throw new Error();
	}
}

const FacebookUserTokenProvider = ({ children }) => {
	const [state, dispatch] = useReducer(FacebookReducer, initialState);
	const [{ workspace, isInitialized: isConfigInitialized }] = useConfigContext();
	const [getClientApi] = useApiLookup();
	const { userStorage, setUserStorage, isInitialized: isUserStorageInitialized } = useUserStorageContext();

	const checkNextDate = () => {
		const nextCheckDate = userStorage.facebook?.nextCheckDate;
		if (
			isNullOrUndefined(nextCheckDate) ||
			(!isNullOrUndefined(nextCheckDate) && (nextCheckDate <= Date.now() || new Date(nextCheckDate).getTime() <= Date.now()))
		) {
			setUserStorage((prev) => set({ ...prev }, 'facebook.skipFacebookAuth', false));
			handleCheckUserToken();
		}
	};

	const contextValue = useMemo(() => {
		return { state, dispatch, checkNextDate };
	}, [state, dispatch, checkNextDate]);

	const handleStatusChange = (data) => {
		if (data.status === 'connected') {
			const {
				status,
				authResponse: { accessToken, userID, data_access_expiration_time }
			} = data;

			window.FB.api('/me', { fields: 'name' }, ({ name }) =>
				dispatch({
					type: 'update',
					payload: {
						name,
						status,
						accessToken,
						userID,
						dataAccessExpirationTime: data_access_expiration_time
					}
				})
			);
		} else {
			const skipFacebookAuth = userStorage.facebook?.skipFacebookAuth;

			if (!isNullOrUndefined(skipFacebookAuth) && skipFacebookAuth) {
				checkNextDate();
			}
		}
	};

	const setTempStorageDate = () => {
		if (!isUserStorageInitialized) {
			return;
		}

		const nextCheckDate = new Date();
		nextCheckDate.setMinutes(new Date().getMinutes() + 5);
		setUserStorage((prev) => ({
			...prev,
			facebook: { skipFacebookAuth: true, nextCheckDate: nextCheckDate.getTime() }
		}));
	};

	const checkUserToken = (facebookUserId, workspaceId) => {
		return getClientApi().then((api) =>
			api
				.post(`user-tokens-facebook/v1`, { facebookUserId, workspaceId })
				.then(({ data }) => data)
				.catch((e) => {
					throw extractError(e);
				})
		);
	};

	const updateUserToken = (payload) => {
		return getClientApi().then((api) =>
			api
				.patch(`user-tokens-facebook/v1`, payload)
				.then(({ data }) => data)
				.catch((e) => {
					throw extractError(e);
				})
		);
	};

	const createUserToken = (payload) => {
		return getClientApi().then((api) =>
			api
				.put(`user-tokens-facebook/v1`, payload)
				.then(({ data }) => data)
				.catch((e) => {
					throw extractError(e);
				})
		);
	};

	const handleCheckUserToken = async () => {
		try {
			if (isNullOrUndefined(state.userID)) {
				return;
			}

			const data = await checkUserToken(state.userID);

			if (state.status === 'connected') {
				if (!isNullOrUndefined(data)) {
					if (!data.status) {
						const updatedData = await updateUserToken({
							facebookUserId: state.userID,
							userToken: state.accessToken
						});
						dispatch({ type: 'update', payload: { expired: !updatedData.status } }); // reverse status from the DB true = active false = expired
					} else {
						dispatch({ type: 'update', payload: { expired: !data.status } }); // reverse status from the DB true = active false = expired
					}
				} else {
					// create the token in the DB
					await handleCreateUserToken();
				}
			}
		} catch (e) {
			console.error('FB002', e);
		}
	};

	const handleCreateUserToken = async () => {
		const payload = {
			facebookUserId: state.userID,
			facebookUsersName: state.name,
			userToken: state.accessToken,
			workspaceId: workspace?.workspace_id
		};

		try {
			if (state.status === 'connected') {
				const data = await createUserToken(payload);
				if (!isNullOrUndefined(data)) {
					dispatch({ type: 'update', payload: { expired: !data.status } }); // reverse status from the DB true = active false = expired
				} else {
					// create the token in the DB
					await handleCreateUserToken();
				}
			}
		} catch (e) {
			setTempStorageDate();
		}
	};

	const initialize = async (count = 0) => {
		try {
			await loadFacebook(document, facebookAppId);
			handleStatusChange(await facebookLoginStatus(true));
		} catch (e) {
			count++;
			console.error('Failed to load Facebook JS SDK');
			if (count <= 3) {
				setTimeout(async () => {
					await initialize(count);
				}, 1000);
			} else {
				setTempStorageDate();
			}
		}
	};

	useEffect(() => {
		if (isNullOrUndefined(getClientApi) || !isUserStorageInitialized) {
			return;
		}

		initialize();
	}, [getClientApi, isUserStorageInitialized]);

	useEffect(() => {
		if (isNullOrUndefined(window?.FB) || !isUserStorageInitialized) {
			return;
		}

		window.FB.Event.subscribe('auth.statusChange', handleStatusChange);
		return () => {
			window.FB.Event.unsubscribe('auth.statusChange', handleStatusChange);
		};
	}, [window?.FB, isUserStorageInitialized]);

	useEffect(() => {
		if (isNullOrUndefined(state.userID) || !isUserStorageInitialized || !isConfigInitialized) {
			return;
		}

		const load = async () => {
			await handleCheckUserToken();
		};

		load();
	}, [state.userID, workspace?.workspace_id, isUserStorageInitialized, isConfigInitialized]);

	return <FacebookUserTokenContext.Provider value={contextValue}>{children}</FacebookUserTokenContext.Provider>;
};

export default memo(FacebookUserTokenProvider);

import React, { memo } from 'react';
import List from '@mui/material/List';
import CheckableGroup from './CheckableGroup';

const GroupedList = ({
	selectedOptions,
	selectedOptionsLookup,
	groups,
	groupCheckedCountsLookup,
	dispatch,
	allowCheckableGroups = false,
	onClick,
	allowCheckboxes,
	scrollBarsRef
}) => {
	return (
		<List disablePadding>
			{groups.map((group, index) => (
				<CheckableGroup
					isLastGroup={index === groups.length - 1}
					scrollBarsRef={scrollBarsRef}
					key={group.value}
					group={group}
					count={groupCheckedCountsLookup[group.value] || 0}
					dispatch={dispatch}
					selectedOptions={selectedOptions}
					selectedOptionsLookup={selectedOptionsLookup}
					allowCheckableGroups={allowCheckableGroups}
					onClick={onClick}
					allowCheckboxes={allowCheckboxes}
				/>
			))}
		</List>
	);
};

export default memo(GroupedList);

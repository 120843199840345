import { useAuth0 } from '@auth0/auth0-react';
import ReconnectingWebSocket from 'reconnecting-websocket';
import { v4 } from 'uuid';
import React, { createContext, memo, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useMatch } from 'react-router-dom';
import { isNullOrUndefined } from '@truescope-web/utils/lib/objects';
import { webSocketEndpoint } from '@truescope-web/utils/lib/websockets';
import config from '../../../config.json';
import { environmentUrl } from '../../Constants';
import { useApiLookup } from '../ApiLookupProvider';
import { handleChunkWithGrouping, isConnected, isConnecting } from './constants';

const WebSocketContext = createContext(null);

export const useWebSocketContext = () => {
	const context = useContext(WebSocketContext);
	if (isNullOrUndefined(context)) {
		throw new Error('WebSocketContext must be used within a WebSocketProvider');
	}
	return context;
};

const isSocketOpen = (socket) => socket?.readyState === WebSocket.CONNECTING || socket?.readyState === WebSocket.OPEN;

const WebSocketProvider = ({ children }) => {
	const { isLoading, isAuthenticated, getAccessTokenSilently } = useAuth0();
	const [_, impersonate] = useApiLookup();
	const [isReady, setIsReady] = useState(false);
	const webSocket = useRef();
	const match = useMatch('/w/:workspaceId/*') || {};
	const routeWorkspaceId = parseInt(match?.params?.workspaceId, 10);
	const previousWorkspace = useRef();
	const receivedChunksByGroupRef = useRef({});
	const uniqueId = useRef(v4());

	const getAccessToken = useCallback(async () => {
		const { access_token } = await getAccessTokenSilently({
			detailedResponse: true,
			authorizationParams: {
				audience: config.api.client.audience,
				redirect_uri: `https://${environmentUrl}`
			}
		});

		return access_token;
	}, [config?.api?.client?.audience, getAccessTokenSilently]);

	const handleWebsocketEvent = useCallback((event) => {
		const data = JSON.parse(event.data);
		const message = handleChunkWithGrouping(data, receivedChunksByGroupRef);

		if (isNullOrUndefined(message)) {
			// waiting for more chunks to process
			return;
		}

		const customEvent = new CustomEvent('onmessage', {
			detail: message
		});

		window.dispatchEvent(customEvent);
	}, []);

	const connect = useCallback(
		() =>
			new Promise(async (resolve) => {
				previousWorkspace.current = routeWorkspaceId;

				console.debug('Creating new WebSocket');
				const socket = new ReconnectingWebSocket(
					`${webSocketEndpoint}?token=Bearer ${await getAccessToken()}&workspaceId=${routeWorkspaceId}&uniqueId=${
						uniqueId.current
					}`
				);
				webSocket.current = socket;

				socket.onopen = () => {
					webSocket.current.addEventListener('message', handleWebsocketEvent);
					console.debug(`WebSocket open ${uniqueId.current}`);
					setIsReady(true);
					resolve(socket);
				};

				socket.onclose = async () => {
					webSocket.current.removeEventListener('message', handleWebsocketEvent);
					console.debug(`WebSocket closed ${uniqueId.current}`);
					setIsReady(false);
				};

				socket.onerror = async (e) => {
					console.error('WebSocket error', e);
				};
			}),
		[isLoading, isAuthenticated, setIsReady, routeWorkspaceId, previousWorkspace, getAccessToken]
	);

	window.ws = webSocket.current;

	useEffect(() => {
		const initiate = async () => {
			if (isNaN(routeWorkspaceId) || isLoading || !isAuthenticated) {
				console.debug('Websocket connection halted', {
					rWsID: routeWorkspaceId,
					isLoading,
					isAuthenticated
				});

				await new Promise((resolve) => setTimeout(resolve, 1000));

				if (isSocketOpen(webSocket.current)) {
					return;
				}

				return await initiate();
			}

			return await connect();
		};

		initiate();

		return () => {
			const socket = webSocket.current;
			if (isNullOrUndefined(socket)) {
				return;
			}

			previousWorkspace.current = undefined;
			if (!isNullOrUndefined(socket?.close) && isSocketOpen(socket)) {
				socket?.close(1000, 'forced');
			}
		};
	}, [connect, routeWorkspaceId, isLoading, isAuthenticated]);

	const send = useCallback(
		async (message, includeAccessToken = false) => {
			try {
				const isPingPong = message === 'ping';

				const connectionCheck = async () => {
					if (isConnecting(webSocket)) {
						await new Promise((resolve) => setTimeout(resolve, 500));
					}

					if (!isConnected(webSocket)) {
						if (!isPingPong) {
							return await send(message, includeAccessToken);
						}
						return;
					}
				};

				await connectionCheck();
				webSocket.current.send(''); // we send "ping" an empty string here as a fix for Safari
				await connectionCheck();
				// If this message is from the pingPong return here we don't want to send it again
				if (isPingPong) {
					return;
				}

				webSocket.current.send(
					JSON.stringify({
						...message,
						accessToken: includeAccessToken ? await getAccessToken() : undefined,
						impersonate: impersonate ? '1' : undefined,
						workspaceId: routeWorkspaceId
					})
				);
			} catch (e) {
				console.error('Websocket send error', e);
				throw e;
			}
		},
		[getAccessToken, impersonate, routeWorkspaceId]
	);

	useEffect(() => {
		const pingPong = setInterval(async () => {
			if (!isSocketOpen(webSocket.current)) {
				return;
			}
			await send('ping');
		}, 30000);

		return () => {
			clearInterval(pingPong);
		};
	}, [send]);

	return <WebSocketContext.Provider value={[isReady, webSocket, send]}>{children}</WebSocketContext.Provider>;
};

export default memo(WebSocketProvider);

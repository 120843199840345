import React from 'react';
import Typography from '@truescope-web/react/lib/components/layout/Typography';

const NoItemsFound = () => {
	return (
		<div className="activity-stream-list__no-items-found">
			<div className="activity-stream-list__no-items-found__content">
				<img src={`${process.env.PUBLIC_URL}/images/empty-state/no-activity.png`} alt="No items found" />
				<Typography variant="subtitle">There's been no activity during this time period</Typography>
			</div>
		</div>
	);
};

export default NoItemsFound;

import { useAuth0 } from '@auth0/auth0-react';
import React from 'react';
import { useLocation, useMatch } from 'react-router-dom';
import Grid from '@truescope-web/react/lib/components/layout/Grid';
import ScrollView from '@truescope-web/react/lib/components/layout/ScrollView';
import Typography from '@truescope-web/react/lib/components/layout/Typography';
import { arrayIsNullOrEmpty } from '@truescope-web/utils/lib/arrays';
import { isNullOrUndefined } from '@truescope-web/utils/lib/objects';
import { stringIsNullOrEmpty } from '@truescope-web/utils/lib/strings';
import { errorCodeMapping } from './ErrorDetailsConstants';
import SuggestedLinks from './SuggestedLinks';

const ErrorDetails = ({ code, _errorMessage }) => {
	const match = useMatch('/error/:code');
	const location = useLocation();
	const { isAuthenticated, logout } = useAuth0();
	const errorCode = !stringIsNullOrEmpty(match?.params?.code) ? match.params.code : code;
	const ecMappingDetails = errorCodeMapping(errorCode, isAuthenticated, logout);

	const renderSuggestedLinks = () => {
		const { label, links } = location?.state?.suggestedLinks || {};
		if (arrayIsNullOrEmpty(links)) {
			return null;
		}

		return <SuggestedLinks label={label} links={links} />;
	};

	return (
		<ScrollView fill>
			<div className="error-state">
				<div className="error-state__center">
					<img
						className="error-state__center__logo"
						src={`${process.env.PUBLIC_URL}/images/logos/truescope-logo-no-padding.png`}
						alt="Truescope"
					/>

					<div className="error-state__center__image-box">
						{!isNullOrUndefined(ecMappingDetails.image) ? (
							<img src={ecMappingDetails.image} alt="error" />
						) : (
							<img src={`${process.env.PUBLIC_URL}/images/empty-state/compass.png`} alt="error" />
						)}
					</div>
					<Grid container spacing={4}>
						{!isNullOrUndefined(ecMappingDetails.title) && (
							<Grid item>
								<Typography variant="h2">{ecMappingDetails.title}</Typography>
							</Grid>
						)}

						{renderSuggestedLinks()}

						{!isNullOrUndefined(ecMappingDetails.details) && (
							<Grid item>
								<Typography className="error-state__center__details" variant="subtitle">
									{ecMappingDetails.details}
								</Typography>
							</Grid>
						)}
					</Grid>
				</div>
			</div>
		</ScrollView>
	);
};

export default ErrorDetails;

import moment from 'moment';
import {
	chartDefinitionIds,
	chartIntervals,
	chartMetricIds,
	chartTypeIds,
	widgetIds
} from '@truescope-web/react/lib/components/charts/enums';
import { stringIsNullOrEmpty } from '@truescope-web/utils/lib/strings';
import { deserializeDates } from '../../../../components/widgets/charts/ChartConstants';
import { createChart } from '../../../../components/widgets/charts/builder/ChartBuilderConstants';

export const reportsActivityMetricsLookup = {
	bounceCount: { value: 'bounceCount', label: 'Bounces' },
	bouncesPercentage: { value: 'bouncesPercentage', label: 'Bounce rate' },
	clickCount: { value: 'clickCount', label: 'Clicks' },
	clicksPercentage: { value: 'clicksPercentage', label: 'Click rate' },
	deliveryCount: { value: 'deliveryCount', label: 'Delivered' },
	openCount: { value: 'openCount', label: 'Opens' },
	opensPercentage: { value: 'opensPercentage', label: 'Open rate' }
};

export const reportsActivityMetricOptions = Object.values(reportsActivityMetricsLookup);

export const metricIsPercentage = (value) => value.substring(value.length - 'Percentage'.length) === 'Percentage';

export const createReportsActivityChart = (workspaceId, dashboardDataContext) =>
	createChart(
		{
			chart_name: 'Activity Overview',
			widget_id: widgetIds.timeSeries,
			chart_definition_id: chartDefinitionIds.volumeAndAudienceOverTime,
			chart_type_id: chartTypeIds.timeSeriesLine,
			chart_metric_ids: [chartMetricIds.volume],
			chart_interval_id: chartIntervals.day,
			workspace_id: workspaceId,
			disableExport: true,
			modifyAmChartConfig: (amChartConfig) => {
				amChartConfig.chartTemplate.colors.list = ['#1786d1', '#ff8745'];
				amChartConfig.chartTemplate.legend.disabled = true;
				amChartConfig.seriesTemplate.hidden = true;
				amChartConfig.seriesTemplate.bullets = [
					{
						type: 'Circle',
						radius: 3
					}
				];
				amChartConfig.chartTemplate.xAxes[0] = {
					...amChartConfig.chartTemplate.xAxes[0],
					startLocation: 0.5,
					endLocation: 0.5
				};
				amChartConfig.chartTemplate.yAxes = [
					{
						type: 'ValueAxis',
						skipEmptyPeriods: true,
						id: `percentageYAxis`,
						renderer: {
							opposite: true
						},
						cursorTooltipEnabled: false
					},
					{
						type: 'ValueAxis',
						skipEmptyPeriods: true,
						id: `countYAxis`,
						cursorTooltipEnabled: false,
						syncWithAxis: 'percentageYAxis'
					}
				];
				amChartConfig.chartTemplate.zoomOutButton = {
					disabled: true
				};
				return amChartConfig;
			},
			search_filter: {
				workspace_id: workspaceId
			},
			onBuiltAmChartConfig: (config) => {
				config.series = config.series.map((series) => {
					const metricValue = series.dataFields.valueY;
					const isPercentageMetric = metricIsPercentage(metricValue);

					return {
						...series,
						yAxis: `${isPercentageMetric ? 'percentage' : 'count'}YAxis`,
						tooltipText: isPercentageMetric ? '{name}: {valueY.formatNumber("#.0%")}' : '{name}: {valueY}'
					};
				});
			},
			onBuiltAmChart: (chart) => {
				const leftValueAxis = chart.yAxes.values.find(({ id }) => id === 'countYAxis');
				leftValueAxis.maxPrecision = 0; //ensure value label is integer

				const rightValueAxis = chart.yAxes.values.find(({ id }) => id === 'percentageYAxis');
				rightValueAxis.renderer.labels.template.adapter.add('text', (text) => {
					const value = text * 100;
					return `${value}%`;
				});
			}
		},
		dashboardDataContext
	);

export const getReportActivityData = async (getClientApi, workspaceId, dateFrom, dateTo, cancelToken) => {
	const api = await getClientApi();
	const {
		data: { data, series, message }
	} = await api.post(
		`report-activity/v1/${workspaceId}`,
		{
			dateFrom,
			dateTo,
			interval: isHourlyInterval(dateTo, dateFrom) ? 'hour' : 'day'
		},
		{ cancelToken }
	);

	if (!stringIsNullOrEmpty(message)) {
		throw new Error(message);
	}

	return {
		data: deserializeDates(data),
		series
	};
};

export const isHourlyInterval = (dateTo, dateFrom) => {
	return moment(dateTo).diff(moment(dateFrom), 'days') <= 6;
};

import { BsAward, BsFileText, BsSearch } from 'react-icons/bs';
import { BsGrid } from 'react-icons/bs';
import { settingTypeLookup } from '../../views/workspaceSettings/WorkspaceSettingsConstants';

/**
 * Lookup user settings entity type
 */
export const entityTypeLookup = {
	query: 'query',
	scope: 'scope',
	dashboard: 'dashboard',
	report: 'report-template'
};

/**
 * Lookup entity object by {string} entity_type
 */
export const entityTypeObjLookup = {
	[entityTypeLookup.query]: {
		entityIdKey: 'query_id',
		userSettingsKey: 'inboxPins',
		path: 'inbox/queries',
		menuIcon: <BsSearch />,
		workspaceSettingType: settingTypeLookup.queryPin
	},
	[entityTypeLookup.scope]: {
		entityIdKey: 'scope_id',
		userSettingsKey: 'inboxPins',
		path: 'inbox/scopes',
		menuIcon: <BsAward />,
		workspaceSettingType: settingTypeLookup.scopePin
	},
	[entityTypeLookup.dashboard]: {
		entityIdKey: 'dashboard_id',
		userSettingsKey: 'dashboardPins',
		settingType: 'dashboard-pins',
		path: 'dashboards',
		menuIcon: <BsGrid />,
		workspaceSettingType: settingTypeLookup.dashboardPin
	},
	[entityTypeLookup.report]: {
		entityIdKey: 'report_template_id',
		userSettingsKey: 'reportTemplatePins',
		settingType: 'report-template-pins',
		path: 'reports/templates',
		menuIcon: <BsFileText />,
		workspaceSettingType: settingTypeLookup.reportPin
	}
};

const disableExpansionRegexPaths = ['help', 'queries', 'settings/profile', 'settings/inbox', 'workspace/content'];

/**
 * checks whether the expand toggle should be disabled
 * @param {*} marketId
 * @param {*} url
 * @returns
 */
export const shouldDisableExpand = (url) => {
	return new RegExp(`/w/\\d+/(${disableExpansionRegexPaths.join('|')})`, 'gi').test(url);
};

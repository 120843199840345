import React, { useCallback } from 'react';
import { useEffect } from 'react';
import { mediaItemListLoadingState } from '@truescope-web/react/lib/components/widgets/MediaItemList';
import useCancelToken, { wasTokenCancelled } from '@truescope-web/react/lib/hooks/useCancelToken';
import { extractError } from '../../../../components/Api';
import { useConfigContext } from '../../../../components/Config/ConfigProvider';
import { useApiLookup } from '../../../../components/providers/ApiLookupProvider';
import { useReportsActivityContext } from '../ReportsActivityProvider';
import ActivityStreamProvider, { useActivityStreamContext } from './ActivityStreamProvider';
import List from './List';
import { getActivityStream } from './constants';
import { appendItems, setLoadingState, updateState } from './reducer';

const pageSize = 10;

const SearchPanel = ({ isOpen }) => {
	const [resetToken] = useCancelToken();
	const [getClientApi] = useApiLookup();
	const [{ dateFrom, dateTo }] = useReportsActivityContext();
	const [state, dispatch] = useActivityStreamContext();
	const [{ workspace }] = useConfigContext();

	const handleSearch = useCallback(async () => {
		const token = resetToken();
		try {
			dispatch(setLoadingState(mediaItemListLoadingState.loadingAllItems));
			const { items, totalCount } = await getActivityStream(
				getClientApi,
				token,
				workspace.workspace_id,
				dateFrom,
				dateTo,
				state.searchString,
				state.activityTypes,
				0,
				pageSize
			);
			dispatch(
				updateState({
					items,
					totalCount,
					offset: 0,
					loadingState: mediaItemListLoadingState.notLoading
				})
			);
		} catch (e) {
			if (wasTokenCancelled(token)) {
				return;
			}
			console.error(`failed to search activity stream - ${extractError(e)}`);
			dispatch(updateState({ loadingState: mediaItemListLoadingState.notLoading }));
		}
	}, [dispatch, state.searchString, state.activityTypes, dateFrom, dateTo, resetToken]);

	const handleLoadMore = async () => {
		const token = resetToken();
		try {
			dispatch(setLoadingState(mediaItemListLoadingState.loadingMoreItems));
			const nextOffset = state.offset + pageSize;
			const { items } = await getActivityStream(
				getClientApi,
				token,
				workspace.workspace_id,
				dateFrom,
				dateTo,
				state.searchString,
				state.activityTypes,
				nextOffset,
				pageSize
			);
			dispatch(
				appendItems({
					items,
					offset: nextOffset,
					loadingState: mediaItemListLoadingState.notLoading
				})
			);
		} catch (e) {
			if (wasTokenCancelled(token)) {
				return;
			}
			console.error(`failed to load more activity stream - ${extractError(e)}`);
			dispatch(updateState({ loadingState: mediaItemListLoadingState.notLoading }));
		}
	};

	useEffect(() => {
		if (isOpen) {
			handleSearch();
		}
	}, [isOpen, handleSearch]);

	return (
		<div className="activity-stream-search-panel">
			<List onLoadMore={handleLoadMore} onSearch={handleSearch} />
		</div>
	);
};

const SearchPanelWrapper = ({ isOpen }) => (
	<ActivityStreamProvider>
		<SearchPanel isOpen={isOpen} />
	</ActivityStreamProvider>
);

export default SearchPanelWrapper;

import React, { createContext, useContext, useReducer } from 'react';
import { isNullOrUndefined } from '@truescope-web/utils/lib/objects';
import activityStreamReducer, { getInitialState } from './reducer';

const ActivityStreamContext = createContext(null);

export const useActivityStreamContext = () => {
	const context = useContext(ActivityStreamContext);
	if (isNullOrUndefined(context)) {
		throw new Error('useActivityStreamContext must be used within a ActivityStreamProvider');
	}
	return context;
};

const ActivityStreamProvider = ({ children }) => {
	const [state, dispatch] = useReducer(activityStreamReducer, getInitialState());

	return <ActivityStreamContext.Provider value={[state, dispatch]}>{children}</ActivityStreamContext.Provider>;
};

export default ActivityStreamProvider;

import React from 'react';
import Skeleton from '@mui/material/Skeleton';
import ListItemRow from './ListItemRow';

const SkeletonListItem = () => {
	return (
		<div className="activity-stream-list-item">
			<div className="activity-stream-list-item__header">
				<div>
					<Skeleton variant="rectangular" height={18} width={220} />
				</div>
				<Skeleton variant="circular" height={25} width={25} />
			</div>
			<div className="activity-stream-list-item__body">
				<ListItemRow
					label={<Skeleton variant="rectangular" height={18} width={50} />}
					value={<Skeleton variant="rectangular" height={18} width={30} />}
				/>
				<ListItemRow
					label={<Skeleton variant="rectangular" height={18} width={40} />}
					value={<Skeleton variant="rectangular" height={18} width={40} />}
				/>
				<ListItemRow
					label={<Skeleton variant="rectangular" height={18} width={30} />}
					value={<Skeleton variant="rectangular" height={18} width={150} />}
				/>
			</div>
		</div>
	);
};

export default SkeletonListItem;

import React, { useEffect, useState } from 'react';
import Select from '@truescope-web/react/lib/components/form/Select';
import TextField from '@truescope-web/react/lib/components/form/TextField';
import ValidationPlaceholder from '@truescope-web/react/lib/components/form/ValidationPlaceholder';
import Grid from '@truescope-web/react/lib/components/layout/Grid';
import { getDefaultInterval, htmlDateToIsoString, isoStringToHtmlFormat } from '@truescope-web/react/lib/components/widgets/DatePicker';
import { dateOptions, dateOptionsLookup } from '@truescope-web/utils/lib/dates';
import { prefillDates } from '@truescope-web/utils/lib/dates';
import { updateDashboardBuilderState } from './DashboardBuilderReducer';

const DateStep = ({ state, dispatch, validationState, validate }) => {
	const [lastDateOption, setLastDateOption] = useState();
	const [htmlDateFrom, setHtmlDateFrom] = useState(null);
	const [htmlDateTo, setHtmlDateTo] = useState(null);

	useEffect(() => {
		setLastDateOption(state.defaultFilters?.publication_date_option);
	}, []);

	const handleDateFromChange = (e) => {
		handleApplyCustomDate(e.target.value, htmlDateTo);
	};

	const handleDateToChange = (e) => {
		handleApplyCustomDate(htmlDateFrom, e.target.value);
	};

	const handleApplyCustomDate = (from, to) => {
		setHtmlDateFrom(from);
		setHtmlDateTo(to);

		const updatedDefaultFilters = {
			...state.defaultFilters,
			publication_date_from: htmlDateToIsoString(from, true),
			publication_date_to: htmlDateToIsoString(to, false),
			publication_date_option: dateOptionsLookup.custom
		};

		dispatch(
			updateDashboardBuilderState({
				defaultFilters: updatedDefaultFilters,
				defaultInterval: getDefaultInterval(
					updatedDefaultFilters.publication_date_option,
					updatedDefaultFilters.publication_date_from,
					updatedDefaultFilters.publication_date_to
				)
			})
		);
		validate();
	};

	const handleDateOptionChange = (_e, value) => {
		const { defaultFilters } = state;

		//if this is switching to a custom date range, use the previous date option to prefill the dates
		const publicationDateOption = value === dateOptionsLookup.custom ? lastDateOption : value;
		const { dateFrom, dateTo } = prefillDates(
			publicationDateOption,
			defaultFilters.publication_date_from,
			defaultFilters.publication_date_to,
			null,
			false
		);

		const chartIntervalId = getDefaultInterval(publicationDateOption, dateFrom, dateTo);

		setLastDateOption(value);
		setHtmlDateFrom(isoStringToHtmlFormat(dateFrom));
		setHtmlDateTo(isoStringToHtmlFormat(dateTo));
		dispatch(
			updateDashboardBuilderState({
				defaultFilters: {
					...defaultFilters,
					publication_date_from: dateFrom,
					publication_date_to: dateTo,
					publication_date_option: value
				},
				defaultInterval: chartIntervalId
			})
		);
		validate();
	};

	return (
		<Grid container spacing={3}>
			<Grid item>
				<Grid container spacing={2}>
					<Grid item sm={6}>
						<Select
							options={dateOptions}
							onChange={handleDateOptionChange}
							value={state.defaultFilters?.publication_date_option}
							menuPosition="fixed"
							label="Select the default date range to apply to charts on this Dashboard"
							fullWidth={false}
						/>
						<ValidationPlaceholder validationResult={validationState?.results?.publication_date_option} />
					</Grid>
				</Grid>
			</Grid>
			{state.defaultFilters?.publication_date_option === dateOptionsLookup.custom && (
				<Grid item>
					<Grid container spacing={2}>
						<Grid item sm={6}>
							<TextField label="From" labelAbove type="date" onChange={handleDateFromChange} value={htmlDateFrom} />
							<ValidationPlaceholder validationResult={validationState?.results?.publication_date_from} />
						</Grid>
						<Grid item sm={6}>
							<TextField label="To" labelAbove type="date" onChange={handleDateToChange} value={htmlDateTo} />
							<ValidationPlaceholder validationResult={validationState?.results?.publication_date_to} />
						</Grid>
					</Grid>
				</Grid>
			)}
		</Grid>
	);
};

export default DateStep;

import * as Sentry from '@sentry/react';
import React, { memo } from 'react';
import { isLocalhost } from '@truescope-web/utils/lib/constants';
import { isNullOrUndefined } from '@truescope-web/utils/lib/objects';
import ErrorDetails from './ErrorDetails';

const hasChunkFailedStorageItem = () => {
	const chunkFailedStorage = window.localStorage.getItem('chunkFailed');
	if (isNullOrUndefined(chunkFailedStorage)) {
		return false;
	}

	const expiry = JSON.parse(chunkFailedStorage);
	const isExpired = new Date().getTime() > expiry;

	if (isExpired) {
		localStorage.removeItem('chunkFailed');
		return false;
	}

	return true;
};

class ErrorBoundary extends React.Component {
	constructor(props) {
		super(props);
		this.state = { hasError: false, errorMessage: undefined };
	}

	static getDerivedStateFromError(error) {
		return { hasError: true, errorMessage: error.message };
	}

	componentDidCatch(error) {
		const chunkFailedMessage = /Loading chunk [\d]+ failed/;
		if (error?.message && chunkFailedMessage.test(error.message)) {
			if (!hasChunkFailedStorageItem()) {
				localStorage.setItem('chunkFailed', new Date().getTime() + 10000);
				window.location.reload();
			}
			return;
		}

		if (!isLocalhost()) {
			Sentry.captureException(error);
		}
	}
	render() {
		const { hasError, errorMessage } = this.state;
		const { component: Component, children } = this.props;

		if (!hasError) {
			return children;
		} else {
			return (
				<div className="app">
					<div className="view">
						{!isNullOrUndefined(Component) ? (
							<Component error={hasError} />
						) : (
							<ErrorDetails {...{ errorMessage }} code="unknown" />
						)}
					</div>
				</div>
			);
		}
	}
}

export default memo(ErrorBoundary);

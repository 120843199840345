import { useAuth0 } from '@auth0/auth0-react';
import React, { createContext, memo, useCallback, useContext, useEffect, useState } from 'react';
import { chartMapTypeIds } from '@truescope-web/react/lib/components/charts/enums';
import { createLookup } from '@truescope-web/utils/lib/arrays';
import { isNullOrUndefined } from '@truescope-web/utils/lib/objects';
import { stringIsNullOrEmpty } from '@truescope-web/utils/lib/strings';
import { extractError } from '../Api';
import { useApiLookup } from './ApiLookupProvider';

export const DashboardDataContext = createContext(null);

/**
 * alternative way to use the dashboard data, without composing the component
 * @returns
 */
export const useDashboardDataContext = () => {
	const context = useContext(DashboardDataContext);
	if (isNullOrUndefined(context)) {
		throw new Error('DashboardDataContext must be used within a DashboardDataProvider');
	}
	return context;
};

/**
 * retrieves the latest dashboard data
 */
const getDashboardData = (getClientApi) => {
	return getClientApi().then((api) =>
		api.get('/dashboards/v1/data').then(({ data }) => {
			if (!stringIsNullOrEmpty(data.message)) {
				throw new Error(data.message);
			}

			const { chart_types, chart_map_types, chart_map_definitions, chart_metrics, chart_intervals, chart_comparisons, widgets } =
				data.dashboard_data;

			return {
				chartTypes: chart_types.map((x) => ({ label: x.name, value: x.chart_type_id, metadata: x })),
				chartTypesLookup: createLookup(chart_types, 'chart_type_id'),
				chartMetrics: chart_metrics.map((x) => ({ label: x.name, value: x.chart_metric_id })),
				chartMetricsLookup: createLookup(chart_metrics, 'chart_metric_id'),
				chartIntervals: chart_intervals.map((x) => ({ label: x.name, value: x.chart_interval_id })),
				chartIntervalsLookup: createLookup(chart_intervals, 'chart_interval_id'),
				chartComparisons: chart_comparisons.map((x) => ({ label: x.name, value: x.chart_comparison_id })),
				chartComparisonsLookup: createLookup(chart_comparisons, 'chart_comparison_id'),
				chartDefinitionsLookup: widgets.reduce((acc, cur) => {
					acc[cur.widget_id] = {};
					(cur.chart_definitions || []).forEach((chartDefinition) => {
						acc[cur.widget_id][chartDefinition.chart_definition_id] = chartDefinition;
					});
					return acc;
				}, {}),
				widgets,
				widgetsLookup: createLookup(widgets, 'widget_id'),

				chartMapTypes: chart_map_types.map((x) => ({ label: x.name, value: x.chart_map_type_id, metadata: x })),
				chartMapTypesLookup: createLookup(chart_map_types, 'chart_map_type_id'),
				chartMapDefinitions: chart_map_definitions,
				chartMapDefinitionsLookup: chart_map_types.reduce((acc, cur) => {
					acc[cur.chart_map_type_id] = {};

					chart_map_definitions.forEach((chartMapDefinition) => {
						if (!chartMapDefinition.chart_map_type_ids.includes(cur.chart_map_type_id)) {
							return acc;
						}

						const definition = { ...chartMapDefinition };

						if (cur.chart_map_type_id === chartMapTypeIds.segmentByRegionState) {
							definition.name = `${chartMapDefinition.name} Regions`;
						}

						acc[cur.chart_map_type_id][chartMapDefinition.chart_map_definition_id] = definition;
					});

					return acc;
				}, {})
			};
		})
	);
};

/**
 * contains the states for the dashboard data
 * @param {*} param0
 */
const DashboardDataProvider = ({ children }) => {
	const [getClientApi] = useApiLookup();
	const { isAuthenticated, isLoading } = useAuth0();
	const [contextData, setContextData] = useState({ isInitialized: false });

	const handleInitialize = useCallback(async () => {
		if (isLoading || !isAuthenticated) {
			setContextData({ isInitialized: false });
			return;
		}
		try {
			const result = await getDashboardData(getClientApi);
			setContextData({
				...result,
				isInitialized: true
			});
		} catch (e) {
			console.error(`failed to get dashboard data ${extractError(e)}`, e);
			setContextData({ isInitialized: false });
		}
	}, [isLoading, isAuthenticated, getClientApi]);

	useEffect(() => {
		handleInitialize();
	}, [handleInitialize]);

	return <DashboardDataContext.Provider value={contextData}>{children}</DashboardDataContext.Provider>;
};

export default memo(DashboardDataProvider);

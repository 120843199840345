import React from 'react';
import LinearProgress from '@mui/material/LinearProgress';

const PartialDataGridLoader = () => (
	<div className="partial-data-grid-loader">
		<LinearProgress />
	</div>
);

export default PartialDataGridLoader;

import React, { memo } from 'react';
import { useNavigate } from 'react-router-dom';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@truescope-web/react/lib/components/layout/Typography';
import Avatar from '@truescope-web/react/lib/components/widgets/Avatar';
import { getHostedIconUrl } from '@truescope-web/utils/lib/mediaItem';

const TrendingNowItem = memo(({ item, country }) => {
	const sourceFavIcon = getHostedIconUrl(item.source);
	const navigate = useNavigate();

	return (
		<div className="trending-now__item">
			<div className="trending-now__item__image">
				<img alt={item.title} src={item.image} />
			</div>
			<div className="trending-now__item__content">
				<div className="trending-now__item__content__title">
					<Tooltip arrow title="View in Trends">
						<Typography
							variant="h5"
							onClick={() =>
								navigate(`search`, {
									state: {
										query: item.title
											.replaceAll(/[_=+~`!@#$%^&*()[];:<>\/\|{}]/gi, '')
											.replaceAll(/_|-|\b vs \b/gi, ' '),
										country
									}
								})
							}
						>
							{item.title}
						</Typography>
					</Tooltip>
				</div>

				<div className="trending-now__item__content__subtitle">
					<div className="trending-now__item__content__subtitle__source" onClick={() => window.open(item.source, 'noopener')}>
						<Avatar src={sourceFavIcon} /> <Typography variant="subtitle">{item.article.source}</Typography>
					</div>
					<div className="trending-now__item__content__subtitle__title" onClick={() => window.open(item.source, 'noopener')}>
						{item.article.title}
					</div>
				</div>
			</div>
			<div className="trending-now__item__count">
				<div className="trending-now__item__count__traffic">
					<Typography variant="h3">{item.traffic}</Typography>
				</div>
				<Typography variant="subtitle">searches</Typography>
			</div>
		</div>
	);
});

export default TrendingNowItem;

import { isMapChart } from '@truescope-web/react/lib/components/charts/ChartContainerConstants';
import { chartIntervals } from '@truescope-web/react/lib/components/charts/enums';
import { validationResults } from '@truescope-web/react/lib/utils/validation';
import { arrayIsNullOrEmpty } from '@truescope-web/utils/lib/arrays';
import { isNullOrUndefined } from '@truescope-web/utils/lib/objects';
import { sortOptionsLookup } from '@truescope-web/utils/lib/search';

/**
 * validation rules for filling out the display view
 */
export const chartDisplaySelectorValidationRules = {
	chart_definition_id: ({ chart }) => {
		return isNullOrUndefined(chart?.chart_definition_id)
			? validationResults.required(`Please select a chart definition`)
			: validationResults.ok();
	},
	chart_type_id: ({ chart, chartDefinition }) => {
		return !arrayIsNullOrEmpty(chartDefinition?.chart_type_ids) && isNullOrUndefined(chart?.chart_type_id)
			? validationResults.required(`Please select a chart type`)
			: validationResults.ok();
	},
	chart_map_definition_id: ({ chart }) => {
		if (!isMapChart(chart)) {
			return validationResults.ok();
		}
		return isNullOrUndefined(chart?.chart_map_definition_id)
			? validationResults.required(`Please select a map definition`)
			: validationResults.ok();
	},
	chart_metric_ids: ({ chart, chartDefinition }) => {
		return !arrayIsNullOrEmpty(chartDefinition?.chart_metric_ids) && arrayIsNullOrEmpty(chart?.chart_metric_ids)
			? validationResults.required(`Please select a metric`)
			: validationResults.ok();
	},
	nested_charts: ({ chart, chartDefinition, rebuildOnly }) => {
		const nestedCharts = chart?.nested_charts || [];
		const { max_nested_charts, min_nested_charts } = chartDefinition || {};

		if (!isNullOrUndefined(min_nested_charts)) {
			if (rebuildOnly) {
				if (nestedCharts.length < 1) {
					return validationResults.required(`Please select at least 1 chart`);
				}
			} else if (nestedCharts.length < min_nested_charts) {
				return validationResults.required(`Please select at least ${min_nested_charts} charts`);
			}
		}

		if (!isNullOrUndefined(max_nested_charts) && nestedCharts.length > max_nested_charts) {
			const overflow = nestedCharts.length - max_nested_charts;
			return validationResults.required(`Please remove ${overflow} chart${overflow === 1 ? 's' : ''}`);
		}

		return validationResults.ok();
	}
};

/**
 * adds the default date options to a chart
 * @param {*} chart chart object
 * @param {*} chartDefinition chart definition
 * @param {*} userStorageDateOptions user storage date settings
 * @returns shallow copy chart object with user date settings
 */
export const addDateOptionsToChart = (chart, chartDefinition, defaultChartSettings) => {
	const updatedChart = {
		...chart,
		...defaultChartSettings,
		search_filter: {
			...chart.search_filter,
			...defaultChartSettings.search_filter
		}
	};

	return setChartIntervalOptions(updatedChart, chartDefinition, defaultChartSettings);
};

/**
 * sets the interval on the charts
 * @param {*} chart
 * @param {*} chartDefinition
 * @param {*} param2
 * @returns
 */
export const setChartIntervalOptions = (chart, chartDefinition, { chart_interval_id }) => ({
	...chart,
	chart_interval_id: !arrayIsNullOrEmpty(chartDefinition?.chart_interval_ids) ? chart_interval_id || chartIntervals.day : null
});

export const mediaItemListChartSortOptions = [
	{
		label: 'Publication Date',
		value: sortOptionsLookup.publicationDate,
		chartTitle: 'Most recent'
	},
	{
		label: 'Potential Impressions',
		value: sortOptionsLookup.audience,
		chartTitle: 'Most potential impressions'
	},
	{
		label: 'Social engagements',
		value: sortOptionsLookup.totalEngagements,
		chartTitle: 'Most engaged items'
	},
	{
		label: 'Relevance',
		value: sortOptionsLookup.score,
		chartTitle: 'Most relevant items'
	}
];

export const getMediaItemListDisplayOptions = ({ showSentiment, showAudience, showSocialEngagement, showImage, showAve }, canShowAve) => {
	const options = [
		{
			label: 'Sentiment',
			field: 'showSentiment',
			checked: showSentiment || false
		},
		{
			label: 'Impressions',
			field: 'showAudience',
			checked: showAudience || false
		},
		{
			label: 'Engagement',
			field: 'showSocialEngagement',
			checked: showSocialEngagement || false
		},
		{
			label: 'Image',
			field: 'showImage',
			checked: showImage || false
		}
	];

	if (canShowAve) {
		options.push({
			label: 'Show AVE',
			field: 'showAve',
			checked: showAve || false
		});
	}

	return options;
};

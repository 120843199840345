import lodashDebounce from 'lodash.debounce';
import React, { useCallback, useRef, useState } from 'react';
import TextField from '@truescope-web/react/lib/components/form/TextField';
import { stringIsNullOrEmpty } from '@truescope-web/utils/lib/strings';
import { filterOptions } from './constants';
import { updateState } from './reducer';

const OptionFilter = ({ options, selectedOptionsLookup, dispatch, placeholder, ...props }) => {
	const [filterText, setFilterText] = useState(null);

	const debounceFilterOptions = useRef(
		lodashDebounce(
			(filterText, options, selectedOptionsLookup, dispatch) =>
				dispatch(
					updateState({
						filteredOptions: filterOptions(filterText, options, selectedOptionsLookup),
						showAllOptions: stringIsNullOrEmpty(filterText)
					})
				),
			300
		)
	).current;

	const handleChange = useCallback(
		(_e, value) => {
			setFilterText(value);
			debounceFilterOptions(value, options, selectedOptionsLookup, dispatch);
		},
		[options, selectedOptionsLookup, setFilterText]
	);

	return (
		<TextField
			autoFocus
			placeholder={placeholder}
			value={filterText || ''}
			onChange={handleChange}
			onKeyDown={(e) => {
				e.stopPropagation();
			}}
			{...props}
		/>
	);
};

export default OptionFilter;

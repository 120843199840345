import { arrayIsNullOrEmpty } from '@truescope-web/utils/lib/arrays';
import { isNullOrUndefined } from '@truescope-web/utils/lib/objects';
import { filterDefinitions, filterGroupSortOrder } from '../FilterComponents';

/**
 * creates a list of selectable filter options
 * @param {['query_ids', 'media_types', 'authors']} filterFields filter options that should appear in the list
 * @param {[{field: 'query_ids', removable: true}, {field: 'media_types', removable: true}]} preselectedFilters filter options that should ALWAYS be on by default
 * @param {[{ field: 'media_types', path: 'media_types' }, { field: 'query_ids', path: 'nested[0].filter.query_ids'} ]} filterPaths filters paths that show which field/filter is active
 * @param {number} workspaceMarketId some filters are specific to a market, so this trims them down. use the current workspace's market id help filter whats available
 *  @returns options
 */
export const createFilterOptions = (filterFields, preselectedFilters, filterPaths, workspaceMarketId) => {
	const ignore = {};

	if (isNullOrUndefined(preselectedFilters)) {
		preselectedFilters = [];
	}
	const preselectedFilterFields = preselectedFilters.map(({ field }) => field);

	const activeFiltersLookup = (filterPaths || []).reduce((lookup, { field }, index) => {
		const preselectedIndex = preselectedFilterFields.indexOf(field);
		lookup[field] = {
			active: true,
			//if this field is in the preselected list, we need to use it's order. OTHERWISE we can use the order is appears in the json (offset by the preselected count)
			index: preselectedIndex >= 0 ? preselectedIndex : index + preselectedFilterFields.length
		};
		return lookup;
	}, {});

	return preselectedFilterFields.concat(filterFields).reduce((options, filterField) => {
		//skip already added fields. incase someone doubles up with preselected and options
		if (ignore[filterField]) {
			return options;
		}

		const filterDefinition = filterDefinitions[filterField];
		if (isNullOrUndefined(filterDefinition)) {
			console.error(`no filter definition for '${filterField}'`);
		} else if (arrayIsNullOrEmpty(filterDefinition.marketIds) || filterDefinition.marketIds.includes(workspaceMarketId)) {
			//only allow the filter if there's no market restriction OR workspace market is within the restriction list
			const { label, value } = filterDefinition.option;
			const preselected = preselectedFilterFields.includes(value);

			ignore[filterField] = true;
			const active = preselected || activeFiltersLookup[value]?.active;
			const removable = !preselected || preselectedFilters.some(({ field, removable }) => field === value && removable);

			options.push({
				label,
				value,
				active,
				preselected,
				displayOrder: active ? activeFiltersLookup[value]?.index : -1,
				removable
			});
		}
		return options;
	}, []);
};

/**
 * Takes filter options into a group and sorts them
 * @param {*} filterOptions desired filter options
 * @returns filter option groups
 */
export const createFilterOptionGroups = (filterOptions) => {
	const groupsLookup = filterOptions.reduce((groupLookup, filterOption) => {
		const groupName = filterDefinitions[filterOption.value]?.group?.name || 'Ungrouped';
		if (isNullOrUndefined(groupLookup[groupName])) {
			groupLookup[groupName] = [];
		}

		groupLookup[groupName].push(filterOption);

		return groupLookup;
	}, {});

	return Object.entries(groupsLookup)
		.map(([groupName, options]) => {
			return {
				label: groupName,
				options: options.sort((a, b) => {
					const aOrder = filterDefinitions[a.value]?.group?.sort_order || 0;
					const bOrder = filterDefinitions[b.value]?.group?.sort_order || 0;
					return aOrder - bOrder;
				})
			};
		})
		.sort((a, b) => filterGroupSortOrder[a.label] - filterGroupSortOrder[b.label]);
};

/**
 * given a collection of filter options, calculates the next viable display order
 * @param {array} filterOptions list of filter options
 * returns
 */
export const getNextDisplayOrder = (filterOptions, isPreselected = false) => {
	const displayOrdersLookup = (filterOptions || []).reduce(
		(lookup, filterOption) => {
			if (filterOption.preselected && filterOption.displayOrder > lookup.preselectedMax) {
				lookup.preselectedMax = filterOption.displayOrder;
			} else if (filterOption.active && filterOption.displayOrder > lookup.optionalMax) {
				lookup.optionalMax = filterOption.displayOrder;
			}
			return lookup;
		},
		{ preselectedMax: -1, optionalMax: -1 }
	);

	if (isPreselected) {
		return displayOrdersLookup.preselectedMax + 1;
	}

	//we add 100 to the display order calculation for optional filters. this ensures that preselected filters always appear first
	return displayOrdersLookup.optionalMax < 0 ? 100 : displayOrdersLookup.optionalMax + 1;
};

import { useAuth0 } from '@auth0/auth0-react';
import * as Sentry from '@sentry/react';
import { useEffect, useState } from 'react';
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router-dom';
import { getEnvironmentName, isLocalhost, isProduction } from '@truescope-web/utils/lib/constants';
import { isNullOrUndefined } from '@truescope-web/utils/lib/objects';
import { useTestSettings } from '../../Constants';

/**
 * hooks sentry.io
 */
const useSentryIo = () => {
	const { user } = useAuth0();
	const [isInitialized, setIsInitialized] = useState(false);
	const testSettings = useTestSettings();

	useEffect(() => {
		if (testSettings?.disableSentry) {
			console.log('SentryIo is disabled for testing');
			return;
		}

		if (isLocalhost()) {
			console.debug('SentryIo is disabled for localhost');
			return;
		}

		const isDev = getEnvironmentName() === 'dev2';

		//intialize SentryIo
		console.debug(`Initializing SentryIo ${isDev ? '(experimental exception capture enabled)' : ''}`);

		const sentryIoOptions = {
			dsn: 'https://1368012bf12a4fa18b462abd990a4d1a@o4505224650096640.ingest.sentry.io/4505224761901056',
			environment: getEnvironmentName(),
			integrations: [
				Sentry.reactRouterV6BrowserTracingIntegration({
					useEffect,
					useLocation,
					useNavigationType,
					createRoutesFromChildren,
					matchRoutes
				}),
				new Sentry.replayIntegration({
					maskAllText: false,
					maskAllInputs: false,
					blockAllMedia: false,
					networkDetailAllowUrls: [window.location.origin, /^https:\/\/.*truescope.(com|cloud)\/.*/],
					//todo: MY-3274 - UNDO this and restore the code above. it'll flood our quota. although sentry did promise to reimburse us
					//update: disable this by 01/08/24
					_experiments: isDev
						? {
								captureExceptions: true
						  }
						: undefined
				})
			],
			tracesSampleRate: 1.0,
			replaysSessionSampleRate: 1.0,
			replaysOnErrorSampleRate: 1.0
		};

		if (isProduction()) {
			sentryIoOptions.release = process.env.REACT_APP_VERSION;
		}

		Sentry.init(sentryIoOptions);

		setIsInitialized(true);
	}, [setIsInitialized, testSettings?.disableSentry]);

	useEffect(() => {
		if (!isInitialized) {
			return;
		}
		if (isNullOrUndefined(user)) {
			console.debug('SentryIo active in anonymous mode');
			return;
		}
		//to avoid having anonymous sessions, we can identify the user from auth0.
		//important: the auth0 user is different to the impersonated user
		Sentry.setUser({
			id: user.email,
			name: user.name,
			email: user.email,
			nickname: user.nickname,
			auth_id: user.sub
		});
		console.debug(`SentryIo user '${user.name}' identified`);
	}, [user, isInitialized]);
};

export default useSentryIo;

import React, { memo, useCallback, useRef } from 'react';
import FormHelperText from '@mui/material/FormHelperText';
import ScrollView from '@truescope-web/react/lib/components/layout/ScrollView';
import { arrayIsNullOrEmpty } from '@truescope-web/utils/lib/arrays';
import FlatList from './FlatList';
import GroupedList from './GroupedList';

const OptionPicker = ({
	showAllOptions,
	options,
	filteredOptions,
	selectedOptions,
	selectedOptionsLookup,
	groups,
	groupCheckedCountsLookup,
	dispatch,
	allowCheckableGroups,
	allowCheckboxes,
	onClick
}) => {
	const scrollBarsRef = useRef();
	const renderList = useCallback(() => {
		if (showAllOptions) {
			//if there's no groups, there's no reason to show them grouped
			if (groups.length <= 1) {
				return (
					<FlatList
						options={options}
						selectedOptionsLookup={selectedOptionsLookup}
						dispatch={dispatch}
						allowCheckboxes={allowCheckboxes}
						onClick={onClick}
					/>
				);
			}

			return (
				<GroupedList
					selectedOptions={selectedOptions}
					selectedOptionsLookup={selectedOptionsLookup}
					groups={groups}
					groupCheckedCountsLookup={groupCheckedCountsLookup}
					dispatch={dispatch}
					allowCheckableGroups={allowCheckableGroups}
					allowCheckboxes={allowCheckboxes}
					onClick={onClick}
					scrollBarsRef={scrollBarsRef}
				/>
			);
		}

		if (arrayIsNullOrEmpty(filteredOptions)) {
			return (
				<div className="option-picker__no-results">
					<FormHelperText>Nothing found</FormHelperText>
				</div>
			);
		}

		return (
			<FlatList
				options={filteredOptions}
				selectedOptionsLookup={selectedOptionsLookup}
				dispatch={dispatch}
				allowCheckboxes={allowCheckboxes}
				onClick={onClick}
			/>
		);
	}, [
		dispatch,
		showAllOptions,
		allowCheckableGroups,
		options,
		filteredOptions,
		selectedOptions,
		selectedOptionsLookup,
		groups,
		groupCheckedCountsLookup
	]);

	return (
		<ScrollView fill className="option-picker" autoHeight autoHeightMax="450px" ref={scrollBarsRef}>
			{renderList()}
		</ScrollView>
	);
};

export default memo(OptionPicker);

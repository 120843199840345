import { useAuth0 } from '@auth0/auth0-react';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { isNullOrUndefined } from '@truescope-web/utils/lib/objects';
import { useConfigContext } from '../../Config/ConfigProvider';
import { captureIdleEvent, captureNavigationEvent } from '../../api/workspaceUserActivitiesApi';
import { useApiLookup } from '../../providers/ApiLookupProvider';

//how often to capture user idle event in milliseconds
const idleTimerMs = 3600000;

/**
 * hooks sentry.io
 */
const useActivityCapture = () => {
	const [{ workspace, user }] = useConfigContext();
	const location = useLocation();
	const [getClientApi] = useApiLookup();
	const { isLoading, isAuthenticated } = useAuth0();

	useEffect(() => {
		const isImpersonating = !isNullOrUndefined(user?.impersonated_by);

		if (
			isLoading ||
			!isAuthenticated ||
			isNullOrUndefined(location?.pathname) ||
			isNullOrUndefined(workspace?.workspace_id) ||
			isImpersonating
		) {
			//if app hasnt loaded yet OR the user is impersonating, dont track any events
			return;
		}

		//capture the navigation to this page
		captureNavigationEvent(getClientApi, workspace.workspace_id, location.pathname);

		//while on this page, wait every idleTimerMs and capture an idle event
		const timeoutId = setInterval(() => {
			captureIdleEvent(getClientApi, workspace.workspace_id, location.pathname);
		}, idleTimerMs);

		return () => {
			clearInterval(timeoutId);
		};
	}, [isLoading, isAuthenticated, location?.pathname, getClientApi, workspace?.workspace_id, user?.impersonated_by]);
};

export default useActivityCapture;

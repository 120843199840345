import { stringIsNullOrEmpty } from '@truescope-web/utils/lib/strings';
import { extractError } from '../../components/Api';

export const getManageSubscriptionsInfo = async (getClientApi, guid) => {
	try {
		const api = await getClientApi();
		const { data } = await api.get(`subscriptions/v1/${guid}`);
		if (!stringIsNullOrEmpty(data.message)) {
			throw new Error(`Failed to get manage subscriptions info: ${data.message}`);
		}
		return data;
	} catch (e) {
		throw new Error(extractError(e));
	}
};
export const updateSubscription = async (
	getClientApi,
	recipient_id,
	subscription_reference,
	report_template_id,
	shouldSubscribe,
	type = 'report'
) => {
	try {
		const api = await getClientApi();
		const { data } = await api.patch(`subscriptions/v1/${recipient_id}`, {
			subscription_reference,
			report_template_id,
			action: shouldSubscribe ? 'subscribe' : 'unsubscribe',
			type
		});
		if (!stringIsNullOrEmpty(data.message) || !data.success) {
			throw new Error(`Failed to update subscriptions: ${data.message || 'unsuccessful'}`);
		}
		return data;
	} catch (e) {
		throw new Error(extractError(e));
	}
};
export const subscriptionTypesLookup = {
	report: 'report',
	alert: 'alert'
};

import React, { Fragment } from 'react';
import Link from '@mui/material/Link';
import Button from '@truescope-web/react/lib/components/form/Button';
import Grid from '@truescope-web/react/lib/components/layout/Grid';
import Typography from '@truescope-web/react/lib/components/layout/Typography';
import { isNullOrUndefined } from '@truescope-web/utils/lib/objects';
import { stringIsNullOrEmpty } from '@truescope-web/utils/lib/strings';
import { getEmptyStateImage } from './EmptyStateCallToActionConstants';

const EmptyStateCallToAction = ({
	iconVariant = '',
	mainMessage,
	subMessage,
	helpMessage,
	helpLinkText,
	helpLinkOnClick,
	actionButtonText,
	actionButtonOnClick,
	enableImageShadow = true
}) => {
	const iconImage = getEmptyStateImage(iconVariant);

	return (
		<div className="empty-state">
			<div className="empty-state__center">
				{!isNullOrUndefined(iconImage) && (
					<div className={`empty-state__image-box ${enableImageShadow ? 'empty-state__image-box--with-shadow' : ''}`}>
						<img src={iconImage} alt={iconVariant} />
					</div>
				)}
				<Grid container spacing={3}>
					{!stringIsNullOrEmpty(mainMessage) && (
						<Grid item>
							<Typography className="empty-state__main-message">{mainMessage}</Typography>
						</Grid>
					)}
					{!stringIsNullOrEmpty(subMessage) && (
						<Grid item>
							<Typography className="empty-state__sub-message">{subMessage}</Typography>
						</Grid>
					)}
					{!stringIsNullOrEmpty(helpMessage) && (
						<Grid item>
							<Typography variant="subtitle">
								{helpMessage}
								{!isNullOrUndefined(helpLinkText) && (
									<Fragment>
										{' '}
										<Link className="empty-state__help-text-link" onClick={helpLinkOnClick}>
											{helpLinkText}
										</Link>
									</Fragment>
								)}
								.
							</Typography>
						</Grid>
					)}
					{!isNullOrUndefined(actionButtonOnClick) && (
						<Grid item>
							<Button onClick={actionButtonOnClick} aria-label={actionButtonText}>
								{actionButtonText}
							</Button>
						</Grid>
					)}
				</Grid>
			</div>
		</div>
	);
};

export default EmptyStateCallToAction;

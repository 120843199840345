import { isNullOrUndefined } from '@truescope-web/utils/lib/objects';

const createOptionGroups = (options) => {
	return Object.values(
		(options || []).reduce((groupLookup, option) => {
			if (isNullOrUndefined(groupLookup[option.groupValue])) {
				groupLookup[option.groupValue] = {
					label: option.groupLabel,
					value: option.groupValue,
					isExpandedByDefault: option.isExpandedByDefault,
					options: []
				};
			}

			groupLookup[option.groupValue].options.push(option);

			return groupLookup;
		}, {})
	).sort((a, b) => (a.label || 'zzz').localeCompare(b.label || 'zzz'));
};

const getGroupCheckedCountsLookup = (options) => {
	return (options || []).reduce((groupCheckedCountLookup, { groupValue }) => {
		if (isNullOrUndefined(groupCheckedCountLookup[groupValue])) {
			groupCheckedCountLookup[groupValue] = 1;
		} else {
			groupCheckedCountLookup[groupValue]++;
		}
		return groupCheckedCountLookup;
	}, {});
};

const getSelectedOptionsLookup = (selectedOptions) =>
	(selectedOptions || []).reduce((acc, option) => {
		acc[option.value] = option;
		return acc;
	}, {});

const incrementGroupCheckedCount = (lookup, groupValue) => {
	if (isNullOrUndefined(lookup[groupValue])) {
		return {
			...lookup,
			[groupValue]: 1
		};
	}
	lookup[groupValue]++;
	return lookup;
};

const decrementGroupCheckedCount = (lookup, groupValue) => {
	if (isNullOrUndefined(lookup[groupValue])) {
		return {
			...lookup,
			[groupValue]: 0
		};
	}
	lookup[groupValue]--;
	return lookup;
};

export const getInitialState = ({ selectedOptions, options, ...defaultProps }) => ({
	options: options || [],
	filteredOptions: [],
	selectedOptions: !isNullOrUndefined(selectedOptions) ? [...selectedOptions] : [],
	selectedOptionsLookup: getSelectedOptionsLookup(selectedOptions),
	showAllOptions: true,
	groups: createOptionGroups(options),
	groupCheckedCountsLookup: getGroupCheckedCountsLookup(selectedOptions),
	...defaultProps
});

export const updateState = (data) => {
	return {
		type: 'update',
		payload: data
	};
};

export const resetState = () => {
	return {
		type: 'reset'
	};
};

export const reinitialize = (options, selectedOptions) => {
	return {
		type: 'update',
		payload: getInitialState({ options, selectedOptions })
	};
};

export const setSelectedOptions = (selectedOptions) => {
	return {
		type: 'setSelectedOptions',
		payload: {
			selectedOptions
		}
	};
};

export const addSelectedOption = (option) => {
	return {
		type: 'addSelectedOption',
		payload: {
			option
		}
	};
};

export const removeSelectedOption = (option) => {
	return {
		type: 'removeSelectedOption',
		payload: {
			option
		}
	};
};

const removeOptionFromSelectedOptions = (option, selectedOptions) => {
	const updatedSelectedOptions = [...selectedOptions];
	const index = updatedSelectedOptions.findIndex(({ value }) => value === option.value);
	updatedSelectedOptions.splice(index, 1);
	return updatedSelectedOptions;
};

const reducer = (state, action) => {
	switch (action?.type) {
		case 'update':
			return {
				...state,
				...action.payload
			};
		case 'setSelectedOptions': {
			const selectedOptionsLookup = getSelectedOptionsLookup(action.payload.selectedOptions);
			return {
				...state,
				selectedOptions: Object.values(selectedOptionsLookup),
				selectedOptionsLookup,
				groupCheckedCountsLookup: getGroupCheckedCountsLookup(action.payload.selectedOptions)
			};
		}
		case 'removeSelectedOption': {
			const { option } = action.payload;
			const selectedOptionsLookup = { ...state.selectedOptionsLookup };
			delete selectedOptionsLookup[option.value];
			const selectedOptions = removeOptionFromSelectedOptions(option, state.selectedOptions);
			return {
				...state,
				selectedOptions,
				groupCheckedCountsLookup: decrementGroupCheckedCount(state.groupCheckedCountsLookup, option.groupValue),
				selectedOptionsLookup
			};
		}
		case 'addSelectedOption': {
			const { option } = action.payload;
			delete option.isIndeterminate;

			//indeterminate options need to be removed from the list, since they have isIndeterminate on them
			const selectedOptions = state.selectedOptionsLookup[option.value]?.isIndeterminate
				? removeOptionFromSelectedOptions(option, state.selectedOptions)
				: [...state.selectedOptions];

			selectedOptions.push(option);

			return {
				...state,
				selectedOptions,
				groupCheckedCountsLookup: incrementGroupCheckedCount(state.groupCheckedCountsLookup, option.groupValue),
				selectedOptionsLookup: {
					...state.selectedOptionsLookup,
					[option.value]: option
				}
			};
		}
		case 'reset':
			return getInitialState({
				...state,
				filteredOptions: [],
				selectedOptions: [],
				selectedOptionsLookup: {},
				groupCheckedCountsLookup: {},
				showAllOptions: true
			});
		default:
			throw new Error(`unknown reducer action '${action?.type}'`);
	}
};

export default reducer;

import React from 'react';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Popover from '@mui/material/Popover';
import { textMatchingOptions } from '../FilterConstants';

const ConditionPicker = ({ value, isOpen, onClose, onChange, anchorEl }) => {
	return (
		<Popover
			classes={{ paper: 'popover' }}
			anchorEl={anchorEl}
			open={isOpen}
			onClose={onClose}
			anchorOrigin={{
				vertical: 'bottom',
				horizontal: 'right'
			}}
			transformOrigin={{
				vertical: 'top',
				horizontal: 'right'
			}}
		>
			<List disablePadding dense>
				{textMatchingOptions.map((option) => (
					<ListItemButton key={option.value} onClick={() => onChange(option.value)} selected={option.value === value}>
						<ListItemText primary={option.label} />
					</ListItemButton>
				))}
			</List>
		</Popover>
	);
};

export default ConditionPicker;

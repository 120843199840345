import React from 'react';
import LinearProgress from '@mui/material/LinearProgress';

const FullDataGridLoader = () => (
	<div className="full-data-grid-loader">
		<LinearProgress />
	</div>
);

export default FullDataGridLoader;

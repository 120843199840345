import React from 'react';
import Skeleton from '@mui/material/Skeleton';
import ChipTextField from '@truescope-web/react/lib/components/form/ChipTextField';
import { cleanString } from '@truescope-web/utils/lib/strings';

export const SimpleFilter = ({
	isLoading,
	label,
	labelAbove,
	onChange,
	value,
	placeholder,
	tooltip = undefined,
	multiline = true,
	autoFocus,
	classes = {},
	disabled
}) => {
	const handleChipTextFieldChange = (e, newChips) => {
		onChange(
			e,
			newChips.map((chip) => cleanString(chip.replace(/"/g, '')))
		);
	};

	return (
		<div className="simple-filter">
			{isLoading ? (
				<div className="simple-filter--loading">
					<Skeleton height="30px" width="100%" variant="text" />
					<Skeleton height="200px" width="100%" variant="rectangular" />
				</div>
			) : (
				<ChipTextField
					onChange={handleChipTextFieldChange}
					delimiters={[';']}
					value={value}
					multiline={multiline}
					placeholder={placeholder}
					labelAbove={labelAbove}
					label={label}
					tooltip={tooltip}
					autoFocus={autoFocus}
					classes={classes}
					disabled={disabled}
				/>
			)}
		</div>
	);
};

export default SimpleFilter;

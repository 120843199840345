import { wasTokenCancelled } from '@truescope-web/react/lib/hooks/useCancelToken';
import { extractError } from '../../../../components/Api';

export const getGoogleTopTrends = async (getClientApi, countryCode, cancelToken) => {
	return getClientApi().then((api) =>
		api
			.get(`trends/v2/top/${countryCode}`, { cancelToken })
			.then(({ data }) => data)
			.catch((e) => {
				if (wasTokenCancelled(cancelToken)) {
					throw new Error('cancelled');
				}

				throw new Error(extractError(e));
			})
	);
};

import ISO6391 from 'iso-639-1';
import cloneDeep from 'lodash.clonedeep';
import get from 'lodash.get';
import set from 'lodash.set';
import unset from 'lodash.unset';
import moment from 'moment';
import { v4 } from 'uuid';
import { arrayIsNullOrEmpty } from '@truescope-web/utils/lib/arrays';
import { prefillDates } from '@truescope-web/utils/lib/dates';
import { requiresStartOfWeek } from '@truescope-web/utils/lib/dates';
import { isNullOrUndefined, updateFormState } from '@truescope-web/utils/lib/objects';
import { hasActiveFilters } from '@truescope-web/utils/lib/search';
import { audienceSumModeLookup } from '@truescope-web/utils/lib/search';
import { cleanString, stringIsNullOrEmpty } from '@truescope-web/utils/lib/strings';
import { createExactMatchString, extractWordsAndQuotedWords, getTimeZoneOffset, sanitizeTextFilter } from '../Constants';

/**
 * extracts the text queries' values and wraps them into a proper string query for the server
 * @param {*} param0
 */
export const formatTextQueries = ({ value, condition, fields, exact }, searchFieldVariants = {}) => {
	value = Array.isArray(value) ? value.map((text) => sanitizeTextFilter(text)) : sanitizeTextFilter(value);

	const delimiter = condition === 'must' ? ' AND ' : ' OR ';

	if (arrayIsNullOrEmpty(fields)) {
		fields = fullTextSearchFields;
	}

	return {
		fields: flattenSearchFieldVariants(fields, searchFieldVariants),
		//remap fields should to must, because should seems to do an open ended search
		condition: condition === 'should' ? 'must' : condition,
		value: exact ? createExactMatchString(value, delimiter) : extractWordsAndQuotedWords(value).join(delimiter)
	};
};

/**
 * flattens the fields to a list
 */
const flattenSearchFieldVariants = (fields, searchFieldVariants) => {
	return isNullOrUndefined(searchFieldVariants)
		? fields
		: fields.reduce((acc, cur) => {
				if (!arrayIsNullOrEmpty(searchFieldVariants[cur])) {
					acc.push(...searchFieldVariants[cur]);
				} else {
					acc.push(cur);
				}
				return acc;
		  }, []);
};

/**
 * there's an issue right now (05/02/2024) where advanced query strings are
 * appearing wrong (RMB-669). this should fix them. we should probably remove
 * this code by 2025
 * @param {string} advancedQueryString
 * @returns {string} fixed advanced query string
 */
const fixAdvancedQueryString = (advancedQueryString = '') => {
	return advancedQueryString.split('||').reduce((queryString, markup) => {
		if (markup.startsWith('{')) {
			try {
				const object = JSON.parse(markup);
				if (typeof object.value === 'string') {
					object.value = {
						label: object.value,
						value: object.value
					};
				}
				markup = `||${JSON.stringify(object)}||`;
			} catch (e) {
				console.error(`failed to parse and fix markup '${markup}', it will be skipped`);
			}
		}
		return queryString + markup;
	}, '');
};

/**
 * deserializes filters from the server
 */
export const deserializeFilters = ({ not, or, sort, desc, nested, do_not_aggregate, ...and } = {}, workspace) => {
	const filters = {
		sort,
		desc,
		do_not_aggregate,
		...deserializeFilterParts(and, workspace)
	};

	if (!arrayIsNullOrEmpty(nested)) {
		filters.nested = nested.map((nestedFilter) => deserializeFilterParts(nestedFilter, workspace));
	}

	if (!isNullOrUndefined(not)) {
		filters.not = deserializeFilterParts(not, workspace);
	}

	if (!isNullOrUndefined(or)) {
		filters.or = deserializeFilterParts(or, workspace);
	}

	if (!stringIsNullOrEmpty(filters.advanced_query?.value)) {
		filters.advanced_query.value = fixAdvancedQueryString(filters.advanced_query.value);
	}

	return filters;
};

/**
 * removes non existing filters from a query
 * @param {*} filters - obtains original location of string to be manipulated
 * @param {*} workspace - contains all scope ids on a filter
 */
export const deserializeAdvancedQueryValue = (advancedQueryValue, workspace) => {
	advancedQueryValue = cleanString(advancedQueryValue);
	//trim out any scopes that dont exist any more
	const scopeFiltersRegex = /\|\|{"field":"scope_ids","value":(\d*)}\|\|/gim;
	const existingQueryScopeIds = [...advancedQueryValue.matchAll(scopeFiltersRegex)];
	existingQueryScopeIds.forEach(([jsonBlock, scopeId]) => {
		if (isNullOrUndefined(workspace.scopesLookup[scopeId])) {
			advancedQueryValue = advancedQueryValue.replace(jsonBlock, '');
		}
	});

	return advancedQueryValue;
};

/**
 * deserializes the terms of a simple query
 * @param {string[]} terms simple query terms
 * @returns string[]
 */
export const deserializeSimpleQueryTerms = (terms) => terms.map((term) => cleanString(term).replace(/"/g, ''));

/**
 * for the filter picker, we 'tick' selected fields if the field itself is present on the filters object
 * this function will strip off any fields that are undefined, so that no filter picker fields are selected
 * @param {object} filters
 * @returns {object} filters
 */
const trimEmptyFilterProperties = (filters) =>
	Object.entries(filters).reduce((cleanFilters, [key, value]) => {
		if (value !== undefined) {
			cleanFilters[key] = value;
		}
		return cleanFilters;
	}, {});

/**
 * deserializes parts of a filter
 * @param {*} filters
 */
const deserializeFilterParts = (filters, workspace) => {
	if (!arrayIsNullOrEmpty(filters.media_types)) {
		filters.media_types = filters.media_types.map((mediaType) =>
			mediaType.hasOwnProperty('value') ? mediaType : { label: mediaType, value: mediaType }
		);
	}

	if (!isNullOrUndefined(filters.simple_query)) {
		if (!arrayIsNullOrEmpty(filters.simple_query.and)) {
			filters.simple_query.and = deserializeSimpleQueryTerms(filters.simple_query.and);
		}

		if (!arrayIsNullOrEmpty(filters.simple_query.or)) {
			filters.simple_query.or = deserializeSimpleQueryTerms(filters.simple_query.or);
		}

		if (!arrayIsNullOrEmpty(filters.simple_query.not)) {
			filters.simple_query.not = deserializeSimpleQueryTerms(filters.simple_query.not);
		}
	}

	if (!isNullOrUndefined(filters.advanced_query)) {
		filters.advanced_query.value = deserializeAdvancedQueryValue(filters.advanced_query.value, workspace);
	}

	if (!arrayIsNullOrEmpty(filters.query_ids)) {
		//queries can be removed. in this case, we want to remove them from the filters
		const queriesLookup = workspace.queriesLookup;
		filters.query_ids = filters.query_ids.filter((queryId) => !isNullOrUndefined(queriesLookup[queryId]));
	}

	if (!arrayIsNullOrEmpty(filters.scope_ids)) {
		//scopes can also be removed. in this case, we want to remove them from the filters
		filters.scope_ids = filters.scope_ids.filter((scopeId) => !isNullOrUndefined(workspace.scopesLookup[scopeId]));
	}

	//revert to option/label
	if (!arrayIsNullOrEmpty(filters.languages) && filters.languages.some((language) => typeof language === 'string')) {
		filters.languages = filters.languages.map((languageCode) => ({
			value: languageCode.toUpperCase(),
			label: ISO6391.getName(languageCode.toLowerCase())
		}));
	}

	if (!isNullOrUndefined(filters.publication_date_option) || !isNullOrUndefined(filters.item_date_option)) {
		//use local user's time zone
		filters.time_zone = getTimeZoneOffset();
	} else {
		delete filters.time_zone;
	}

	//todo: remove this later. it shouldn't have been on the filters
	delete filters.time_zone_name;

	//this will get stamped automatically when serialized
	delete filters.content_syndication;
	delete filters.audienceSumMode;

	return trimEmptyFilterProperties(filters);
};

/**
 * cleans filters that have excessive filters
 * @param {object} filters
 * @param {object} workspace
 * @returns
 */
export const cleanFilters = (filters, workspace) => {
	if (filters.scope_ids?.length >= workspace.scopes.length) {
		//scopes are not stamped onto query filters, because if new ones are added or old ones deleted, the percolator will dynamically updated the query
		delete filters.scope_ids;
	}

	if (filters.countries?.length >= workspace.countries.length) {
		delete filters.countries;
	}

	if (filters.media_types?.length >= workspace.mediaTypes.length) {
		delete filters.media_types;
	}

	if (filters.languages?.length >= workspace.languages.length) {
		delete filters.languages;
	}
	return filters;
};

/**
 * flattens search filters into a schema for the backend
 * @param {object} filters filters to serialize
 * @param {object} workspace workspace
 * @param {object} appCache app cache
 * @param {object} user local user
 * @param {boolean} preserveDateFilters preserves publication date settings on filters. if false, they get stripped off
 * preserveDateFilters=true
 * - saving a query
 * - saving a report
 * preserveDateFilters=false
 * - viewing the inbox
 * - saving a dashboard/charts
 * - previewing a query that you're building
 * @returns {object} serialized filters
 */
export const serializeFilters = (
	{
		not,
		or,
		sort = 'publication_date',
		desc = true,
		nested,
		do_not_aggregate,
		limit,
		offset,
		query_from_timestamp,
		query_to_timestamp,
		scoreBy = undefined,
		...and
	},
	workspace,
	appCache,
	user,
	preserveDateFilters
) => {
	const workspaceId = workspace.workspace_id;

	const serialized = {
		...serializeFilterParts(and, appCache.searchFieldVariants, user.user_id),
		workspace_id: workspaceId,
		sort,
		desc,
		content_syndication: workspace.content_syndication || 1, //todo: remove this
		audienceSumMode: workspace.audience_sum_mode || audienceSumModeLookup.channel,
		do_not_aggregate,
		limit,
		offset,
		query_from_timestamp,
		query_to_timestamp,
		scoreBy
	};

	if (hasActiveFilters(not)) {
		serialized.not = serializeFilterParts(not, appCache.searchFieldVariants, user.user_id);
	}

	if (hasActiveFilters(or)) {
		serialized.or = {
			...serializeFilterParts(or, appCache.searchFieldVariants, user.user_id),
			workspace_id: workspaceId
		};
	}

	serialized.nested = serializeNestedFilters(nested, appCache.searchFieldVariants, user.user_id);

	return cleanFilters(preserveDateFilters ? serialized : removePublicationDateFilters(serialized), workspace);
};

const serializeNestedFilters = (nestedFilters, searchFieldVariants, userId) => {
	if (arrayIsNullOrEmpty(nestedFilters)) {
		return undefined;
	}

	return nestedFilters.reduce((acc, { operator, nested, filter }) => {
		const serialized = {
			operator,
			filter: !isNullOrUndefined(filter) ? serializeFilterParts(filter, searchFieldVariants, userId) : undefined,
			nested: serializeNestedFilters(nested, searchFieldVariants, userId)
		};

		if (!isNullOrUndefined(serialized.filter) || !isNullOrUndefined(serialized.nested)) {
			acc.push(serialized);
		}

		return acc;
	}, []);
};

const serializeFilterParts = (
	{
		scope_ids,
		global_scope_ids,
		query_ids,
		media_types,
		locations,
		people,
		companies,
		countries,
		regions,
		cities,
		sources,
		source_dmarank,
		source_sections,
		source_networks,
		authors,
		media_owners,
		categories,
		junk,
		read,
		publication_date_option,
		item_date_option,
		entity_sentiments,
		sentiments,
		audience,
		social_engagement,
		languages,
		full_text_contains,
		full_text_exact,
		title_contains,
		title_exact,
		title_summary_contains,
		title_summary_exact,
		item_ids,
		text_queries,
		custom_queries,
		hashtags,
		key_phrases,
		emojis,
		advanced_query,
		simple_query,
		global,
		workspace_id,
		time_zone,
		majorMentions,
		//2023/06/14, i don't like adding new fields to the filters. it's already pretty bloated.
		//this has been added because title_contains, etc are arrays. but we need singular objects
		//ideally we should to refactor the inbox and remove it's dependance on title_contains, pushing them into nested filters
		title,
		title_summary,
		title_summary_body,
		...other
	},
	searchFieldVariants,
	userId
) => {
	const { dateTo: publication_date_to, dateFrom: publication_date_from } = prefillDates(
		publication_date_option,
		other.publication_date_from,
		other.publication_date_to,
		null,
		false
	);

	const { dateTo: item_date_to, dateFrom: item_date_from } = prefillDates(
		item_date_option,
		other.item_date_from,
		other.item_date_to,
		null,
		false
	);

	const hasDates = !isNullOrUndefined(publication_date_option) || !isNullOrUndefined(item_date_option);

	const search = {
		scope_ids: !arrayIsNullOrEmpty(scope_ids) ? scope_ids : undefined,
		global_scope_ids: !arrayIsNullOrEmpty(global_scope_ids) ? global_scope_ids : undefined,
		query_ids: !arrayIsNullOrEmpty(query_ids) ? query_ids : undefined,
		publication_date_option,
		publication_date_from: !isNullOrUndefined(publication_date_from) ? publication_date_from : undefined,
		publication_date_to: !isNullOrUndefined(publication_date_to) ? publication_date_to : undefined,
		item_date_option,
		item_date_from: !isNullOrUndefined(item_date_from) ? item_date_from : undefined,
		item_date_to: !isNullOrUndefined(item_date_to) ? item_date_to : undefined,
		media_types: !arrayIsNullOrEmpty(media_types)
			? media_types.map((mediaTypeOption) => (mediaTypeOption.hasOwnProperty('value') ? mediaTypeOption.value : mediaTypeOption))
			: undefined,
		locations,
		people,
		companies,
		countries,
		regions,
		cities,
		sources,
		source_dmarank,
		source_sections,
		source_networks,
		authors,
		media_owners,
		languages,
		categories: !isNullOrUndefined(categories) ? categories : undefined,
		junk: isNullOrUndefined(junk) ? undefined : Boolean(junk),
		entity_sentiments,
		sentiments,
		item_ids,
		custom_queries,
		hashtags,
		key_phrases,
		emojis: !arrayIsNullOrEmpty(emojis) ? emojis : undefined,
		audience,
		social_engagement,
		global,
		workspace_id,
		time_zone: hasDates ? time_zone || getTimeZoneOffset() : undefined,
		title,
		title_summary,
		title_summary_body,
		majorMentions
	};

	if (!isNullOrUndefined(read)) {
		search.read = Boolean(read);
		search.user_id = userId;
	}

	const allTextQueries = [
		full_text_contains,
		title_contains,
		title_summary_contains,
		full_text_exact,
		title_exact,
		title_summary_exact
	].reduce((formattedTextQueries, textQuery) => {
		if (!isNullOrUndefined(textQuery)) {
			const formattedTextQuery = formatTextQueries(textQuery, searchFieldVariants);
			if (!stringIsNullOrEmpty(formattedTextQuery.value)) {
				formattedTextQueries.push(formattedTextQuery);
			}
		}
		return formattedTextQueries;
	}, []);

	if (!arrayIsNullOrEmpty(allTextQueries)) {
		search.text_queries = allTextQueries;
	}

	if (!isNullOrUndefined(advanced_query)) {
		search.advanced_query = {
			value: cleanString(advanced_query?.value?.trim()),
			search_field_mapping: {
				title: flattenSearchFieldVariants(titleSearchFields, searchFieldVariants),
				title_summary: flattenSearchFieldVariants(titleAndSummarySearchFields, searchFieldVariants),
				full_text: flattenSearchFieldVariants(fullTextSearchFields, searchFieldVariants)
			}
		};
	}

	if (!isNullOrUndefined(simple_query)) {
		search.simple_query = {
			and: simple_query.and?.map((x) => cleanString(x.replace(/"/g, '').trim())),
			or: simple_query.or?.map((x) => cleanString(x.replace(/"/g, '').trim())),
			not: simple_query.not?.map((x) => cleanString(x.replace(/"/g, '').trim())),
			fields: flattenSearchFieldVariants(fullTextSearchFields, searchFieldVariants)
		};
	}

	if (requiresStartOfWeek(publication_date_option) || requiresStartOfWeek(item_date_option)) {
		search.start_of_week = moment().startOf('week').format('dddd').toLowerCase();
	}

	return search;
};

/**
 * creates a text filter
 * @param {*} value
 * @param {*} textMatchingOption
 * @param {*} fields fullTextSearchFields, titleSearchFields, titleAndSummarySearchFields
 */
export const createTextFilter = (value, textMatchingOption, fields, exact = false) => {
	return {
		fields: fields || fullTextSearchFields,
		condition: textMatchingOption || textMatchingOptionsLookup.must,
		value,
		exact,
		key: v4()
	};
};

/**
 * creates a raw filter
 */
export const createCustomQueryFilter = (value, fields) => {
	return {
		query_string: {
			query: value,
			fields: fields || fullTextSearchFields,
			key: v4()
		}
	};
};

/**
 * splits a string like 'this OR that AND then' into ['this', 'that', 'then']
 */
export const splitKeywordsByOperators = (text) => {
	return (text || '')
		.replace(/"/g, '')
		.split(/\bOR|AND\b/gi)
		.map((x) => x.trim());
};

/**
 * clears the value of a filter
 * @param {{ query_ids: [], ... }} filters filters
 * @param {'query_ids'} filterField path for filter to clear
 * @returns cloned, cleared filters
 */
export const clearFilter = (filters, filterField) => {
	const newFilters = { ...filters };
	unset(newFilters, filterField);

	if (filterField.match(/^simple_query\./g)) {
		if (
			isNullOrUndefined(newFilters.simple_query?.and) &&
			isNullOrUndefined(newFilters.simple_query?.or) &&
			isNullOrUndefined(newFilters.simple_query?.not)
		) {
			delete newFilters.simple_query;
		}
	} else if (filterField.match(/^audience\./g)) {
		if (isNullOrUndefined(newFilters.audience.from) && isNullOrUndefined(newFilters.audience.to)) {
			delete newFilters.audience;
		}
	} else if (filterField.match(/^social_engagement\./g)) {
		if (isNullOrUndefined(newFilters.social_engagement.from) && isNullOrUndefined(newFilters.social_engagement.to)) {
			delete newFilters.social_engagement;
		}
	}

	return newFilters;
};

/**
 * checks to see if a filter has a value
 * @param {[1,2,3] or { from: 0, to: 5}, etc} value filter value
 * @returns
 */
export const filterHasValue = (value) => {
	if (isNullOrUndefined(value)) {
		return false;
	}

	if (Array.isArray(value)) {
		return !arrayIsNullOrEmpty(value);
	}

	if (typeof value !== 'object') {
		//handles primative types like number, boolean
		return true;
	}

	return ['from', 'to', 'and', 'or', 'not', 'value'].some((property) => {
		if (!value.hasOwnProperty(property)) {
			return false;
		}
		const nestedValue = value[property];
		return Array.isArray(nestedValue) ? !arrayIsNullOrEmpty(nestedValue) : !isNullOrUndefined(nestedValue);
	});
};

/**
 * trims empty values off a filter
 * @param {*} filters filter schema object
 */
export const trimEmptyFilters = (filters) => {
	return hasActiveFilters(filters)
		? Object.entries(cloneDeep(filters)).reduce(
				(trimmedFilters, [filterField, value]) =>
					filterHasValue(value)
						? updateFormState(trimmedFilters, {
								[filterField]: cloneDeep(value)
						  })
						: trimmedFilters,
				{}
		  )
		: null;
};

/**
 * given a list of fields, it applies the variants
 * @param {*} fields
 * @param {*} appCache
 */
export const getFieldVariants = (fields, searchFieldVariants) => {
	return fields.reduce((acc, cur) => {
		if (!arrayIsNullOrEmpty(searchFieldVariants[cur])) {
			acc.push(...searchFieldVariants[cur]);
		}
		return acc;
	}, []);
};

/**
 * full text search fields
 */
export const fullTextSearchFields = ['title', 'body', 'summary'];

/**
 * title search fields
 */
export const titleSearchFields = ['title'];

/**
 * title and summary search fields
 */
export const titleAndSummarySearchFields = ['title', 'summary'];

/**
 * lookup for filtering text
 */
export const textMatchingOptionsLookup = {
	must: 'must',
	should: 'should',
	mustNot: 'must_not'
};

/**
 * converts a condition like must/should/must_not into a nice label
 * @param {*} condition
 * @returns string label
 */
export const getConditionLabel = (condition) => {
	switch (condition) {
		case textMatchingOptionsLookup.must:
			return 'contains all of';
		case textMatchingOptionsLookup.should:
			return 'contains any of';
		case textMatchingOptionsLookup.mustNot:
			return 'excludes';
		default:
			throw new Error(`unknown condition '${condition}'`);
	}
};

/**
 * options available for filtering text
 */
export const textMatchingOptions = [
	{ label: 'contains all of', value: textMatchingOptionsLookup.must },
	{ label: 'contains some of', value: textMatchingOptionsLookup.should },
	{ label: 'excludes', value: textMatchingOptionsLookup.mustNot }
];

/**
 * lookup for filtering numbers
 */
export const numberMatchingOptionsLookup = {
	gte: 0,
	lte: 1,
	unset: 2
};

/**
 * options available for filtering numbers
 */
export const numberMatchingOptions = [
	{ label: 'at least', value: numberMatchingOptionsLookup.gte },
	{ label: 'at most', value: numberMatchingOptionsLookup.lte },
	{ label: 'none', value: numberMatchingOptionsLookup.unset }
];

/**
 * removes publication date fields from filters
 * @param {object} filters
 * @returns date-free filters
 */
export const removePublicationDateFilters = (filters) => {
	if (isNullOrUndefined(filters)) {
		return filters;
	}
	const { publication_date_option, publication_date_from, publication_date_to, ...strippedFilters } = filters;
	return strippedFilters;
};

/**
 * checks to see if there are no query terms
 * @param {boolean} advanced
 * @param {object} filters
 * @returns boolean true if there are no query terms
 */
export const hasNoKeywordFilters = (filters) => stringIsNullOrEmpty(filters?.advanced_query?.value);

/**
 * given a filters object, this extracts a list of [{ path, field }] filter paths.
 * the path is the fully quality path (nested[3].filter.media_types)
 * the field is the filter field (media_types)
 * @param {object} filters filters
 * @param {string} prefix nesting prefix for recursion
 * @returns [{ path, field }]
 */
export const getFilterPaths = (filters, prefix = '') => {
	if (isNullOrUndefined(filters)) {
		return [];
	}

	return Object.keys(filters).reduce((filterPaths, field) => {
		if (field === 'simple_query') {
			const simpleQueryKeys = Object.keys(filters[field])
				.filter((simpleQueryField) => simpleQueryField !== 'fields')
				.map((simpleQueryField) => ({
					path: `${prefix}${field}.${simpleQueryField}`,
					field: `${field}.${simpleQueryField}`
				}));
			filterPaths.push(...simpleQueryKeys);
		} else if (field === 'nested') {
			const nestedPaths = filters.nested.reduce((nestedPaths, nestedFilter, nestedFilterIndex) => {
				nestedPaths.push(...getFilterPaths(nestedFilter.filter, `nested[${nestedFilterIndex}].filter.`));
				return nestedPaths;
			}, []);
			filterPaths.push(...nestedPaths);
		} else {
			filterPaths.push({
				path: `${prefix}${field}`,
				field: field
			});
		}
		return filterPaths;
	}, []);
};

/**
 * removes all mentions of a field within a filter
 * @param {object} filters
 * @param {string} field
 * @returns filters without said field
 */
export const excludeFiltersByField = (filters, field) => {
	const newFilters = cloneDeep(filters);
	getFilterPaths(filters).forEach((filterPath) => {
		if (filterPath.field === field) {
			unset(filters, filterPath.path);
		}
	});
	return newFilters;
};

/**
 * only allows certain fields within a filter
 * @param {object} filters
 * @param {array} fields
 * @returns filters without said field
 */
export const includeFiltersByFields = (filters, fields) => {
	return getFilterPaths(filters).reduce((inclusionFilters, filterPath) => {
		if (fields.includes(filterPath.field)) {
			const value = get(filters, filterPath.path);
			set(inclusionFilters, filterPath.path, value);
		}
		return inclusionFilters;
	}, {});
};

import React, { useMemo } from 'react';
import LinearProgress from '@mui/material/LinearProgress';
import { verticalAlignment } from '@truescope-web/react/lib/components/Constants';
import { stringIsNullOrEmpty } from '@truescope-web/utils/lib/strings';

const PartialLoadingIndicator = ({ loading = true, className, verticalAlignment: va = verticalAlignment.bottom }) => {
	const classNames = useMemo(() => {
		let newClassNames = ['linear-progress'];

		if (!stringIsNullOrEmpty(className)) {
			newClassNames.push(className);
		}

		//set the alignment class
		if (va === verticalAlignment.bottom) {
			newClassNames.push('linear-progress--bottom');
		} else {
			if (va === verticalAlignment.top) {
				newClassNames.push('linear-progress--top');
			} else {
				console.error(`${va} is not a supported type of vertical alignment for linear progress loader`);
			}
		}

		return newClassNames.join(' ');
	}, [className, va]);

	if (loading) {
		return <LinearProgress variant="indeterminate" className={classNames} />;
	}

	return <div className={classNames}></div>;
};

export default PartialLoadingIndicator;

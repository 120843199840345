import React from 'react';
import Link from '@mui/material/Link';
import { stringIsNullOrEmpty } from '@truescope-web/utils/lib/strings';

const refresh = () => {
	if (!window.location.pathname.includes('/error/')) {
		window.location.reload();
	}
	window.location.assign(`${window.location.origin}/inbox`);
};

export const errorCodes = {
	500: 'Something went wrong and we’ve lost our way. An internal server error occurred.',
	401: 'Something went wrong and we’ve lost our way. Please ensure that you are signed into the application.',
	403: 'Something went wrong and we’ve lost our way. You do not have the appropriate permissions to access this resource.',
	ERC100: "Something went wrong and we’ve lost our way. Your account isn't assigned a workspace.",
	ERC101: 'Something went wrong and we’ve lost our way. The workspace allocated to your account does not exist or you cannot access it.',
	ERC102: 'Something went wrong and we’ve lost our way. The workspace allocated to your account is experiencing issues.',
	ERC103: "Something went wrong and we’ve lost our way. The workspace you're trying to access either doesn't exist or you do not have permissions to view it.",
	ERC104: 'Something went wrong and we’ve lost our way. The application could not load. Please check your internet connection and try again',
	default: "Something went wrong and you've landed on this page. Probably not the page you were looking for 🤷"
};

export const errorCodeMapping = (errorCode, isAuthenticated, logout) => {
	const refreshLoginText = (
		<>
			<Link onClick={refresh}>refresh the page</Link> or{' '}
			{isAuthenticated && typeof logout === 'function' && <Link onClick={() => logout()}>log-in again.</Link>}
		</>
	);

	const generalDetails = <>If the problem persists please contact your account team. In the meantime you can try to {refreshLoginText}</>;

	switch (true) {
		case !stringIsNullOrEmpty(errorCodes[errorCode]):
			return {
				title: errorCodes[errorCode],
				details: generalDetails
			};

		default:
			return {
				title: errorCodes['default'],
				details: <>Our engineers have been informed. In the meantime you can try to {refreshLoginText}</>,
				image: `${process.env.PUBLIC_URL}/images/empty-state/rocket-ship.png`
			};
	}
};

import React from 'react';
import Switch from '@truescope-web/react/lib/components/form/Switch';

const BooleanFilter = ({ className = '', onChange, componentLabel, value }) => {
	return (
		<div className={`boolean-filter ${className}`}>
			<Switch label={componentLabel} value={value} onChange={onChange} size="small" />
		</div>
	);
};

export default BooleanFilter;

import cloneDeep from 'lodash.clonedeep';
import { isMapChart, isMediaItemListChart } from '@truescope-web/react/lib/components/charts/ChartContainerConstants';
import { chartDefinitionIds, chartIntervals, chartMapTypeIds, chartMetricIds } from '@truescope-web/react/lib/components/charts/enums';
import { arrayIsNullOrEmpty } from '@truescope-web/utils/lib/arrays';
import { isNullOrUndefined, updateFormState } from '@truescope-web/utils/lib/objects';
import { sortOptionsLookup } from '@truescope-web/utils/lib/search';
import { stringIsNullOrEmpty } from '@truescope-web/utils/lib/strings';
import { mediaItemListChartSortOptions } from './ChartDisplaySelectorConstants';

export const chartStepsLookup = {
	compare: 'Compare',
	display: 'Display',
	data: 'Data'
};

/**
 * creates a chart from a widget
 * @param {*} widget
 * @param {*} workspace_id
 * @param {*} dashboardDataContext
 */
export const createChartFromWidget = (widget, workspace_id, dashboardDataContext) => {
	let chart = {
		id: 1,
		widget_id: widget.widget_id,
		search_filter: {
			workspace_id,
			publication_date_option: 3,
			sort: 'publication_date',
			desc: true
		},
		elastic_query: null,
		compare_field: widget.elasticsearch_comparison_field,
		compare_selected_values: [],
		block_height: 1,
		block_width: 1
	};

	//if there's multiple ways to compare, add the comparison selector step
	if (!arrayIsNullOrEmpty(widget.chart_comparison_ids)) {
		if (widget.chart_comparison_ids.length === 1) {
			//auto select the first one, if there's not multiple choices
			chart.chart_comparison_id = widget.chart_comparison_ids[0];
		}
	}

	//auto select the first/only chart definition
	if (widget.chart_definitions.length === 1) {
		applyChartDefinitionToChart(chart, widget.chart_definitions[0], dashboardDataContext, false);
	}

	return chart;
};

/**
 * applies the default properties of a chart definition to a chart
 * @param {*} chart
 * @param {*} chartDefinition
 * @param {*} dashboardDataContext
 */
export const applyChartDefinitionToChart = (chart, chartDefinition, dashboardDataContext, forceDefaultValues = false) => {
	chart.chart_name = chartDefinition.name;
	chart.chart_definition_name = chartDefinition.name;
	chart.chart_definition_id = chartDefinition.chart_definition_id;
	chart.query_template = chartDefinition.query_template;
	chart.block_height = chartDefinition.block_height;
	chart.block_width = chartDefinition.block_width;
	chart.allow_benchmark = chartDefinition.allow_benchmark;
	chart.benchmark_enabled = chartDefinition.benchmark_default_value;
	chart.nested_charts = [];

	if (isMediaItemListChart(chart)) {
		chart.search_filter.sort = sortOptionsLookup.totalEngagements;
		const chartName = mediaItemListChartSortOptions.find(({ value }) => value === chart.search_filter.sort)?.chartTitle;
		if (!stringIsNullOrEmpty(chartName)) {
			chart.chart_name = chartName;
		}
	}

	const widget = dashboardDataContext.widgetsLookup[chart.widget_id];
	chart.compare_field = chartDefinition?.elasticsearch_comparison_field || widget?.elasticsearch_comparison_field || null;

	//only select the chart type id if the length is 1 OR you're setting defaults
	if (!arrayIsNullOrEmpty(chartDefinition.chart_type_ids) && (!forceDefaultValues || chartDefinition.chart_type_ids.length === 1)) {
		chart.chart_type_id = chartDefinition.chart_type_ids[0];
		chart.am_chart_config = dashboardDataContext.chartTypesLookup[chart.chart_type_id].am_chart_config;
	} else {
		//clear the values after a change
		chart.chart_type_id = null;
		chart.am_chart_config = null;
	}

	if (!arrayIsNullOrEmpty(chartDefinition.chart_metric_ids) && (!forceDefaultValues || chartDefinition.chart_metric_ids.length === 1)) {
		// When adding Volume and audience over time change the default selected option to Volume, Audience
		if (chart.chart_definition_id === chartDefinitionIds.volumeAndAudienceOverTime && chartDefinition?.chart_metric_ids.length > 1) {
			chart.chart_metric_ids = [chartMetricIds.volume, chartMetricIds.potentialImpressions];
		} else {
			chart.chart_metric_ids = [chartDefinition.chart_metric_ids[0]];
			if (chartDefinition.chart_definition_id === chartDefinitionIds.metricDistribution) {
				chart.chart_name = dashboardDataContext.chartMetricsLookup[chartDefinition.chart_metric_ids[0]].name;
			}
		}
	} else {
		chart.chart_metric_ids = null;
	}

	if (!arrayIsNullOrEmpty(chartDefinition.chart_interval_ids)) {
		if (forceDefaultValues || chartDefinition.chart_interval_ids.length === 1) {
			chart.chart_interval_id = chartDefinition.chart_interval_ids[0];
		} else if (chartDefinition.chart_interval_ids.includes(chartIntervals.day)) {
			//we want to force 'day' as the default chart interval, but only if day is one of the available intervals
			chart.chart_interval_id = chartIntervals.day;
		}
	} else {
		chart.chart_interval_id = null;
	}

	const isGeographyBreakdown = chart.chart_definition_id === chartDefinitionIds.geographyBreakdown;
	if (isMapChart(chart) || isGeographyBreakdown) {
		delete chart.chart_map_definition_id;
	}

	return chart;
};

/**
 * taking a few hard coded properties, this creates a chart
 * @param {*} widget_id
 * @param {*} chart_definition_id
 * @param {*} chart_type_id
 * @param {*} chart_metric_ids
 * @param {*} chart_interval_id
 * @param {*} workspace_id
 * @param {*} dashboardDataContext
 */
export const createChart = (
	{
		widget_id,
		chart_definition_id,
		chart_type_id,
		chart_metric_ids,
		chart_interval_id,
		workspace_id,
		disableExport,
		disableLegend,
		disableLabels,
		disableCursor,
		modifyAmChartConfig,
		props,
		...other
	},
	dashboardDataContext
) => {
	const widget = dashboardDataContext.widgetsLookup[widget_id];

	let chart = createChartFromWidget(widget, workspace_id, dashboardDataContext);

	const chartDefinition = dashboardDataContext.chartDefinitionsLookup[widget_id][chart_definition_id];

	if (isNullOrUndefined(chartDefinition)) {
		console.error(`chart definition does not exist. widgetId='${widget_id}' chartDefinitionId='${chart_definition_id}'`);
		return null;
	}

	applyChartDefinitionToChart(chart, chartDefinition, dashboardDataContext, false);

	if (!isNullOrUndefined(chart_type_id)) {
		chart.chart_type_id = chart_type_id;
		chart.am_chart_config = dashboardDataContext.chartTypesLookup[chart_type_id].am_chart_config;
	}

	if (!arrayIsNullOrEmpty(chart_metric_ids)) {
		chart.chart_metric_ids = chart_metric_ids;
	}

	if (!isNullOrUndefined(chart_interval_id)) {
		chart.chart_interval_id = chart_interval_id;
	}

	chart.props = props;
	if (disableExport) {
		delete chart.am_chart_config?.chartTemplate?.exporting;
	}

	if (disableCursor) {
		delete chart.am_chart_config?.chartTemplate?.cursor;
	}

	if (disableLegend && !isNullOrUndefined(chart.am_chart_config) && !isNullOrUndefined(chart.am_chart_config.seriesTemplate)) {
		chart.am_chart_config.seriesTemplate.hiddenInLegend = true;
	}

	if (disableLabels) {
		chart.am_chart_config.chartTemplate = updateFormState(chart.am_chart_config.chartTemplate, {
			'xAxes[0].renderer.labels.template.disabled': true,
			'xAxes[0].renderer.grid.template.disabled': true,
			'xAxes[0].startLocation': 0.5,
			'xAxes[0].endLocation': 0.7,

			'yAxes[0].renderer.labels.template.disabled': true,
			'yAxes[0].renderer.grid.template.disabled': true,
			'yAxes[0].renderer.baseGrid.template.disabled': true,
			'yAxes[0].min': 0,

			paddingTop: 0,
			paddingBottom: 0,
			paddingLeft: 0,
			paddingRight: 0
		});
	}

	if (!isNullOrUndefined(modifyAmChartConfig)) {
		chart.am_chart_config = modifyAmChartConfig(cloneDeep(chart.am_chart_config));
	}

	return {
		...chart,
		...other
	};
};

/**
 * creates a multi part chart, like a card strip. To use it:
 
 	createMultiChart(
 	{
 		chartRequests: [
 			{
 				chart_definition_id: chartDefinitionIds.totalVolume,
 				chart_type_id: chartTypeIds.card,
 				chart_metric_ids: [chartMetricIds.volume]
 			},
 			{
 				chart_definition_id: chartDefinitionIds.totalAudience,
 				chart_type_id: chartTypeIds.card,
 				chart_metric_ids: [chartMetricIds.potentialImpressions]
 			}
 		],
 		chart_name: 'Summary',
 		widget_id: widgetIds.metrics,
 		...shared
 	},
 	dashboardDataContext
   )
 */
export const createMultiChart = ({ chart_name, widget_id, workspace_id, props, chartRequests, ...other }, dashboardDataContext) => {
	return {
		chart_name,
		...createChartFromWidget(dashboardDataContext.widgetsLookup[widget_id], workspace_id, dashboardDataContext),
		nested_charts: chartRequests.map((chartRequest) => createChart({ ...chartRequest, widget_id, workspace_id }, dashboardDataContext)),
		props: {
			style: { height: 'auto', minHeight: '200px' },
			...props
		},
		...other
	};
};

/**
 * after creating an array of charts, you can use this to assign ids to charts + their children
 * @param {*} charts
 */
export const assignIdsToCharts = (charts) => {
	let nextId = 1;
	return (charts || [])
		.filter((chart) => !isNullOrUndefined(chart))
		.map((chart) => {
			chart.id = nextId++;
			if (!arrayIsNullOrEmpty(chart.nested_charts)) {
				chart.nested_charts.forEach((nestedChart) => {
					nestedChart.id = nextId++;
				});
			}
			return chart;
		});
};

/**
 * gets the next id for a chart
 */
export const getNextChartId = (charts) => (arrayIsNullOrEmpty(charts) ? 0 : getMaxChartId(charts) + 1);

/**
 * gets the next id for a chart
 */
export const getMaxChartId = (charts) => {
	if (arrayIsNullOrEmpty(charts)) {
		return 0;
	}

	const max = charts.reduce((maxValue, { nested_charts, id }) => {
		maxValue = (nested_charts || []).reduce((nestedMaxValue, nestedCurr) => Math.max(nestedMaxValue, nestedCurr.id), maxValue);
		return Math.max(maxValue, id);
	}, 0);

	return max;
};

/**
 * Scrolls an element to the top
 * @param {HTML Element} HTML element / reference
 * @returns void
 */
export const scrollChartBuilderElementToTop = (element) => {
	if (isNullOrUndefined(element)) {
		return;
	}

	// We wait here for the scrollTop value to change when we shift steps due to the transition, scrollTop may be the previous value.
	setTimeout(() => {
		if (isNullOrUndefined(element.scrollTo) || isNullOrUndefined(element.scrollTop) || element.scrollTop === 0) {
			return;
		}

		element.scrollTo(0, 0);
	}, 150); // 255ms is the transition timeout on ChartBuilder,
};

export const getChartMapDefinitionName = (chart_map_type_id, chart_map_definition_id, chart, dashboardDataContext) => {
	const chartMapDefinitionName = dashboardDataContext.chartMapDefinitionsLookup[chart_map_type_id][chart_map_definition_id].name;
	const chartMetricId = chart.chart_metric_ids[0];
	const chartNameWithMetric = `${chartMapDefinitionName} ${dashboardDataContext.chartMetricsLookup[chartMetricId].name.toLowerCase()}`;

	switch (chart_map_type_id) {
		case chartMapTypeIds.segmentByCountry:
			return `${chartNameWithMetric} by country`;
		case chartMapTypeIds.segmentByRegionState:
			return `${chartNameWithMetric} by region`;
		case chartMapTypeIds.segmentByCity:
			return `${chartNameWithMetric} by city`;
		default:
			return chart.chart_definition_name;
	}
};

import * as am4core from '@amcharts/amcharts4/core';
import React, { useCallback, useEffect, useMemo, useReducer, useRef } from 'react';
import Skeleton from '@mui/material/Skeleton';
import { useUserStorageContext } from '@truescope-web/react/lib/components/UserStorageProvider';
import { buildChart, disposeChart } from '@truescope-web/react/lib/components/charts/ChartContainerConstants';
import NoChartDataMessage from '@truescope-web/react/lib/components/charts/NoChartDataMessage';
import Typography from '@truescope-web/react/lib/components/layout/Typography';
import useCancelToken, { wasTokenCancelled } from '@truescope-web/react/lib/hooks/useCancelToken';
import { isNullOrUndefined } from '@truescope-web/utils/lib/objects';
import { stringIsNullOrEmpty } from '@truescope-web/utils/lib/strings';
import { useConfigContext } from '../../../../components/Config/ConfigProvider';
import { useApiLookup } from '../../../../components/providers/ApiLookupProvider';
import { useDashboardDataContext } from '../../../../components/providers/DashboardDataProvider';
import { useReportsActivityContext } from '../ReportsActivityProvider';
import { createReportsActivityChart, getReportActivityData, isHourlyInterval, metricIsPercentage } from './ReportsActivityChartConstants';
import SelectMetrics from './SelectMetrics';
import reportsActivityChartReducer, { getInitialState, updateState } from './reducer';

const chartId = 'reports-activity-chart';

const Error = ({ errorMessage }) => (
	<div className="reports-activity-chart__content">
		<NoChartDataMessage message={errorMessage} />
	</div>
);

const Loading = () => (
	<div className="chart-loader-container chart-loader-container--am-chart">
		<Skeleton width="160px" height="20px" variant="rectangular" />
		<Skeleton width="100%" height="420px" variant="rectangular" />
		<Skeleton width="100%" height="30px" variant="rectangular" />
	</div>
);

const Header = ({ children }) => (
	<div className="reports-activity-chart">
		<Typography className="reports-activity-chart__header">Activity Overview</Typography>
		{children}
	</div>
);

const ReportsActivityChart = () => {
	const { userStorage, setUserStorage } = useUserStorageContext();
	const [getClientApi] = useApiLookup();
	const dashboardDataContext = useDashboardDataContext();
	const [{ workspace }] = useConfigContext();
	const [{ dateFrom, dateTo }] = useReportsActivityContext();
	const [resetToken] = useCancelToken();
	const [{ isLoading, chartData, selectedMetrics, errorMessage }, dispatch] = useReducer(
		reportsActivityChartReducer,
		getInitialState({ selectedMetrics: userStorage.reportsActivity?.selectedMetrics })
	);
	const amChart = useRef();

	const hasPercentageMetrics = useMemo(() =>
		[selectedMetrics.left, selectedMetrics.right].some((value) => metricIsPercentage(value), [selectedMetrics])
	);

	useEffect(() => {
		if (isNullOrUndefined(amChart.current)) {
			return;
		}
		amChart.current.yAxes.values[0].disabled = !hasPercentageMetrics;
	}, [hasPercentageMetrics, amChart.current]);

	const chartConfig = useMemo(
		() => createReportsActivityChart(workspace.workspace_id, dashboardDataContext),
		[dashboardDataContext, workspace.workspace_id]
	);

	const loadData = useCallback(async () => {
		const token = resetToken();
		try {
			dispatch(updateState({ isLoading: true }));
			const chartData = await getReportActivityData(getClientApi, workspace.workspace_id, dateFrom, dateTo, token);
			dispatch(updateState({ chartData, errorMessage: undefined, isLoading: false }));
		} catch (e) {
			if (wasTokenCancelled(token)) {
				return;
			}
			console.error(`failed to load report activity chart data - ${e.message}`, e);
			dispatch(updateState({ errorMessage: e.message, isLoading: false }));
		}
	}, [getClientApi, workspace.workspace_id, dateFrom, dateTo, dispatch]);

	useEffect(() => {
		loadData();
	}, [loadData]);

	useEffect(() => {
		if (isNullOrUndefined(chartData) || isNullOrUndefined(chartConfig)) {
			return;
		}

		try {
			dispatch(updateState({ isLoading: true }));
			const { amChart: chart, message: amChartMessage = null } = buildChart(chartId, chartConfig, chartData, {
				onChartClick: null
			});

			if (!stringIsNullOrEmpty(amChartMessage)) {
				throw new Error(amChartMessage);
			}

			amChart.current = chart;
			showChart(getSeriesIndex(selectedMetrics.left), 'left');
			showChart(getSeriesIndex(selectedMetrics.right), 'right');

			chart.xAxes.values[0].tooltipDateFormat = isHourlyInterval(dateTo, dateFrom) ? 'd MMM HH:mm' : 'd MMM';

			dispatch(updateState({ isLoading: false }));

			return () => {
				if (!isNullOrUndefined(amChart.current)) {
					disposeChart(amChart.current);
				}
			};
		} catch (e) {
			if (wasTokenCancelled(e)) {
				return;
			}
			console.error(`failed to create reports activity chart - ${e.message}`, e);
			dispatch(updateState({ errorMessage: e.message, isLoading: false }));
		}
	}, [chartConfig, chartData]);

	const getSeriesIndex = (value) => chartData.series.findIndex((data) => data.value === value);

	const hideChart = (index) => amChart.current?.series.values[index].hide();

	const showChart = (index, key) => {
		if (isNullOrUndefined(amChart.current)) {
			return;
		}
		const lineSeries = amChart.current.series.values[index];
		lineSeries.show();

		//keep the two visible line series in blue and orange
		const chartColor = am4core.color(key === 'left' ? '#1786d1' : '#ff8745');
		lineSeries.stroke = chartColor;
		lineSeries.fill = chartColor;
	};

	const handleChangeChartMetric = (_e, value, key) => {
		//hide the previously selected metric
		hideChart(getSeriesIndex(selectedMetrics[key]));
		//update newly selected metric
		const updatedState = { ...selectedMetrics, [key]: value };
		dispatch(updateState({ selectedMetrics: updatedState }));
		showChart(getSeriesIndex(value), key);
		//persist value
		setUserStorage(({ reportsActivity, ...prev }) => ({
			...prev,
			reportsActivity: {
				...(reportsActivity || {}),
				selectedMetrics: updatedState
			}
		}));
	};

	if (isLoading) {
		return <Loading />;
	}

	return (
		<Header>
			{!stringIsNullOrEmpty(errorMessage) ? (
				<Error errorMessage={errorMessage} />
			) : (
				<>
					<div className="reports-activity-chart__content">
						<div id={chartId} className="chart-container--am-chart"></div>
					</div>
					<div className="reports-activity-chart__metric-options">
						<SelectMetrics value={selectedMetrics} onChange={handleChangeChartMetric} />
					</div>
				</>
			)}
		</Header>
	);
};

export default ReportsActivityChart;

import { mediaItemListLoadingState } from '@truescope-web/react/lib/components/widgets/MediaItemList';

export const updateState = (data) => {
	return {
		type: 'update',
		payload: data
	};
};

export const appendItems = (data) => {
	return {
		type: 'append-items',
		payload: data
	};
};

export const resetState = () => {
	return {
		type: 'reset'
	};
};

export const getInitialState = () => {
	return {
		items: [],
		totalCount: 0,
		offset: 0,
		loadingState: mediaItemListLoadingState.loadingAllItems,
		cancelTokenSource: undefined,
		searchString: undefined,
		activityTypes: undefined
	};
};

export const setLoadingState = (loadingState) => {
	return {
		type: 'set-loading-state',
		payload: loadingState
	};
};

const activityStreamReducer = (state, action) => {
	switch (action?.type) {
		case 'update':
			return {
				...state,
				...action.payload
			};
		case 'set-loading-state':
			return {
				...state,
				loadingState: action.payload
			};
		case 'append-items':
			return {
				...state,
				...action.payload,
				items: state.items.concat(action.payload.items)
			};
		case 'set-items':
			return {
				...state,
				items: action.payload,
				loadingState: mediaItemListLoadingState.notLoading
			};
		case 'reset':
			return getInitialState();
		default:
			throw new Error(`unknown reducer action '${action?.type}'`);
	}
};

export default activityStreamReducer;

import React from 'react';
import CheckableAutocomplete from '@truescope-web/react/lib/components/form/CheckableAutocomplete';
import TextFieldWithDebounce from '@truescope-web/react/lib/components/form/TextFieldWithDebounce';
import Grid from '@truescope-web/react/lib/components/layout/Grid';
import { activityTypeOptions } from '../constants';
import { useActivityStreamContext } from './ActivityStreamProvider';
import { updateState } from './reducer';

const Search = () => {
	const [state, dispatch] = useActivityStreamContext();
	return (
		<div className="activity-stream-search">
			<Grid container>
				<Grid item>
					<TextFieldWithDebounce
						onChange={(_e, value) => dispatch(updateState({ searchString: value }))}
						value={state.searchString}
						placeholder="Enter recipient email or report name"
						labelAbove
						label="Search by report or recipient"
					/>
				</Grid>
				<Grid item>
					<CheckableAutocomplete
						labelAbove
						label="Search by activity types"
						labelAll="All activity types"
						options={activityTypeOptions}
						value={state.activityTypes}
						placeholder="Select one or more activity types"
						onChange={(_e, value) => dispatch(updateState({ activityTypes: value }))}
					/>
				</Grid>
			</Grid>
		</div>
	);
};

export default Search;

import React, { useEffect, useRef } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import { CSSTransition } from 'react-transition-group';
import Typography from '@truescope-web/react/lib/components/layout/Typography';
import { isNullOrUndefined } from '@truescope-web/utils/lib/objects';
import PartialLoadingIndicator from '../widgets/PartialLoadingIndicator';

const SheetLayout = ({
	children,
	header,
	footer,
	isLoading = false,
	withTransition = false,
	cssTransitionKey,
	className = '',
	scrollToTopTrigger //todo: tidy this up next sprint.
}) => {
	const scrollbarsRef = useRef();

	useEffect(() => {
		//scrollTopTrigger is used where children that are passed into SheetLayout have multiple steps
		//it should be the step key, which lets Sheet layout knows that the different step is rendered
		//and when the step changes, the scroller should scroll to the top
		if (!isNullOrUndefined(scrollToTopTrigger)) {
			scrollbarsRef?.current?.scrollToTop();
		}
	}, [scrollToTopTrigger]);

	return (
		<div className={`sheet-layout ${className}`}>
			{!isNullOrUndefined(header) &&
				(typeof header === 'string' ? (
					<div className="sheet-layout__header">
						<Typography variant="h5">{header}</Typography>
					</div>
				) : (
					header
				))}
			<div className="sheet-layout__body">
				<Scrollbars ref={scrollbarsRef}>
					<div className="sheet-layout__body__content">
						{withTransition ? (
							<CSSTransition unmountOnExit={false} key={cssTransitionKey} classNames="slide-right" timeout={255}>
								{children}
							</CSSTransition>
						) : (
							children
						)}
					</div>
				</Scrollbars>
			</div>
			{!isNullOrUndefined(footer) && <div className="sheet-layout__footer pad-container">{footer}</div>}
			{isLoading && <PartialLoadingIndicator loading={isLoading} />}
		</div>
	);
};

export default SheetLayout;

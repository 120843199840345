import React from 'react';
import Skeleton from '@mui/material/Skeleton';
import Avatar from '@truescope-web/react/lib/components/widgets/Avatar';

const ChannelInfoCardSkeleton = () => {
	return (
		<div className="channel-info-card">
			<div className="channel-info-card__header">
				<div className="channel-info-card__header__source">
					<Skeleton variant="circular">
						<Avatar size="md" />
					</Skeleton>
				</div>
				<div className="channel-info-card__header__info">
					<Skeleton className="channel-info-card__header__info__name" variant="rectangular" width={200} height={10} />
					<Skeleton
						className="channel-info-card__header__info__link"
						variant="rectangular"
						width={100}
						height={10}
						sx={{ marginTop: '5px' }}
					/>
				</div>
			</div>
			<div className="channel-info-card__description">
				<Skeleton variant="rectangular" width={200} height={10} sx={{ marginTop: '5px' }} />
				<Skeleton variant="rectangular" width={200} height={10} sx={{ marginTop: '5px' }} />
			</div>
		</div>
	);
};

export default ChannelInfoCardSkeleton;

import { dateOptionsLookup, prefillDates } from '@truescope-web/utils/lib/dates';
import { activityTablesLookup } from './activitiesTable/constants';

export const updateState = (data) => {
	return {
		type: 'update',
		payload: data
	};
};

export const getInitialState = (initialState = {}) => {
	const state = {
		activityTableType: activityTablesLookup.report,
		dateFrom: undefined,
		dateTo: undefined,
		dateOption: dateOptionsLookup.last30Days,
		isEmpty: false,
		...initialState
	};
	return {
		...state,
		...prefillDates(state.dateOption, state.dateFrom, state.dateTo, null, false)
	};
};

const reportsActivityReducer = (state, action) => {
	switch (action?.type) {
		case 'update':
			return {
				...state,
				...action.payload
			};
		case 'reset':
			return getInitialState();
		default:
			throw new Error(`unknown reducer action '${action?.type}'`);
	}
};

export default reportsActivityReducer;

import React from 'react';
import Typography from '@truescope-web/react/lib/components/layout/Typography';
import ColumnBenchmark from './ColumnBenchmark';

const BenchmarkColumnHeader = ({ benchmark, colDef, isIncreaseBad, valueFormatter }) => {
	return (
		<div className={`benchmark-column-header benchmark-column-header--align-${colDef?.headerAlign || 'left'}`}>
			<Typography variant="body" bold>
				{colDef.headerName}
			</Typography>
			<ColumnBenchmark
				isIncreaseBad={isIncreaseBad}
				current={benchmark?.current?.[colDef.field]}
				previous={benchmark?.previous?.[colDef.field]}
				valueFormatter={valueFormatter}
			/>
		</div>
	);
};

export default BenchmarkColumnHeader;

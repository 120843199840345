import React from 'react';
import { BsExclamationCircle } from 'react-icons/bs';

const ChannelInfoCardEmptyState = () => {
	return (
		<div className="channel-info-card">
			<div className="channel-info-card__empty-state">
				<BsExclamationCircle size="19.5px" />
				<div>Failed to load channel, please try again later.</div>
			</div>
		</div>
	);
};

export default ChannelInfoCardEmptyState;

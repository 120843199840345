import React, { memo } from 'react';
import Typography from '@truescope-web/react/lib/components/layout/Typography';
import { arrayIsNullOrEmpty } from '@truescope-web/utils/lib/arrays';
import { stringIsNullOrEmpty } from '@truescope-web/utils/lib/strings';
import { mediaTypesLookup } from '@truescope-web/utils/src/mediaTypes';

const MediaTypeContent = memo(({ mediaType }) => {
	if (stringIsNullOrEmpty(mediaType)) {
		return null;
	}

	let mergeField = undefined;
	let mergeFieldTextJoin = 'a';

	switch (mediaType) {
		case mediaTypesLookup.online:
		case mediaTypesLookup.forum:
		case mediaTypesLookup.announcements:
		case mediaTypesLookup.hansard:
			mergeField = `${mergeFieldTextJoin} website`;
			break;
		case mediaTypesLookup.radio:
			mergeField = `${mergeFieldTextJoin} ${mediaType.toLowerCase()} channel`;
			break;
		case mediaTypesLookup.tv:
		case mediaTypesLookup.youtube:
			mergeField = `${mergeFieldTextJoin} ${mediaType} channel`;
			break;
		case mediaTypesLookup.twitter:
		case mediaTypesLookup.instagram:
		case mediaTypesLookup.tiktok:
		case mediaTypesLookup.linkedIn:
			mergeField = `${mergeFieldTextJoin} ${mediaType} account`;
			break;
		case mediaTypesLookup.facebook:
			mergeField = `${mergeFieldTextJoin} page`;
			break;
		case mediaTypesLookup.reddit:
			mergeField = `${mergeFieldTextJoin} subreddit`;
			break;
		case mediaTypesLookup.print:
			mergeField = `${mergeFieldTextJoin} publication`;
			break;
		case mediaTypesLookup.podcasts:
			mergeField = `${mergeFieldTextJoin} podcast`;
			break;
		default:
			mergeField = `from ${mediaType}`;
	}

	return <> is {mergeField} </>;
});

const ChannelInfoCardDescription = ({ data }) => {
	const hasCities = !arrayIsNullOrEmpty(data.cities);
	const hasRegions = !arrayIsNullOrEmpty(data.regions);
	const hasCountries = !arrayIsNullOrEmpty(data.countries);
	const hasOneCityOrRegionOrCountry = hasCities || hasRegions || hasCountries;
	const isPodcast = data.mediaType === mediaTypesLookup.podcasts;
	const mediaTypeContentSeparator = isPodcast ? 'from' : 'in';
	return (
		<Typography className="channel-info-card__description">
			{data.name}
			<MediaTypeContent mediaType={data.mediaType} />
			{hasOneCityOrRegionOrCountry && mediaTypeContentSeparator} {hasCities && `${data.cities.join(' and ')}`}
			{hasCities && hasRegions && ', '}
			{hasRegions && `${data.regions.join(' and ')}`}
			{((hasCities && !hasRegions && hasCountries) || (hasRegions && hasCountries)) && ', '}
			{hasCountries && `${data.countries.join(' and ')}`}
			{!stringIsNullOrEmpty(data.owner) && ` and is owned by ${data.owner}`}
		</Typography>
	);
};

export default memo(ChannelInfoCardDescription);

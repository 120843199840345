import { createNewReportTemplate } from './ReportTemplateBuilderConstants';

/**
 * default empty template for reports
 */
const defaultTemplate = {
	id: 1,
	icon: 'emptyTemplate',
	name: 'Blank Template',
	setDefaultProperties: createNewReportTemplate
};

export default defaultTemplate;

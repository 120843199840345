//note: the next line will load the word clouds plugin into memory. they won't work otherwise.
import ISO6391 from 'iso-639-1';
import cloneDeep from 'lodash.clonedeep';
import get from 'lodash.get';
import set from 'lodash.set';
import unset from 'lodash.unset';
import moment from 'moment';
import { isMapChart, isMediaItemListChart } from '@truescope-web/react/lib/components/charts/ChartContainerConstants';
import {
	chartDefinitionIds,
	chartIntervals,
	chartMapTypeIds,
	chartMetricIds,
	chartTypeIds,
	widgetIds
} from '@truescope-web/react/lib/components/charts/enums';
import { arrayIsNullOrEmpty } from '@truescope-web/utils/lib/arrays';
import { dateOptionsLookup } from '@truescope-web/utils/lib/dates';
import { mergeFilters } from '@truescope-web/utils/lib/filters';
import { isNullOrUndefined } from '@truescope-web/utils/lib/objects';
import { filterOperator, hasActiveFilters } from '@truescope-web/utils/lib/search';
import { sentimentOptions } from '@truescope-web/utils/lib/sentiments';
import { joinDefined, stringIsNullOrEmpty } from '@truescope-web/utils/lib/strings';
import { dashboardDateRanges } from '../../../views/dashboard/builder/DashboardBuilderConstants';
import { getLocale, getTimeZoneName } from '../../Constants';
import { convertItems } from '../../mediaItem/MediaItemConstants';
import { sendWebSocketRequestPromise } from '../../providers/WebSockets/constants';
import { filterDefinitions, getFilterFieldFromElasticField } from '../FilterComponents';
import { deserializeFilters, removePublicationDateFilters, serializeFilters, trimEmptyFilters } from '../FilterConstants';

const createEmptyChartData = (chartId) => ({
	id: chartId,
	data: [],
	series: []
});

export const loadMultipleChartsData = async (getClientApi, charts, workspace, appCache, user, showRequest, cancelToken) => {
	const serializedCharts = charts.map((chartJson) => serializeChart(chartJson, workspace, appCache, user, true));

	if (serializedCharts.every((serializedChart) => !hasActiveFilters(serializedChart.search_filter))) {
		//if no filters, skip over trying to get data
		return Promise.resolve({
			chartData:
				serializedCharts.length === 1
					? createEmptyChartData(serializedCharts[0].id)
					: serializedCharts.map((serializedChart) => createEmptyChartData(serializedChart.id))
		});
	}

	const api = await getClientApi();

	const { data } = await api.post(
		`dashboards/v1/charts`,
		{
			charts: serializedCharts,
			showElasticRequest: showRequest
		},
		{ cancelToken }
	);

	const { message, chartData, chartRequest } = data;

	if (!isNullOrUndefined(chartRequest)) {
		console.debug('chartData chartRequest', chartRequest?.unifiedRequest || chartRequest);
	}

	if (!stringIsNullOrEmpty(message)) {
		throw new Error(message);
	}

	return deserializeMultipleChartData(charts, chartData, workspace);
};

export const loadChartData = (getClientApi, chart, workspace, appCache, user, showRequest, cancelToken) => {
	return loadMultipleChartsData(getClientApi, [chart], workspace, appCache, user, showRequest, cancelToken);
};

export const loadMultipleChartsDataWebSocket = async (
	isWebsocketReady,
	webSocket,
	send,
	websocketMessageId,
	charts,
	workspace,
	appCache,
	user,
	showRequest
) => {
	let messageId = undefined;
	try {
		const serializedCharts = charts.map((chartJson) => serializeChart(chartJson, workspace, appCache, user, true));

		if (serializedCharts.every((serializedChart) => !hasActiveFilters(serializedChart.search_filter))) {
			//if no filters, skip over trying to get data
			return Promise.resolve({
				chartData:
					serializedCharts.length === 1
						? createEmptyChartData(serializedCharts[0].id)
						: serializedCharts.map((serializedChart) => createEmptyChartData(serializedChart.id))
			});
		}

		const {
			data,
			error = false,
			messageId: responseMessageId,
			message
		} = await sendWebSocketRequestPromise(
			isWebsocketReady,
			webSocket,
			{
				action: 'charts',
				data: {
					charts: serializedCharts,
					showElasticRequest: showRequest
				},
				workspaceId: workspace.workspace_id
			},
			send,
			websocketMessageId
		);

		messageId = responseMessageId;

		if (error) {
			return {
				message,
				messageId
			};
		}

		const { chartData } = data;

		return {
			...deserializeMultipleChartData(charts, chartData, workspace),
			messageId
		};
	} catch (e) {
		return {
			message: e.message || e,
			messageId
		};
	}
};

export const loadChartDataWebSocket = (
	isWebsocketReady,
	webSocket,
	send,
	websocketMessageId,
	chart,
	workspace,
	appCache,
	user,
	showRequest
) => {
	return loadMultipleChartsDataWebSocket(
		isWebsocketReady,
		webSocket,
		send,
		websocketMessageId,
		[chart],
		workspace,
		appCache,
		user,
		showRequest
	);
};

export const deserializeMultipleChartData = (charts, chartData, workspace, userId) => {
	return charts.length === 1
		? deserializeChartData(charts[0], chartData, workspace, userId)
		: {
				chartData: charts.map((chart, index) => deserializeChartData(chart, chartData?.[index], workspace, userId))
		  };
};

/**
 * given chart data, this function converts it to the users local time
 * @param {*} data received chart data from server
 * @returns data with local dates
 */
export const deserializeDates = (data) => {
	return (data || []).map((datum) => {
		return isNullOrUndefined(datum.date)
			? datum
			: {
					...datum,
					date: moment.utc(datum.date).local().format('YYYY-MM-DD HH:mm:ss')
			  };
	});
};

export const deserializeChartData = (chart, chartData, workspace, userId) => {
	if (isNullOrUndefined(chartData)) {
		return {
			chartData: {
				id: chart.id,
				data: []
			},
			entity_lookup: {}
		};
	}

	if (isMediaItemListChart(chart)) {
		return {
			chartData: {
				data: convertItems(workspace, chartData.data, userId),
				totalCount: chartData.totalCount,
				id: chartData.id
			},
			entity_lookup: {}
		};
	}

	return {
		chartData: Array.isArray(chartData)
			? chartData
			: {
					...chartData,
					data: deserializeDates(chartData.data || [])
			  },
		entity_lookup: (chartData.entities || []).reduce((lookup, { label, value }) => {
			// For table charts the key property is the ID
			if (chart.chart_type_id === chartTypeIds.table) {
				lookup[value] = label;
			} else {
				lookup[label] = value;
			}
			return lookup;
		}, {})
	};
};

/**
 * serializes a chart to be sent to the server
 */
export const serializeChart = ({ search_filter, time_zone_name, locale, ...chart }, workspace, appCache, user, preserveDateFilters) => {
	const updatedChart = cloneDeep(chart);
	updatedChart.time_zone_name = !stringIsNullOrEmpty(time_zone_name) ? time_zone_name : getTimeZoneName();
	updatedChart.locale = !stringIsNullOrEmpty(locale) ? locale : getLocale();
	updatedChart.search_filter = serializeFilters(search_filter, workspace, appCache, user, preserveDateFilters);

	if (isMediaItemListChart(chart) && chart.mediaItemListDisplaySettings?.showAve) {
		delete updatedChart.mediaItemListDisplaySettings.exchangeRate;
	}

	//the default bucket size is 10 (set in server side)
	if (chart.chart_definition_id === chartDefinitionIds.geographyBreakdown) {
		//except for geography/ map charts - the bucket size is not set, so we have to explicitly set it (specifically for geography breakdown chart only)
		updatedChart.bucket_size = 10;
	} else if ([chartDefinitionIds.mediaTypesBreakdown, chartDefinitionIds.mediaTypesOverTime].includes(chart.chart_definition_id)) {
		//for media types, we set it to the current known count of all media types
		updatedChart.bucket_size = appCache.mediaTypes.length;
	} else if (!arrayIsNullOrEmpty(chart.compare_selected_values) && chart.compare_selected_values.length > 10) {
		//if you've got more than 10, we need to set the bucket size to that comparison size
		updatedChart.bucket_size = chart.compare_selected_values.length;
	}

	if (isMapChart(updatedChart)) {
		delete updatedChart.am_chart_config.chartTemplate.projection;
		delete updatedChart.am_chart_config.chartTemplate.geodata;
		delete updatedChart.am_chart_config.chartTemplate.delta_longitude;
		delete updatedChart.am_chart_config.chartTemplate.delta_latitude;
	}

	return updatedChart;
};

/**
 * sets the ave and exchange rate for a chart
 * if the workspace has turned off ave, we turn it off.
 * @param {object} chart
 * @param {object} workspace
 */
const setAveAndExchangeRate = (chart, workspace) => {
	if (!isMediaItemListChart(chart)) {
		return;
	}

	if (isNullOrUndefined(chart.mediaItemListDisplaySettings)) {
		chart.mediaItemListDisplaySettings = {};
	}

	//if its been turned off in the workspace, we need to disable it
	if (!workspace.show_ave && chart.mediaItemListDisplaySettings.showAve) {
		chart.mediaItemListDisplaySettings.showAve = false;
	}

	if (chart.mediaItemListDisplaySettings.showAve) {
		chart.mediaItemListDisplaySettings.exchangeRate = workspace.exchangeRate.local;
	} else {
		delete chart.mediaItemListDisplaySettings.exchangeRate;
	}
};

/**
 * deserializes a saved chart
 */
export const deserializeChart = (chart, dashboard, userStorageDateOptions, dashboardDataContext, workspace) => {
	chart.search_filter = deserializeFilters(chart.search_filter, workspace);

	//we set this during deserialization so that the local user has the correct exchange rate when viewing this chart
	setAveAndExchangeRate(chart, workspace);

	//migration 22/12/2021 - replace chart metric id with an array
	if (!isNullOrUndefined(chart.chart_metric_id)) {
		if (chart.chart_metric_id === chartMetricIds.volumeAndPotentialImpressions) {
			chart.chart_metric_ids = [chartMetricIds.volume, chartMetricIds.potentialImpressions];
		} else {
			chart.chart_metric_ids = [chart.chart_metric_id];
		}
		delete chart.chart_metric_id;
		delete chart.chart_metric_name;
	}

	if (!arrayIsNullOrEmpty(chart.nested_charts)) {
		chart.nested_charts = chart.nested_charts.map((nestedChart) =>
			deserializeChart(nestedChart, dashboard, userStorageDateOptions, dashboardDataContext, workspace)
		);
	}

	if (!isNullOrUndefined(chart.search_filter)) {
		//ensure that the date options havent slipped onto the nested filters
		if (!arrayIsNullOrEmpty(chart.search_filter.nested)) {
			chart.search_filter.nested = chart.search_filter.nested.map((nested) => {
				nested.filters = removePublicationDateFilters(nested.filters);
				return nested;
			});
		}

		if (dashboard.date_range === dashboardDateRanges.separate && isNullOrUndefined(chart.search_filter.publication_date_option)) {
			//in some older charts, for separate dates, its possible that the date is not set on the filters in separate mode. therefore, we need to add a default, so it HAS a date limitation
			chart.search_filter.publication_date_option = dateOptionsLookup.last7Days;
			if (!isNullOrUndefined(chart.chart_interval_id)) {
				chart.chart_interval_id = chartIntervals.day;
			}
		} else if (dashboard.date_range === dashboardDateRanges.all && !isNullOrUndefined(chart.search_filter.publication_date_option)) {
			//if dates are stuck on a chart in ALL mode, we need to trim the moff
			chart.search_filter = removePublicationDateFilters(chart.search_filter);
		}
	}

	//2022-09-12 - migration - we dont want to use "exclude_compare_selected_values" at all in the app. lets move it completely into the api layer
	//luckily its only keyphrases that are affected
	if (chart.chart_definition_id === chartDefinitionIds.keyPhrases && !arrayIsNullOrEmpty(chart.exclude_compare_selected_values)) {
		set(chart, 'search_filter.not.key_phrases', chart.exclude_compare_selected_values);
		delete chart.exclude_compare_selected_values;
	}

	//2022-09-15 - there's a old bug with creating dashboards from templates where the score card id is missing. we should delete this in a few months
	if (isNullOrUndefined(chart.chart_definition_id) && !arrayIsNullOrEmpty(chart.nested_charts)) {
		chart.chart_definition_id = chartDefinitionIds.scoreCards;
	}

	//we want the deserializing browser to clear the time zone, so that when requests are sent, it stamps the current time zone into the request
	delete chart.time_zone_name;
	delete chart.locale;
	delete chart.time_zone;

	//2024-05-15 - remove interval name and only use chart_interval_id as the source of truth
	//if you dont delete this, the chart service may fall back to it
	delete chart.chart_interval_name;

	return chart;
};

/**
 * takes two sets of nested filters and removes incoming ones from the old collection, then sets them
 * @param {array} existingNested existing nested filters
 * @param {array} newNested new nested filters
 * @returns merged nested filters
 */
const replaceNestedFilters = (existingNested, newNested) => {
	//create a lookup for the incoming nested filters, which will dictact what should be ignored when merging the existing nested filters
	const ignoreFilterLookup = (newNested || []).reduce((lookup, { filter }) => {
		Object.keys(filter).forEach((field) => {
			lookup[field] = true;
		});
		return lookup;
	}, {});

	return (existingNested || []).filter(({ filter }) => !Object.keys(filter).some((field) => ignoreFilterLookup[field])).concat(newNested);
};

/**
 * applies a clickthrough filter value to the search schema
 */
export const applyClickthroughFilter = (chartFilters, key, value, field, category, merge) => {
	let filters = cloneDeep(chartFilters);
	let filterValue, filterProperty;

	switch (field) {
		case 'author_ids':
			filterValue = [{ label: key, value: parseInt(value, 10) }];
			filterProperty = 'authors';
			break;
		case 'categories.category_id':
			filterValue = [parseInt(value, 10)];
			filterProperty = 'categories';
			break;
		case 'companies_mentioned.id':
		case 'companies_mentioned':
			filterValue = [{ label: key, value: parseInt(value, 10) }];
			filterProperty = 'companies';
			break;
		case 'emoji':
			filterValue = [value];
			filterProperty = 'emojis';
			break;
		case 'geography_countries':
			filterValue = [{ label: value, value: value }];
			filterProperty = 'countries';
			break;
		case 'entities.extracted_entity_id':
			filterValue = [{ label: key, value: parseInt(value, 10) }];
			filterProperty = 'locations';
			break;
		case 'hashtags':
			filterValue = [value];
			filterProperty = 'hashtags';
			break;
		case 'language_code':
			filterValue = [{ label: ISO6391.getNativeName(value.toLowerCase()), value: value }];
			filterProperty = 'languages';
			break;
		case 'locations_mentioned':
			filterValue = [{ label: key, value: parseInt(value, 10) }];
			filterProperty = 'locations';
			break;
		case 'key_phrases':
			filterValue = [value];
			filterProperty = 'key_phrases';
			break;
		case 'mediatypes':
			filterValue = [{ label: value, value: value }];
			filterProperty = 'media_types';
			break;
		case 'nested':
			filterValue = replaceNestedFilters(filters.nested, value);
			filterProperty = 'nested';
			break;
		case 'people_mentioned':
		case 'people_mentioned.id':
			filterValue = [{ label: key, value: parseInt(value, 10) }];
			filterProperty = 'people';
			break;
		case 'queries.query_id':
			filterValue = [parseInt(value, 10)];
			filterProperty = 'query_ids';
			break;
		case 'scopes.scope_id':
			filterValue = [parseInt(value)];
			filterProperty = 'scope_ids';
			break;
		case 'sentiment':
			filterValue = [value];
			filterProperty = 'sentiments';
			break;
		case 'source_media_owner_id':
			filterValue = [{ label: key, value: parseInt(value, 10) }];
			filterProperty = 'source_media_owner_id';
			break;
		case 'source_source_id':
			filterValue = [{ label: key, value: parseInt(value, 10) }];
			filterProperty = 'sources';
			break;
		default:
			console.error(`Clickthrough Error - unknown field '${field}'`);
			break;
	}

	if (!isNullOrUndefined(category)) {
		let categoryProperty;
		//check to see if the category is sentiment. to add other checks, add them below this if condition
		if (sentimentOptions.some(({ value }) => value === category)) {
			if (
				field === filterDefinitions.companies.field ||
				field === filterDefinitions.locations.field ||
				field === filterDefinitions.people.field
			) {
				//for location, company or people we use entity_sentiments. otherwise use document level sentiment
				categoryProperty = 'entity_sentiments';
			} else {
				categoryProperty = 'sentiments';
			}
		}

		filters[categoryProperty] = (isNullOrUndefined(filters[categoryProperty]) || !merge ? [] : filters[categoryProperty]).concat([
			category
		]);
	}

	if (isNullOrUndefined(filters[filterProperty]) || !merge) {
		set(filters, filterProperty, filterValue);
	} else {
		const newFilters = set({}, filterProperty, filterValue);
		filters = mergeFilters(filters, newFilters);
	}

	return filters;
};

/**
 * gets the print formatted name for a chart
 */
export const getPrintChartName = ({ chart_name, nested_charts }) => {
	if (!arrayIsNullOrEmpty(nested_charts)) {
		return joinDefined(
			nested_charts.map((nestedChart) => nestedChart?.chart_name),
			', '
		);
	}

	if (!stringIsNullOrEmpty(chart_name)) {
		return chart_name;
	}

	return 'Untitled Chart';
};

/**
 * takes two filters and merges them together
 * we can't use the standard filter merging, because charts specifically needs the date at the root of the filter schema (it cant use multiple dates)
 * @param {filters} readonlyFilters
 * @param {filters} chartFilters
 * @returns merged filters
 */
export const mergeReadonlyAndChartFilters = (readonlyFilters = {}, chartFilters = {}) => {
	//we trim the readonly filters, as they often come from the dashboard, and may have empty arrays and such
	return mergeFilters(trimEmptyFilters(readonlyFilters) || {}, chartFilters);
};

/**
 * creates a filters json snippet for two overlapping queries
 * @param {string} queryX query x key
 * @param {string} queryY query y key
 * @param {object} entityLookup entity lookup for queries
 * @returns nested queries
 */
const getIntersectingQueriesFilterSnippet = (queryX, queryY, entityLookup) => [
	{
		operator: filterOperator.and,
		filter: {
			query_ids: [entityLookup[queryX]]
		}
	},
	{
		operator: filterOperator.and,
		filter: {
			query_ids: [entityLookup[queryY]]
		}
	}
];

/**
 * takes clickthrough event and creates clickthrough filters
 * @param {event} clickthroughEvent
 * @param {filters} readonlyFilters
 * @param {chart} chartJson
 * @param {lookup} entityLookup
 * @returns filters
 */
export const getClickThroughFilters = (clickthroughParams, readonlyFilters, chartJson, entityLookup) => {
	const { date, key, category, filterField: filterFieldProp, value, categoryX, categoryY } = clickthroughParams;
	let { not, ...clickthroughFilters } = cloneDeep(chartJson.search_filter);
	let filterField = null;

	if (
		!arrayIsNullOrEmpty(clickthroughFilters?.media_types) &&
		clickthroughFilters.media_types.every((mediaType) => typeof mediaType === 'string')
	) {
		clickthroughFilters.media_types = clickthroughFilters.media_types.map((value) => ({ label: value, value }));
	}

	if (
		!arrayIsNullOrEmpty(readonlyFilters?.media_types) &&
		readonlyFilters.media_types.every((mediaType) => typeof mediaType === 'string')
	) {
		readonlyFilters.media_types = readonlyFilters.media_types.map((value) => ({ label: value, value }));
	}

	if (
		key === 'Social Engagement' &&
		isNullOrUndefined(clickthroughFilters?.social_engagement) &&
		isNullOrUndefined(readonlyFilters?.social_engagement)
	) {
		clickthroughFilters.social_engagement = { to: null, from: 1 };
	} else if (
		key === 'Potential Impressions' &&
		isNullOrUndefined(clickthroughFilters?.audience) &&
		isNullOrUndefined(readonlyFilters?.audience)
	) {
		clickthroughFilters.audience = { to: null, from: 1 };
	}

	if (chartJson.widget_id === widgetIds.geography) {
		const clickedOption =
			chartJson.chart_type_id === chartTypeIds.table
				? { label: entityLookup[key], value: parseInt(key, 10) }
				: { label: key, value: parseInt(entityLookup[key], 10) };

		const nestedFilter = {};
		switch (chartJson.chart_map_type_id) {
			case chartMapTypeIds.segmentByCountry:
				nestedFilter.countries = [clickedOption];
				break;
			case chartMapTypeIds.segmentByRegionState:
				nestedFilter.regions = [clickedOption];
				break;
			case chartMapTypeIds.segmentByCity:
				nestedFilter.cities = [clickedOption];
				break;
			default:
				console.error(
					`unsupported geography chartDefinitionId='${chartJson.chart_definition_id}' clickthrough for chartMapTypeId='${chartJson.chart_type_id}'`
				);
				break;
		}
		if (isNullOrUndefined(clickthroughFilters.nested)) {
			clickthroughFilters.nested = [];
		}
		clickthroughFilters.nested.push({ operator: filterOperator.and, filter: nestedFilter });
	}
	if (chartJson.chart_definition_id === chartDefinitionIds.metricDistribution) {
		//metric distributions use nested filters with ranges
		filterField = filterFieldProp;
		clickthroughFilters.nested = replaceNestedFilters(clickthroughFilters.nested, value);
	} else if (chartJson.chart_definition_id === chartDefinitionIds.queriesStackedBar) {
		//queries stacked bar has two intersecting query ids that need to be AND'd together
		//we also deleting the existing query_ids field to ensure that no other queries slip in here
		filterField = getFilterFieldFromElasticField(chartJson.compare_field);
		delete clickthroughFilters.query_ids;
		clickthroughFilters.nested = (clickthroughFilters.nested || []).concat(
			getIntersectingQueriesFilterSnippet(key, category, entityLookup)
		);
	} else if (chartJson.chart_definition_id === chartDefinitionIds.queriesMatrix) {
		//queries matrix has two intersecting query ids that need to be AND'd together
		//we also deleting the existing query_ids field to ensure that no other queries slip in here
		filterField = getFilterFieldFromElasticField(chartJson.compare_field);
		delete clickthroughFilters.query_ids;
		clickthroughFilters.nested = (clickthroughFilters.nested || []).concat(
			getIntersectingQueriesFilterSnippet(categoryX, categoryY, entityLookup)
		);
	} else if (!stringIsNullOrEmpty(chartJson.compare_field)) {
		filterField = getFilterFieldFromElasticField(chartJson.compare_field);
		//lookup the key and pass it through
		if (stringIsNullOrEmpty(key)) {
			console.error('clickthrough error - compare_field provided, but no key passed through chart', chartJson);
		} else if (isNullOrUndefined(entityLookup) || isNullOrUndefined(entityLookup[key])) {
			console.error(`clickthrough error - '${key}' was not found in entity lookup`, entityLookup, chartJson);
		} else {
			const isTableChart = chartJson.chart_type_id === chartTypeIds.table;
			if (isTableChart) {
				clickthroughFilters = applyClickthroughFilter(
					clickthroughFilters,
					entityLookup[key],
					key,
					chartJson.compare_field,
					category,
					false
				);
			} else {
				clickthroughFilters = applyClickthroughFilter(
					clickthroughFilters,
					key,
					entityLookup[key],
					chartJson.compare_field,
					category,
					false
				);
			}
		}
	}

	Object.assign(clickthroughFilters, getClickthroughDateFilters(date, chartJson.chart_interval_id));

	delete clickthroughFilters.do_not_aggregate;

	return combineClickthroughAndReadonlyFilters(readonlyFilters, chartJson.search_filter, clickthroughFilters, filterField);
};

/**
 * takes a date and chart interval id, and creates date filters
 * @param {string} date
 * @param {int} chartIntervalId
 * @returns date filters
 */
const getClickthroughDateFilters = (date, chartIntervalId) => {
	if (stringIsNullOrEmpty(date) || isNullOrUndefined(chartIntervalId)) {
		return {};
	}

	let offset;
	switch (chartIntervalId) {
		case chartIntervals.hour:
			offset = { minutes: 59, seconds: 59 };
			break;
		case chartIntervals.day:
			offset = { days: 1, minutes: -1, seconds: 59 };
			break;
		case chartIntervals.week:
			offset = { days: 7, minutes: -1, seconds: 59 };
			break;
		case chartIntervals.month:
			offset = { months: 1, minutes: -1, seconds: 59 };
			break;
		default:
			offset = {};
			console.error(`Clickthrough Error - '${chartIntervalId}' is not a valid chart interval`);
			break;
	}

	return {
		publication_date_option: dateOptionsLookup.custom,
		publication_date_from: moment(date).toISOString(),
		publication_date_to: moment(date).add(offset).toISOString()
	};
};

/**
 * takes the readonly filters, chart filters and clickthrough filters and adjusts the shape of the clickthrough filters, based on whats been clicked
 * we need to AND the readonly filters in some cases, and OR them in other. it also merges them together
 * @param {filters} readonlyFilters readonly filters
 * @param {filters} chartFilters chart level fitlers
 * @param {filters} clickthroughFilters filters containing what's been clicked on the chart
 * @param {string} filterField the filter field of what's been clicked
 * @returns adjusted clickthrough filters
 */
const combineClickthroughAndReadonlyFilters = (readonlyFilters, chartFilters, clickthroughFilters, filterField) => {
	//if there's no readonly filters, skip the filter adjustment logic
	const trimmedReadonlyFilters = trimEmptyFilters(readonlyFilters);
	if (!hasActiveFilters(trimmedReadonlyFilters)) {
		return clickthroughFilters;
	}

	//if the readonly filters HAVE the field that's been clicked, unset it
	const readonlyFilterFieldValue = get(trimmedReadonlyFilters, filterField);
	const hasReadonlyFilterFieldValue = !arrayIsNullOrEmpty(readonlyFilterFieldValue);
	if (hasReadonlyFilterFieldValue) {
		unset(trimmedReadonlyFilters, filterField);
	}

	const hasChartFilterFieldValue = !arrayIsNullOrEmpty(get(chartFilters, filterField));

	//if the readonly filters had the value, and but the chart filters didnt, we need to push the readonly filter's value into the AND collection
	//because the clickthrough might contain the actual filter value
	if (hasReadonlyFilterFieldValue && !hasChartFilterFieldValue) {
		//if the readonly filters have this specific filter, but the original chart level filters do not, we AND the filter into the collection
		clickthroughFilters.nested = [
			{
				operator: filterOperator.and,
				filter: {
					[filterField]: readonlyFilterFieldValue
				}
			},
			...(clickthroughFilters.nested || [])
		];
	}
	return mergeFilters(trimmedReadonlyFilters, clickthroughFilters);
};

/**
 * checks to see if a chart is valid/can be shown
 */
export const isChartValid = (chartJson, minNestedChartDefinitions) => {
	if (isNullOrUndefined(chartJson) || isNullOrUndefined(chartJson.chart_definition_id)) {
		return false;
	}
	if (isNaN(minNestedChartDefinitions)) {
		return true;
	}
	if (chartJson.nested_charts?.length < minNestedChartDefinitions) {
		return false;
	}
	const isGeographyBreakdown = chartJson.chart_definition_id === chartDefinitionIds.geographyBreakdown;
	if (
		(isMapChart(chartJson) || isGeographyBreakdown) &&
		(isNullOrUndefined(chartJson.chart_map_type_id) || isNullOrUndefined(chartJson.chart_map_definition_id))
	) {
		return false;
	}

	return true;
};

export const getMultiChartsGridSpacing = ({ h: height }, chartsNumber) => {
	if (height < chartsNumber) {
		return 12 / Math.ceil(chartsNumber / height);
	}
	return 12;
};

export const chartBuilderStates = {
	hidden: 0,
	editChart: 1,
	editFilters: 2
};

/**
 * list of chart definition ids to log out debugging info for
 */
const debugChartDefinitionIds = [
	chartDefinitionIds.volumeAndAudienceOverTime,
	chartDefinitionIds.mediaTypesBreakdown,
	chartDefinitionIds.sentimentsBreakdown,
	chartDefinitionIds.metricDistribution
];

/**
 * in debug mode, this logs out volume sums so its easier to validate
 * @param {*} chartJson
 * @param {*} chartDataState
 * @returns
 */
export const debugLogChartSums = (chartJson, data) => {
	if (arrayIsNullOrEmpty(data) || !debugChartDefinitionIds.includes(chartJson.chart_definition_id)) {
		return;
	}

	const total = getChartVolumeSum(data);
	console.debug(`chartSum: ${total.toLocaleString()} (name=${chartJson.chart_name}, chartDefinitionId=${chartJson.chart_definition_id})`);
};

const getChartVolumeSum = (data) =>
	(data || []).reduce((sum, { count, Volume, range, ...other }) => {
		if (count) {
			return sum + count;
		} else if (Volume) {
			return sum + parseInt(Volume, 10);
		} else if (range) {
			const distribution = parseInt(other['Social Engagement Distribution'], 10);
			return sum + distribution;
		}
		return sum;
	}, 0);

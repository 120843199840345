import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { isNullOrUndefined } from '@truescope-web/utils/lib/objects';
import { useConfigContext } from '../Config/ConfigProvider';
import { useApiLookup } from '../providers/ApiLookupProvider';

const getUserHash = async (getClientApi) => {
	try {
		const api = await getClientApi();
		const { data } = await api.get('intercom/v1');

		return data;
	} catch (e) {
		console.error(e);
	}
};

/**
 * hooks intercom
 */
const useIntercom = () => {
	const [{ workspace, user, isInitialized: isConfigContextInitialized, isLoading: isConfigContextLoading }] = useConfigContext();
	const location = useLocation();
	const [getClientApi, impersonate] = useApiLookup();

	const isAppLoading =
		isNullOrUndefined(location?.pathname) || isNullOrUndefined(user) || isConfigContextLoading || !isConfigContextInitialized;

	useEffect(() => {
		if (isAppLoading || impersonate || isNullOrUndefined(workspace) || isNullOrUndefined(user)) {
			// if app hasn't loaded yet OR the user is impersonating, don't track any events
			return;
		}

		const fetchData = async () => {
			const hash = await getUserHash(getClientApi);

			window.Intercom('boot', {
				api_base: 'https://api-iam.intercom.io',
				app_id: 'ovcqfk1b',
				user_hash: hash,
				user_id: user.user_id,
				name: user.name,
				nickname: user.nickname,
				email: user.email,
				mobile: user.mobile,
				job_title: user.job_title,
				department: user.department,
				workspace_name: workspace.name,
				workspace_id: workspace.workspace_id,
				role_name: workspace.role_name,
				role_id: workspace.role_id,
				plan: workspace.plan_name,
				plan_id: workspace.plan_id,
				plan_start_date: workspace.plan_start_date,
				plan_end_date: workspace.plan_end_date,
				data_pack_name: workspace.data_pack_name,
				data_pack_id: workspace.data_pack_id,
				size: workspace.max_monthly_item_count,
				avatar: {
					type: 'avatar',
					image_url: user.picture
				},
				company: {
					client_account_number: workspace.client_account_number,
					company_id: workspace.client_id,
					name: workspace.client_name,
					market_name: workspace.market_name,
					market_id: workspace.market_id,
					enable_ai_summarization: workspace.enable_ai_summarization,
					enable_media_assistant: workspace.enable_media_assistant
				}
			});
		};

		fetchData();
	}, [isAppLoading, impersonate, window.Intercom, getClientApi, user, workspace]);

	useEffect(() => {
		if (isAppLoading || impersonate) {
			// if app hasn't loaded yet OR the user is impersonating, don't track any events
			return;
		}

		window.Intercom('update');
	}, [location?.pathname, isAppLoading, impersonate]);
};

export default useIntercom;

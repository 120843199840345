import React, { memo, useState } from 'react';
import { useUserStorageContext } from '@truescope-web/react/lib/components/UserStorageProvider';
import Inline, { horizontalAlignment } from '@truescope-web/react/lib/components/layout/Inline';
import ScrollView from '@truescope-web/react/lib/components/layout/ScrollView';
import Typography from '@truescope-web/react/lib/components/layout/Typography';
import { SheetWithClickAway } from '@truescope-web/react/lib/components/modal/SheetV2';
import DatePicker from '@truescope-web/react/lib/components/widgets/DatePicker';
import { arrayIsNullOrEmpty } from '@truescope-web/utils/lib/arrays';
import { prefillDates } from '@truescope-web/utils/lib/dates';
import { useConfigContext } from '../../../components/Config/ConfigProvider';
import EmptyStateCallToAction from '../../../components/widgets/EmptyStateCallToAction';
import { emptyStateImagesLookup } from '../../../components/widgets/EmptyStateCallToActionConstants';
import ReportTemplateBuilder from '../builder/ReportTemplateBuilder';
import ReportsActivityProvider, { useReportsActivityContext } from './ReportsActivityProvider';
import { activityTablesLookup } from './activitiesTable/constants';
import RecipientActivitiesTable from './activitiesTable/recipient/RecipientActivitiesTable';
import ReportActivitiesTable from './activitiesTable/report/ReportActivitiesTable';
import ReportsActivityChart from './activityChart/ReportsActivityChart';
import ActivityStream from './activityStream/ActivityStream';
import { updateState } from './reducer';

const Table = ({ activityTableType }) => (
	<ScrollView fill>
		<div className="reports-activity__body__content">
			<div className="reports-activity__body__content__overview-panel">
				<ReportsActivityChart />
			</div>
			<div className="reports-activity__body__content__activity-table-panel">
				{activityTableType === activityTablesLookup.recipient ? <RecipientActivitiesTable /> : <ReportActivitiesTable />}
			</div>
		</div>
	</ScrollView>
);

const ReportsActivity = memo(() => {
	const [state, dispatch] = useReportsActivityContext();
	const { setUserStorage } = useUserStorageContext();

	const handleDateChange = (dateOption, dateFrom, dateTo) => {
		const updatedState = prefillDates(dateOption, dateFrom, dateTo, null, false);
		//persist the changed dates in the user storage
		setUserStorage(({ reportsActivity, ...prev }) => ({
			...prev,
			reportsActivity: {
				...(reportsActivity || {}),
				dateFrom: updatedState.dateFrom,
				dateTo: updatedState.dateTo,
				dateOption: updatedState.dateOption
			}
		}));
		dispatch(updateState({ ...updatedState, isEmpty: false }));
	};

	return (
		<div className="reports-activity">
			<div className="reports-activity__header">
				<Inline horizontalAlignment={horizontalAlignment.rightAlignSiblings} responsiveWrap={false}>
					<Typography variant="h4">Reports Activity</Typography>
					<DatePicker
						dateOption={state.dateOption}
						dateFrom={state.dateFrom}
						dateTo={state.dateTo}
						editable
						onAccept={handleDateChange}
					/>
				</Inline>
			</div>
			<div className="reports-activity__body">
				{state.isEmpty && (
					<EmptyStateCallToAction
						iconVariant={emptyStateImagesLookup.noActivity}
						enableImageShadow={false}
						mainMessage="There’s no activity for this time period, try adjusting your date range."
					/>
				)}

				<Table activityTableType={state.activityTableType} />
				{!state.isEmpty && (
					<div className="reports-activity__body__activity-stream-panel">
						<ActivityStream />
					</div>
				)}
			</div>
		</div>
	);
});

const ReportsActivityWrapper = () => {
	const [
		{
			workspace: { reportTemplates = [] }
		}
	] = useConfigContext();
	const [showReportTemplateBuilder, setShowReportTemplateBuilder] = useState(false);

	if (arrayIsNullOrEmpty(reportTemplates)) {
		return (
			<>
				<EmptyStateCallToAction
					iconVariant={emptyStateImagesLookup.noReports}
					enableImageShadow={false}
					mainMessage={`Create and publish ${
						arrayIsNullOrEmpty(reportTemplates) ? 'your first' : 'a'
					} report to share your media results`}
					actionButtonText="Create Template"
					actionButtonOnClick={() => setShowReportTemplateBuilder(true)}
				/>
				<SheetWithClickAway isOpen={showReportTemplateBuilder} onClose={() => setShowReportTemplateBuilder(false)}>
					<ReportTemplateBuilder isOpen={showReportTemplateBuilder} />
				</SheetWithClickAway>
			</>
		);
	}

	return (
		<ReportsActivityProvider>
			<ReportsActivity />
		</ReportsActivityProvider>
	);
};

export default ReportsActivityWrapper;

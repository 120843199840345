import React, { createContext, useContext, useReducer } from 'react';
import { useUserStorageContext } from '@truescope-web/react/lib/components/UserStorageProvider';
import { dateOptionsLookup } from '@truescope-web/utils/lib/dates';
import { isNullOrUndefined } from '@truescope-web/utils/lib/objects';
import { activityTablesLookup } from './activitiesTable/constants';
import activityStreamReducer, { getInitialState } from './reducer';

const ReportsActivityContext = createContext(null);

export const useReportsActivityContext = () => {
	const context = useContext(ReportsActivityContext);
	if (isNullOrUndefined(context)) {
		throw new Error('useReportsActivityContext must be used within a ReportsActivityProvider');
	}
	return context;
};

const ReportsActivityProvider = ({ children }) => {
	const { userStorage } = useUserStorageContext();

	const [state, dispatch] = useReducer(
		activityStreamReducer,
		getInitialState({
			dateFrom: userStorage.reportsActivity?.dateFrom,
			dateTo: userStorage.reportsActivity?.dateTo,
			dateOption: userStorage.reportsActivity?.dateOption || dateOptionsLookup.last30Days,
			activityTableType: userStorage.reportsActivity?.activityTableType || activityTablesLookup.report
		})
	);

	return <ReportsActivityContext.Provider value={[state, dispatch]}>{children}</ReportsActivityContext.Provider>;
};

export default ReportsActivityProvider;

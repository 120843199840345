import { renderToStaticMarkup } from 'react-dom/server';
import { getDefaultInterval } from '@truescope-web/react/lib/components/widgets/DatePicker';
import { wasTokenCancelled } from '@truescope-web/react/lib/hooks/useCancelToken';
import { breakpoints } from '@truescope-web/react/lib/utils/breakpoints';
import { arrayIsNullOrEmpty } from '@truescope-web/utils/lib/arrays';
import { dateOptionsLookup } from '@truescope-web/utils/lib/dates';
import { isNullOrUndefined } from '@truescope-web/utils/lib/objects';
import { stringIsNullOrEmpty } from '@truescope-web/utils/lib/strings';
import { filterDefinitions } from '../../components/widgets/FilterComponents';
import { deserializeFilters, serializeFilters } from '../../components/widgets/FilterConstants';
import { deserializeChart, serializeChart } from '../../components/widgets/charts/ChartConstants';
import DashboardPrintPageFooter from './DashboardPrintPageFooter';
import { dashboardDateRanges } from './builder/DashboardBuilderConstants';

/**
 * available columns in the dashboard depending on the sizes
 * old version before 3/6/2021: { lg: 6, md: 3, sm: 2, xs: 1 }
 */
export const dashboardGridColumns = { md: 6, xs: 1 };

/**
 * minimum widths for columns
 * old version before 3/6/2021: { lg: 2, md: 1, sm: 1, xs: 1 }
 */
export const dashboardGridMinimumWidths = { md: 2, xs: 1 };

/**
 * dashboard breakpoints
 * old version before 3/6/2021: { lg: 1200, md: 992, sm: 577, xs: 0 }
 * desktopDashboardBreakpoints.md = smMax (991) - $drawer-master-width (60) - sum of left and right margin of ResponsiveGridLayout (10 * 2)
 */
export const desktopDashboardBreakpoints = { md: breakpoints.sm.max - 80, xs: 0 };
export const mobileDashboardBreakpoints = { md: breakpoints.sm.max, xs: 0 };

/**
 * gets a dashboard
 * @param {*} param0
 */
export const getDashboard = async (getClientApi, dashboardId, workspaceId, cancelToken) => {
	try {
		const api = await getClientApi();
		const { data } = await api.get(`dashboards/v1/${workspaceId}/dashboard/${dashboardId}`, { cancelToken });
		if (!stringIsNullOrEmpty(data.message)) {
			throw new Error(data.message);
		}
		return data;
	} catch (e) {
		if (wasTokenCancelled(cancelToken)) {
			return new Error('cancelled');
		}
		throw e;
	}
};

/**
 * removes a dashboard
 * @param {*} param0
 */
export const removeDashboard = async (getClientApi, dashboardId, workspaceId) => {
	const api = await getClientApi();
	const { message } = await api.delete(`dashboards/v1/${workspaceId}/dashboards/${dashboardId}`);
	if (!stringIsNullOrEmpty(message)) {
		throw new Error(message);
	}
};

/**
 * updates a dashboard
 * @param {*} param0
 */
export const updateDashboard = async (getClientApi, dashboard, workspaceId) => {
	const api = await getClientApi();
	const { data } = await api.patch(`dashboards/v1/${workspaceId}/update`, { dashboard });
	if (!stringIsNullOrEmpty(data.message)) {
		throw new Error(data.message);
	}
	return data;
};

/**
 * maxYchart is the chart that has the bottom (y + h) reaching the highest y
 * @param {*} sortedGridPositions
 */
const findMaxYChart = (sortedGridPositions) => {
	return sortedGridPositions.reduce((acc, cur) => {
		if (cur.y + cur.h > acc.y + acc.h) {
			return cur;
		}
		//if multiple charts have the same bottom that reach the highest y, maxY chart is the last one on the right (highest x)
		if (cur.y + cur.h === acc.y + acc.h && cur.x > acc.x) {
			return cur;
		}
		return acc;
	});
};

/**
 * available space to insert new chart
 * @param {*} sortedGridPositions
 * @param {*} chartWidth
 * @param {*} maxWidth
 * @param {*} minWidth
 */
const findAvailableSpace = (sortedGridPositions, chartWidth, maxWidth, minWidth) => {
	const { y, h, x, w } = findMaxYChart(sortedGridPositions);
	const potentialAvailableRightColumns = maxWidth - w - x;

	if (maxWidth !== 1) {
		// charts that have the same bottom as the MaxYChart
		const maxYRowCharts = sortedGridPositions.filter((chart) => chart.y + chart.h === y + h);
		//check if last row is full
		if (maxYRowCharts.length < maxWidth / minWidth) {
			if (x <= minWidth && potentialAvailableRightColumns >= minWidth && potentialAvailableRightColumns >= chartWidth) {
				return { x: x + w, y: y + h };
			}
		}
	}

	return { x: 0, y: y + h };
};

/**
 * inserts a new grid position into the grid positions collection
 * @param {*} chart
 * @param {*} gridPositions
 * @param {*} maxWidth - 6 (md) | 1 (xs)
 * @param {*} minWidth - 2 (md) | 1 (xs)
 */
export const insertGridPosition = (chart, gridPositions, maxWidth = 6, minWidth = 2) => {
	if (isNullOrUndefined(gridPositions)) {
		return;
	}

	let insertionRow = { x: 0, y: 0 };

	if (!arrayIsNullOrEmpty(chart.nested_charts)) {
		if (minWidth === 2) {
			chart.block_width = minWidth * chart.nested_charts.length;
		}
	}

	if (!arrayIsNullOrEmpty(gridPositions)) {
		const sortedGridPositions = gridPositions.sort((a, b) => a.y - b.y);
		insertionRow = findAvailableSpace(sortedGridPositions, chart.block_width, maxWidth, minWidth);
	}

	let insertX = insertionRow.x;
	let insertY = insertionRow.y;

	gridPositions.push({
		moved: false,
		static: false,
		h: chart.block_height,
		w: Math.max(minWidth, chart.block_width),
		i: `${chart.id}`,
		x: insertX,
		y: insertY,
		maxW: maxWidth,
		minW: minWidth,
		minH: chart.block_height
	});
};

/**
 * removes a chart's grid position from the grid positions collection
 * @param {*} chartId
 * @param {*} gridPositions
 */
export const removeGridPosition = (chartId, gridPositions) => {
	if (isNullOrUndefined(gridPositions)) {
		return;
	}
	const index = gridPositions.findIndex((x) => x.i === chartId);
	if (index < 0) {
		return;
	}
	gridPositions.splice(index, 1);
};

/**
 * Get xs layout based on md layout change
 */
export const getUpdatedLayoutXs = (charts, mdLayout) => {
	return mdLayout
		.sort((prev, cur) => (prev.y === cur.y ? prev.x - cur.x : prev.y - cur.y))
		.map((value, index) => {
			let h = 3;
			let y = 0;

			const previousGrid = mdLayout[index - 1];
			if (!isNullOrUndefined(previousGrid)) {
				y = previousGrid.y + previousGrid.h;
			}

			const nestedCharts = charts.find((chart) => chart.id === parseInt(value.i))?.nested_charts;
			if (!arrayIsNullOrEmpty(nestedCharts)) {
				h = nestedCharts.length;
			}

			return {
				...value,
				x: 0,
				w: 1,
				maxW: 1,
				minW: 1,
				y,
				h
			};
		});
};

/**
 * serializes a dashboard for saving
 * @param {*} dashboard
 * @returns serialized dashboard
 */
export const serializeDashboard = ({ charts, filters, workspace_id, ...dashboard }, workspace, appCache, user) => {
	return {
		...dashboard,
		filters: serializeFilters(filters, workspace, appCache, user, true),
		charts: (charts || []).map((chart) =>
			serializeChart(chart, workspace, appCache, user, dashboard.date_range === dashboardDateRanges.separate)
		)
	};
};

/**
 * deserializes a dashboard for viewing in the app
 * @param {*} dashboard
 * @returns deserialized dashboard
 */
export const deserializeDashboard = (dashboard, userStorageDateOptions, dashboardDataContext, workspace) => {
	dashboard.dashboard_id = parseInt(dashboard.dashboard_id, 10);

	if (isNullOrUndefined(dashboard.layout)) {
		dashboard.layout = { md: [], xs: [] };
	}

	// from 3/6/2021, there are only two view modes in Responsive Grid Layout
	// md for large screen, xs for small screen
	// for the old dashboards that have 4 defined layouts, update layout.md = layout.lg
	// delete layout.lg and layout.sm
	if (!arrayIsNullOrEmpty(dashboard.layout.lg)) {
		dashboard.layout.md = dashboard.layout.lg;
		delete dashboard.layout.lg;
	}
	if (!arrayIsNullOrEmpty(dashboard.layout.sm)) {
		delete dashboard.layout.sm;
	}
	// to fix error "minWidth not Number" (minWidth = null)
	// that may be seen in Query overview & Query sentiment dashboards created before 28/10/2021
	// todo: this can be removed after user re-open all dashboards that have error and save the layout
	if (!arrayIsNullOrEmpty(dashboard.layout.md) && isNullOrUndefined(dashboard.layout.md[0].minW)) {
		const blockWidthLookup = Object.fromEntries(dashboard.charts.map((chart) => [chart.id, chart.block_width]));
		dashboard.layout.md.forEach((chartLayout) => {
			chartLayout.minW = Math.max(dashboardGridMinimumWidths.md, blockWidthLookup[chartLayout.i]);
		});
	}

	dashboard.charts = (dashboard.charts || []).map((chart) =>
		deserializeChart(chart, dashboard, userStorageDateOptions, dashboardDataContext, workspace)
	);
	dashboard.filters = deserializeFilters(dashboard.filters, workspace);

	if (dashboard.date_range === dashboardDateRanges.all && isNullOrUndefined(dashboard.filters.publication_date_option)) {
		//if the dashboard range is ALL but there's no date filters, default them to last 30 days
		const dateFilter = userStorageDateOptions?.search_filter || { publication_date_option: dateOptionsLookup.last30Days };
		dashboard.filters.publication_date_option = dateFilter.publication_date_option;
		dashboard.filters.publication_date_from = dateFilter.publication_date_from;
		dashboard.filters.publication_date_to = dateFilter.publication_date_to;

		//we also need to make sure that all chart's intervals are compatible with last 30 days
		const defaultChartIntervalId = getDefaultInterval(
			dashboard.filters.publication_date_option,
			dashboard.filters.publication_date_from,
			dashboard.filters.publication_date_to
		);

		dashboard.charts.forEach((chart) => {
			if (!isNullOrUndefined(chart.chart_interval_id)) {
				chart.chart_interval_id = defaultChartIntervalId;
			}
		});
	}

	//due to a bug with new user dashboards, we need to delete these properties
	//TODO: remove this by 01/09/22
	if (!stringIsNullOrEmpty(dashboard.filters.name)) {
		delete dashboard.filters.chart_interval_id;
		delete dashboard.filters.name;
	}

	//in some cases, there's been some dashboards which have had corrupted
	dashboard.layout = removeUnusedLayouts(dashboard.charts, dashboard.layout);

	return dashboard;
};

/**
 * removes any unused layouts
 * @param {[]} charts charts array
 * @param {{ md:[], xs: []}} layout dashboard layouts
 * @returns updated layouts
 */
export const removeUnusedLayouts = (charts, layout) => {
	const chartIdLookup = new Set(charts.map((chart) => `${chart.id}`));
	return {
		xs: layout.xs.filter((layout) => chartIdLookup.has(layout.i)),
		md: layout.md.filter((layout) => chartIdLookup.has(layout.i))
	};
};

const generateExportCreatePageWithContainer = (chart, pageFooter) => {
	const page = document.createElement('div');
	page.classList.add('export-root__html-export__page');

	const container = document.createElement('div');
	container.classList.add('export-root__html-export__page__container');
	container.innerHTML = `${chart.outerHTML}${pageFooter}`;

	page.innerHTML = container.outerHTML;

	return {
		page,
		container
	};
};

export const generateExportChartsHtml = (chartsLayout = [], exportTitle, images = undefined) => {
	const pageTotalString = '|data-total-count|';
	const pages = [];
	let pageCount = 1; // 1 to account for cover page, which starts at page 1
	chartsLayout.forEach(({ i }) => {
		const chart = document.getElementById(`chart-${i}`).cloneNode(true);

		const workspaceThemeLogo = images?.logo?.url;
		const workspaceThemeBadge = images?.badge?.url;
		const hasWorkspaceThemeLogo = !isNullOrUndefined(workspaceThemeLogo);
		const hasWorkspaceThemeBadge = !isNullOrUndefined(workspaceThemeBadge);

		const chartPrintHeaderLogoImageElement = chart.querySelector('.chart-print-header__content__right__logo');
		if (!isNullOrUndefined(chartPrintHeaderLogoImageElement)) {
			if (hasWorkspaceThemeLogo) {
				chartPrintHeaderLogoImageElement.setAttribute('src', workspaceThemeLogo);
				chartPrintHeaderLogoImageElement.style.display = 'block';
			} else if (hasWorkspaceThemeBadge) {
				chartPrintHeaderLogoImageElement.setAttribute('src', workspaceThemeBadge);
				chartPrintHeaderLogoImageElement.style.display = 'block';
			}
		}

		const chartFilterSummaryElements = chart.querySelectorAll('.chart-filter-summary');
		chartFilterSummaryElements.forEach((element) => {
			element.style.display = 'none';
		});

		const chartFilterSummaryContainer = chart.querySelector('.filter-summary__container')?.cloneNode(true);

		const chartPrintHeaderLeftSubtitle = chart.querySelector('.chart-print-header__content__left__subtitle');
		if (chartFilterSummaryContainer?.textContent.length + chartPrintHeaderLeftSubtitle?.textContent.length > 200) {
			chartPrintHeaderLeftSubtitle.remove();
		} else if (!isNullOrUndefined(chartFilterSummaryContainer)) {
			chartPrintHeaderLeftSubtitle.innerHTML = `${chartFilterSummaryContainer.innerHTML} between ${chartPrintHeaderLeftSubtitle.innerHTML}`;
		}

		const mediaListItems = [...chart.querySelectorAll('.media-item-list-chart__media-item-content-component')];
		if (!arrayIsNullOrEmpty(mediaListItems)) {
			const mediaItems = mediaListItems.filter((_item, index) => index < 24);
			const mediaItemPerPageLimit = 8;
			const mediaItemPageCount = Math.ceil(mediaItems.length / mediaItemPerPageLimit);

			[...Array(mediaItemPageCount).keys()].forEach((pageIndex) => {
				const newChart = document.getElementById(`chart-${i}`).cloneNode(true);

				if (pageIndex > 0) {
					const chartPrintHeaderLeftTitle = newChart.getElementsByClassName('chart-print-header__content__left__title__title')[0];
					chartPrintHeaderLeftTitle.innerHTML = `${chartPrintHeaderLeftTitle.innerHTML} - continued`;
				}

				const maxIndex = (pageIndex + 1) * mediaItemPerPageLimit - 1;
				const minIndex = maxIndex - mediaItemPerPageLimit + 1;
				newChart.querySelectorAll('.media-item-list-chart__media-item-content-component').forEach((item, index) => {
					if (index > maxIndex || index < minIndex) {
						item.remove();
					}
				});
				newChart.querySelectorAll('.media-item-list__list-footer').forEach((footer) => footer.remove());

				const pageFooter = renderToStaticMarkup(
					<DashboardPrintPageFooter title={exportTitle} page={pageCount} total={pageTotalString} />
				);
				const { page } = generateExportCreatePageWithContainer(newChart, pageFooter);
				pages.push(page);
				pageCount++;
			});
		} else {
			const pageFooter = renderToStaticMarkup(
				<DashboardPrintPageFooter title={exportTitle} page={pageCount} total={pageTotalString} />
			);
			const { page } = generateExportCreatePageWithContainer(chart, pageFooter);
			pages.push(page);
			pageCount++;
		}
	});

	pages.forEach((page) => {
		page.querySelectorAll('.dashboard-print-page-footer__left__content__page-count').forEach((item) => {
			item.innerHTML = item.innerHTML.replaceAll(pageTotalString, pages.length);
		});
	});

	return pages;
};

export const setChartDateRange = (chart, filters) => {
	const { publication_date_from, publication_date_option, publication_date_to } = filters;
	const dateFilter = { publication_date_from, publication_date_option, publication_date_to } || {
		publication_date_option: dateOptionsLookup.last30Days
	};

	const updatedChart = {
		...chart,
		search_filter: {
			...chart.search_filter,
			...dateFilter
		}
	};

	if (!isNullOrUndefined(chart.chart_interval_id)) {
		updatedChart.chart_interval_id = getDefaultInterval(
			dateFilter.publication_date_option,
			dateFilter.publication_date_from,
			dateFilter.publication_date_to
		);
	}

	return updatedChart;
};

const getChartLayoutById = (chartLayouts, chartId) => {
	return chartLayouts.find(({ i }) => parseInt(i, 10) === chartId);
};

export const getChartLayouts = (dashboardLayout, chartId) => {
	return {
		xs: getChartLayoutById(dashboardLayout.xs, chartId),
		md: getChartLayoutById(dashboardLayout.md, chartId)
	};
};

/**
 * filters that are not removable
 */
export const dashboardPreselectedFilters = [filterDefinitions.query_ids, filterDefinitions.media_types].map(({ option }) => ({
	field: option.value,
	removable: false
}));

/**
 * filters that define what can be picked
 */
export const dashboardFilterFields = [
	filterDefinitions.query_ids,
	filterDefinitions.media_types,
	filterDefinitions.title,
	filterDefinitions.title_summary,
	filterDefinitions.title_summary_body,
	filterDefinitions['simple_query.or'],
	filterDefinitions['simple_query.and'],
	filterDefinitions['simple_query.not'],
	filterDefinitions.languages,
	filterDefinitions.sentiments,
	filterDefinitions.majorMentions,
	filterDefinitions.sources,
	filterDefinitions.authors,
	filterDefinitions.categories,
	filterDefinitions.countries,
	filterDefinitions.regions,
	filterDefinitions.cities,
	filterDefinitions.locations,
	filterDefinitions.audience,
	filterDefinitions.people,
	filterDefinitions.companies,
	filterDefinitions.entity_sentiments,
	filterDefinitions.social_engagement,
	filterDefinitions.source_dmarank,
	filterDefinitions.source_networks,
	filterDefinitions.source_sections
].map(({ option }) => option.value);

/**
 * given a collection of charts the the date range, calculates the dashboards interval
 * @param {*} dashboard
 * @returns
 */
export const getDashboardInterval = (dashboard) =>
	dashboard?.date_range === dashboardDateRanges.all
		? dashboard?.charts.find((chart) => !isNullOrUndefined(chart.chart_interval_id))?.chart_interval_id
		: undefined;
